import React from 'react';
import styled from '@emotion/styled';
import { Tooltip, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ClientInformation } from '@agoy/api-sdk-core';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import Button from '_shared/components/Buttons/Button';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const InformationSectionLabelWrapper = styled.div`
  margin-right: 32px;
  font-size: ${(props) => props.theme.typography.fontSize};
`;

interface ClientCardProps {
  label: string;
  children: React.ReactNode;
  content: ClientInformation;
  toggleCreditSafeModal: (value: ClientInformation) => void;
  withCreditSafe?: boolean;
}

const ClientCard = ({
  label,
  children,
  content,
  toggleCreditSafeModal,
  withCreditSafe,
}: ClientCardProps) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <MainWrapper>
        <InformationSectionLabelWrapper>
          <Typography variant="h2">{label}</Typography>
        </InformationSectionLabelWrapper>
        {withCreditSafe && activeFeatureFlags.get('feature_cs_data') && (
          <Tooltip title={formatMessage({ id: 'creditSafe.tooltip' })}>
            <Button
              label="CreditSafe"
              onClick={() => toggleCreditSafeModal(content)}
              variant="outlined"
              size="medium"
            />
          </Tooltip>
        )}
      </MainWrapper>
      {children}
    </div>
  );
};

export default ClientCard;
