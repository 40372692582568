import styled from '@emotion/styled';
import { Column } from '../../ScrollableTable';
import { PeriodType, SizeType } from '../../types';

const FULL_PERIOD = 265;
const HALF_PERIOD = 205;
const COLLAPSED = 9;

export const WIDTHS = {
  half: HALF_PERIOD,
  full: FULL_PERIOD,
};

export const getColumnWidth = (
  size: SizeType,
  type: PeriodType,
  expanded: boolean
) => {
  if (type === 'month' || type === 'dead' || type === 'yearEnd') {
    return WIDTHS[size];
  }

  return WIDTHS[size] + (expanded ? 0 : COLLAPSED);
};

const PeriodColumn = styled(Column, {
  shouldForwardProp: (name) =>
    name !== 'size' &&
    name !== 'expandedColumnsCount' &&
    name !== 'type' &&
    name !== 'hidden',
})<{
  expandedColumnsCount: number;
  size: SizeType;
  type: PeriodType;
  hidden?: boolean;
}>`
  width: ${({ size, type, expandedColumnsCount = 0 }) =>
    getColumnWidth(size, type, !!expandedColumnsCount) +
    expandedColumnsCount * WIDTHS[size]}px;
  transition: width 0.3s;
  white-space: nowrap;
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`;

export default PeriodColumn;
