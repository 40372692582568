import React from 'react';
import styled from '@emotion/styled';
import { Tooltip, Typography as MuiTypography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import { ClientInformation, CSDetailedCompany } from '@agoy/api-sdk-core';
import { translateCompanyType } from '_clients/components/Information/utils';
import { normalizeOrganisationNumber } from '@agoy/common';

type Warning = 'notMatching' | 'noValue' | undefined;

const StyledWarningIcon = styled(WarningIcon)<{ warning: Warning }>`
  color: ${(props) =>
    props.warning === 'notMatching'
      ? `${props.theme.palette.warning.main}`
      : `${props.theme.palette.error.main}`};
  background: ${({ theme }) => theme.palette.grey[200]};
  margin-left: -1.6rem;
  margin-top: 0.3rem;
  align-self: flex-start;
`;

const StyledInput = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  width: 13rem;
  padding: ${(props) => props.theme.spacing(1)}px;
  font-weight: bold;
  border-radius: 4px;
`;

const ContainerGridRow = styled.div`
  display: flex;
  position: relative;
  margin: 1rem 0;
  width: 930px;
  overflow-y: scroll;
`;

const GridElement = styled.div`
  display: flex;
  width: 30rem;
  min-height: 32px;

  :nth-of-type(1) {
    width: 26rem;
    margin-left: 2rem;
  }

  // Extra padding to allow for the warning icon
  :nth-of-type(2) {
    .input {
      padding: ${(props) => props.theme.spacing(1, 3, 1, 1)};
    }
  }

  :nth-of-type(3) {
    .input {
      padding: ${(props) => props.theme.spacing(1)}px;
    }
  }

  :nth-of-type(2),
  :nth-of-type(3) {
    .input {
      background: ${({ theme }) => theme.palette.grey[200]};
    }
  }

  :nth-of-type(4) {
    .updated-input {
      background: ${({ theme }) => theme.palette.creditSafe.inputPreview};
      padding: ${(props) => props.theme.spacing(1)}px;
    }
  }
`;

const Typography = styled(MuiTypography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: bold;
`;

const WarningRow = ({ warning }: { warning: string }) => {
  return (
    <>
      {warning === 'notMatching' && (
        <Tooltip title="'Aktuell Grundinfo' stämmer inte med 'Data enligt CreditSafe'">
          <StyledWarningIcon warning="notMatching" />
        </Tooltip>
      )}
      {warning === 'noValue' && (
        <Tooltip title="'Aktuell Grundinfo' saknas">
          <StyledWarningIcon warning="noValue" />
        </Tooltip>
      )}
    </>
  );
};

type CSModalRowProps = {
  companyData: CSDetailedCompany;
  cardModalFields: ClientInformation;
};

const CSModalRow = ({ cardModalFields, companyData }: CSModalRowProps) => {
  const { formatMessage } = useIntl();

  // If value in Agoy does not match, yellow icon; if no value in Agoy, red icon;
  const comparesValues = (item: string): Warning => {
    const currentValue = cardModalFields[item]?.value;

    if (item === 'orgNumber') {
      return normalizeOrganisationNumber(currentValue) ===
        normalizeOrganisationNumber(companyData[item] || '')
        ? undefined
        : 'notMatching';
    }

    if (currentValue !== '' && !isEqual(currentValue, companyData[item])) {
      return 'notMatching';
    }

    if (currentValue === '' && companyData[item] !== '') {
      return 'noValue';
    }

    return undefined;
  };

  const renderValue = (
    value: string | boolean | undefined
  ): string | undefined => {
    if (value === undefined) return undefined;

    if (typeof value === 'boolean') {
      return formatMessage({ id: value === true ? 'yes' : 'no' });
    }

    return value;
  };

  // Maps through the companyTypes in English and returns the translation
  const translateItem = (item: string, value?: string): string => {
    if (item === 'type') {
      return translateCompanyType(value, formatMessage) || '';
    }

    return renderValue(value) || '';
  };

  return (
    <>
      {Object.keys(companyData).map((item) => (
        <ContainerGridRow key={item}>
          <GridElement>
            <Typography>
              {formatMessage({ id: `clientInformation.${item}` })}
            </Typography>
          </GridElement>
          <GridElement>
            <StyledInput className="input">
              {translateItem(item, cardModalFields[item]?.value)}
            </StyledInput>
            {comparesValues(item) === 'notMatching' ? (
              <WarningRow warning="notMatching" />
            ) : (
              comparesValues(item) === 'noValue' && (
                <WarningRow warning="noValue" />
              )
            )}
          </GridElement>
          <GridElement>
            <StyledInput className="input">
              {translateItem(item, companyData[item])}
            </StyledInput>
          </GridElement>
          <GridElement>
            <StyledInput className="updated-input">
              {translateItem(item, companyData[item])}
            </StyledInput>
          </GridElement>
        </ContainerGridRow>
      ))}
    </>
  );
};

export default CSModalRow;
