import React, { useCallback, useState, useMemo, useContext } from 'react';
import {
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { last } from 'lodash';

import { setChecklistDrawerExpanded } from '_shared/redux/checklist/actions';
import SpeedDial from '_shared/components/SpeedDial';
import Legend from '_shared/components/Legend';
import SpeedDialItem from '_annual-report/components/AnnualReportView/UI/AnnualReportSpeedDial/SpeedDialItem';
import CreateVoucherView from '_reconciliation/components/ReconciliationView/CreateVoucher/VoucherView';
import {
  PeriodType,
  ReconciliationPeriod,
} from '_reconciliation/components/ReconciliationView/types';
import HiddenRowsContext from '_reconciliation/components/ReconciliationView/RowContext/HiddenRowsContext';
import ClientChecklist from '_clients/components/ClientChecklist';
import { ClientCompany } from '@agoy/api-sdk-core';
import { useSelector } from 'redux/reducers';

import PreviewDrawer from '../PreviewDrawer';
import AccountListDrawer from '../AccountListDrawer';

type ReconciliationSpeedDialProps = {
  clientId: string;
  financialYear?: ClientCompany['financialYears'][0];
  groupedPeriods?: ReconciliationPeriod[];
  periodType?: PeriodType;
};

const ReconciliationSpeedDial = ({
  clientId,
  financialYear,
  groupedPeriods,
  periodType,
}: ReconciliationSpeedDialProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { hiddenRows } = useContext(HiddenRowsContext);

  const [legendOpen, setLegendOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPart, setPreviewPart] = useState('');

  const { showSinglePeriod } = useSelector((state) => state.accountingView);

  const checklistPeriod = useMemo(() => {
    const periods =
      financialYear?.periods?.filter((item) => item.type === 'month') || [];
    const hiddenRowsValues = Object.values(hiddenRows);
    const lastOpenHiddenRow = last(hiddenRowsValues);

    if (periodType === 'quarter') {
      if (showSinglePeriod) {
        const quarterPeriod = groupedPeriods?.find(
          (item) => item.start === showSinglePeriod
        );
        return last(quarterPeriod?.periods);
      }

      return last(lastOpenHiddenRow?.period?.periods);
    }
    if (periodType === 'financialYear') {
      return last(periods);
    }

    if (periodType === 'month') {
      if (showSinglePeriod) {
        return periods.find((period) => period.start === showSinglePeriod);
      }

      return periods.find(
        (period) => period.start === lastOpenHiddenRow?.period?.start
      );
    }

    if (showSinglePeriod) {
      return periods.find((period) => period.start === showSinglePeriod);
    }

    return undefined;
  }, [
    financialYear?.periods,
    groupedPeriods,
    hiddenRows,
    periodType,
    showSinglePeriod,
  ]);

  const togglePreview = useCallback(() => setPreviewOpen((open) => !open), []);

  const toggleChecklist = useCallback(() => {
    dispatch(setChecklistDrawerExpanded(true));
  }, [dispatch]);

  const speedDialActions = [
    {
      icon: <SpeedDialItem title="Dokument" icon={<DescriptionIcon />} />,
      label: 'Dokument',
      clickHandler: () => {
        history.push(`/clients/${clientId}/documents`);
      },
    },
    {
      icon: (
        <SpeedDialItem
          title={formatMessage({ id: 'legend.title' })}
          icon={<DescriptionIcon />}
        />
      ),
      label: formatMessage({ id: 'legend.title' }),
      clickHandler: () => {
        setLegendOpen(true);
      },
    },
    {
      icon: (
        <SpeedDialItem
          title={formatMessage({ id: 'checklist.label' })}
          icon={<DescriptionIcon />}
        />
      ),
      label: formatMessage({ id: 'checklist.label' }),
      clickHandler: toggleChecklist,
    },
    {
      icon: (
        <SpeedDialItem
          title={formatMessage({
            id: 'reconciliation.post.preview.title',
          })}
          icon={<AssignmentIcon />}
        />
      ),
      tooltipTitle: formatMessage({
        id: 'reconciliation.post.preview.hover',
      }),
      label: formatMessage({ id: 'reconciliation.post.preview.title' }),
      clickHandler: () => {
        setPreviewPart('reconciliation.post');
        togglePreview();
      },
    },
  ];

  return (
    <>
      <SpeedDial name="accounting-view-speed-dial" actions={speedDialActions} />

      <PreviewDrawer
        previewPart={previewPart}
        expanded={previewOpen}
        toggleExpanded={togglePreview}
      >
        <>{previewOpen && <CreateVoucherView clientId={clientId} />}</>
      </PreviewDrawer>

      <AccountListDrawer collapsedWidth={0} />

      {financialYear && (
        <ClientChecklist
          clientId={clientId}
          financialYear={financialYear}
          program="reconciliation_fin_statement_company"
          defaultPeriod={checklistPeriod}
        />
      )}

      <Legend open={legendOpen} onClose={() => setLegendOpen(false)} />
    </>
  );
};

export default ReconciliationSpeedDial;
