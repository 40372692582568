import React, {
  useState,
  useEffect,
  ChangeEventHandler,
  useContext,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Divider,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { asResultClass, useApiSdk } from 'api-sdk';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import { useSelector } from 'redux/reducers';
import {
  addGlobalMessage,
  addGlobalErrorMessage,
  getOrganisationMembers,
  getClient,
} from 'redux/actions';
import FileDropArea from '_shared/components/FileDropArea';
import {
  SimplifiedDatePicker,
  SimplifiedSelect,
  SimplifiedTextfield,
} from '_shared/components/Inputs';
import { createClient } from '_clients/redux/customers/actions';
import { readBuffer } from 'utils/sie-reader/sie-reader';
import { getBuffer } from 'utils/FileReader/file-reader-util';
import { sanitizeOrgNumber } from 'utils/Client/client-util';
import { isValidOrgNumber } from '@agoy/common';
import { ClientDataLayerContext } from 'data/client/ClientDataLayerContext';
import { CompanyType } from '_clients/types/types';
import { CTAButton } from '_shared/components/Buttons';
import { useHistory } from 'react-router-dom';
import LoadingLogo from '_shared/components/LoadingLogo';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import GenericSimplefied from '_shared/components/Inputs/GenericSimplefied';
import ClientSectionWrapper from './ClientSectionWrapper';
import {
  getClosingMonthOptions,
  getClosingPeriodOptions,
  getCompanyTypeOptions,
  getVatPeriodOptions,
} from './options';

interface RowContainerProps {
  maxWidth?: number;
  flexStyle?: string;
}

const Page = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: ${(props) => props.theme.spacing(4)}px;
  margin: 0 auto;
  max-width: 1280px;
`;

const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  justify-content: ${(props) =>
    props.flexStyle ? props.flexStyle : 'initial'};

  > *:not(:last-child) {
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }

  > *:only-child {
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

interface InputWrapperProps {
  width: number;
  paddingRight?: number;
}
const InputWrapper = styled.div`
  width: ${(props: InputWrapperProps) => props.width}%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing(4)}px;
`;

const HeaderTitle = styled(Typography)`
  margin-top: 0;
`;

const IntegrateRadioGroup = styled(RadioGroup)`
  display: block;
  text-align: right;
`;

const ClientSieFileDropArea = styled(FileDropArea)`
  padding: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.accountingView.cell.ok};
  border: ${(props) => `1px dashed ${props.theme.palette.secondary.main}`};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;

  input {
    display: none;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const FetchCompanyInfoWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(5)}px;
`;

const DividerWithMargin = styled(Divider)`
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const UploadSIEButton = styled(Button)<{ component: any }>`
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const UploadSieFileText = styled(Typography)`
  margin: ${(props) => `${props.theme.spacing(2)}px 0`};
  padding: ${(props) => `0 ${props.theme.spacing(4)}px`};
`;

const SharesField = styled(CurrencyField)`
  &.has-error fieldset {
    border: 1px solid #ea5160;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

const StartDateLabel = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const Required = styled(Typography)`
  color: ${(props) => props.theme.palette.error.light};
`;

const Info = styled(InfoIcon)`
  font-size: 20px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const isSieFile = (_: File) => {
  return true;
};

type VatPeriodType =
  | ReturnType<typeof getVatPeriodOptions>[number]['value']
  | '';

const CreateClient = () => {
  const orgMembers = useSelector((state) => state.organisation.users);
  const currentUserEmail = useSelector((state) => state.user.email) || '';
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const clientDataLayerContext = useContext(ClientDataLayerContext);
  const sdk = useApiSdk();

  const { formatMessage } = intl;

  const labelledOrgMembers = orgMembers.map((member) => ({
    value: member.email,
    label: member.email,
  }));

  useEffect(() => {
    dispatch(getOrganisationMembers());
  }, [dispatch]);

  const closingMonthOptions = getClosingMonthOptions(intl);
  const closingPeriodOptions = getClosingPeriodOptions(intl);
  const vatPeriodOptions = getVatPeriodOptions(intl);
  const companyTypeOptions = getCompanyTypeOptions(intl);

  const [companyName, setCompanyName] = useState('');

  const [closingMonth, setClosingMonth] = useState<number | ''>('');
  const [clientManager, setClientManager] = useState(
    labelledOrgMembers[0]?.label || ''
  );
  const [closingPeriod, setClosingPeriod] = useState(''); // empty string as default 'no option selected'
  const [vatPeriod, setVatPeriod] = useState<VatPeriodType>(''); // empty string as default 'no option selected'
  const [companyType, setCompanyType] = useState(companyTypeOptions[0].value);
  const [stockTotalShare, setStockTotalShare] = useState(1000);
  const [stockTotalAppliedFrom, setStockTotalAppliedFrom] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyOrgNumber, setCompanyOrgNumber] = useState('');
  const [companyContact, setCompanyContact] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [orgNrError, setOrgNrError] = useState(false);
  const [addingNewCustomer, setAddingNewCustomer] = useState(false);
  const [fetchingCompanyInfo, setFetchingCompanyInfo] = useState(false);
  const [companyInfoOrgNumber, setCompanyInfoOrgNumber] = useState<
    string | undefined
  >();
  const [startDate, setStartDate] = useState<string | undefined>();

  const [sieReader, setSieReader] = useState<FileReader>();

  const [integrateWithFortnox, setIntegrateWithFortnox] = useState(false);
  const onChangeIntegrate: ChangeEventHandler<HTMLInputElement> = (event) => {
    setIntegrateWithFortnox(event.currentTarget.value === 'true');
  };

  /**
   * Fetch company information from Credit Safe and pre-populate some fields.
   */
  const fetchCompanyInformation = async () => {
    const orgNr = companyOrgNumber.replace('-', '');

    setFetchingCompanyInfo(true);
    setCompanyInfoOrgNumber(orgNr);
    const res = await asResultClass(
      sdk.getCsCompanyInformation({ orgNumber: orgNr })
    );

    if (res.ok) {
      const { address, name, orgNumber, type, zipCode, city } = res.val;
      if (type) setCompanyType(type);
      if (orgNumber) setCompanyOrgNumber(orgNumber);
      if (name) setCompanyName(name);
      if (address && zipCode && city) {
        setCompanyAddress(`${address}, ${zipCode} ${city}`);
      }
    } else {
      dispatch(addGlobalErrorMessage('error'));
    }

    setFetchingCompanyInfo(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !companyName ||
      !companyOrgNumber ||
      !closingMonth ||
      !closingPeriod ||
      !vatPeriod ||
      !startDate ||
      !isValidOrgNumber(sanitizeOrgNumber(companyOrgNumber)) ||
      (companyType === 'shares' && !stockTotalShare) ||
      !stockTotalAppliedFrom
    ) {
      return;
    }

    const additionalFields = {
      address: companyAddress,
      orgNumber: sanitizeOrgNumber(companyOrgNumber),
      contact: companyContact,
      number: companyNumber,
      email: companyEmail,
    };

    const cleanedAdditionalFields = Object.entries(additionalFields).reduce(
      (tot, [key, value]) => {
        return value !== '' && value !== null ? { ...tot, [key]: value } : tot;
      },
      {}
    );

    setAddingNewCustomer(true);
    let newClientFromServer;
    try {
      const manager = orgMembers.find(
        (member) => member.email === clientManager
      );
      newClientFromServer = await dispatch(
        createClient({
          name: companyName,
          type: companyType,
          managerId: manager?.userId,
          closingMonth,
          closingPeriod,
          vatPeriod,
          startDate,
          stockTotal: {
            shareTotal: companyType === 'shares' ? stockTotalShare : 1000,
            date: stockTotalAppliedFrom,
            type: 'new_registration',
          },
          fromCS:
            companyInfoOrgNumber !== undefined &&
            sanitizeOrgNumber(companyInfoOrgNumber) ===
              additionalFields.orgNumber,
          ...cleanedAdditionalFields,
        })
      );
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }

    setAddingNewCustomer(false);
    if (!newClientFromServer) {
      return;
    }

    if (!integrateWithFortnox) {
      history.push(`/clients/${newClientFromServer.id}`);
    } else {
      history.push(`/clients/${newClientFromServer.id}/integrations`);
    }

    if (sieReader) {
      const clientDataLayer = clientDataLayerContext.get(
        newClientFromServer.id
      );
      try {
        const uploadResult =
          await clientDataLayer.accountingBalances.uploadSieFile(
            getBuffer(sieReader)
          );

        if (uploadResult.err) {
          if (uploadResult.val.errorCode === 'SIE_IMPORT_WRONG_ORG_NUMBER') {
            dispatch(addGlobalErrorMessage('sie.orgNr.error.global'));
          } else {
            dispatch(addGlobalErrorMessage('sie.unknown.error.title'));
          }
        } else {
          dispatch(getClient(newClientFromServer.id));
        }
      } finally {
        clientDataLayerContext.release(clientDataLayer);
      }
    } else {
      dispatch(addGlobalMessage('warning', 'sie.noFileUploaded.warning'));
    }
  };

  const onAttachFile = (file: File | undefined) => {
    if (!file) return;

    const reader = new FileReader();

    // Reading the sie file to extract data from it and prefill the client creation form
    const onLoad = (event: ProgressEvent<FileReader>) => {
      if (event.target) {
        try {
          const ab = getBuffer(reader);
          const sieObj = readBuffer(ab);

          if (sieObj.list('orgnr')[0]?.orgnr) {
            setCompanyOrgNumber(sieObj.list('orgnr')[0].orgnr);
          }

          setCompanyName(sieObj.list('fnamn')[0].företagsnamn);

          if (sieObj.list('adress')[0]?.kontakt) {
            setCompanyContact(sieObj.list('adress')[0].kontakt);
          }

          if (
            sieObj.list('adress')[0]?.utdelningsadr &&
            sieObj.list('adress')[0]?.postadr
          ) {
            setCompanyAddress(
              `${sieObj.list('adress')[0].utdelningsadr}, ${
                sieObj.list('adress')[0].postadr
              } `
            );
          }

          if (sieObj.list('adress')[0]?.tel) {
            setCompanyNumber(sieObj.list('adress')[0].tel);
          }

          const rarEnd = sieObj.list('rar')[0].slut; // format yyyymmdd
          const accountingYearEndMonth = parseInt(rarEnd.substring(4, 6), 10);
          setClosingMonth(accountingYearEndMonth);

          // store reader so after user has created customer we can use
          // the reader to create an accounting year
          setSieReader(reader);
        } catch (error) {
          dispatch(addGlobalErrorMessage('sie.unknown.error.title'));
        }
      }
    };

    reader.addEventListener('load', onLoad, false);
    reader.readAsArrayBuffer(file);
  };

  const orgNrOnBlur = () => {
    if (!isValidOrgNumber(companyOrgNumber)) {
      setOrgNrError(true);
    } else {
      setOrgNrError(false);
    }
  };

  return (
    <Page>
      <Container>
        <Header>
          <HeaderTitle variant="h2">
            {formatMessage({ id: 'client.create.title' })}
          </HeaderTitle>
          <div>
            <Typography variant="h5">
              {formatMessage({ id: 'client.integrate.fortnox' })}
            </Typography>
            <IntegrateRadioGroup
              value={integrateWithFortnox}
              onChange={onChangeIntegrate}
            >
              <FormControlLabel
                control={
                  <Radio
                    value={false}
                    color={integrateWithFortnox ? 'default' : 'primary'}
                  />
                }
                label={formatMessage({
                  id: 'client.integrate.fortnox.later',
                })}
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Radio
                    value
                    color={integrateWithFortnox ? 'primary' : 'default'}
                  />
                }
                label={formatMessage({
                  id: 'client.integrate.fortnox.now',
                })}
                labelPlacement="start"
              />
            </IntegrateRadioGroup>
          </div>
        </Header>
        <ClientSectionWrapper
          label={formatMessage({ id: 'client.createWithSIE' })}
        >
          <ClientSieFileDropArea
            onDrop={onAttachFile}
            isValidFile={isSieFile}
            onInvalidFileType={() => {}}
          >
            <UploadSIEButton
              variant="contained"
              color="secondary"
              disableElevation
              component="label"
              id="onboarding-upload-file"
            >
              {formatMessage({ id: 'upload.file' })}
              <input
                data-cy="upload-file"
                type="file"
                onChange={(e) => {
                  if (e.target.files) onAttachFile(e.target.files[0]);
                }}
              />
            </UploadSIEButton>

            <UploadSieFileText>
              {formatMessage({ id: 'client.dragAndDropSIE' })}
            </UploadSieFileText>
          </ClientSieFileDropArea>
        </ClientSectionWrapper>

        <ClientSectionWrapper
          label={formatMessage({ id: 'client.createManually' })}
        >
          <RowContainer>
            <InputWrapper width={22}>
              <SimplifiedTextfield
                editing
                labelPlacement="top"
                margin="dense"
                value={sanitizeOrgNumber(companyOrgNumber)}
                required
                label={formatMessage({ id: 'client.orgNumber' })}
                onChange={(e) => setCompanyOrgNumber(e.target.value)}
                dataCy="create-client-orgnumber"
                error={!companyOrgNumber || !!orgNrError}
                helperText={
                  (orgNrError && formatMessage({ id: 'orgNr.error' })) ||
                  (!companyOrgNumber &&
                    formatMessage({ id: 'form.fieldRequired' })) ||
                  ''
                }
                onBlur={orgNrOnBlur}
              />
            </InputWrapper>
            {activeFeatureFlags.get('feature_cs_data') && (
              <FetchCompanyInfoWrapper>
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  disabled={!companyOrgNumber || !!orgNrError}
                  onClick={fetchCompanyInformation}
                  startIcon={
                    fetchingCompanyInfo && <LoadingLogo size="small" />
                  }
                  style={{ height: '43px' }}
                >
                  {formatMessage({ id: 'fetch.companyInfo' })}
                </Button>
              </FetchCompanyInfoWrapper>
            )}
          </RowContainer>
          <DividerWithMargin light />
          <RowContainer>
            <InputWrapper width={66} paddingRight={5}>
              <SimplifiedTextfield
                editing
                labelPlacement="top"
                value={companyName}
                required
                label={formatMessage({ id: 'client.name' })}
                onChange={(e) => setCompanyName(e.target.value)}
                dataCy="create-client-name"
              />
            </InputWrapper>
            <InputWrapper width={33}>
              <SimplifiedSelect
                editing
                labelPlacement="top"
                label={formatMessage({ id: 'client.type' })}
                listItems={companyTypeOptions}
                onChange={(e) => {
                  setCompanyType(e.target.value as CompanyType);
                }}
              />
            </InputWrapper>
          </RowContainer>
          <RowContainer>
            <InputWrapper width={33}>
              <SimplifiedSelect
                displayEmpty
                required
                value={closingMonth}
                renderValue={(value: unknown) => {
                  const selectedMonth = value as number;

                  if (selectedMonth) {
                    return closingMonthOptions[selectedMonth - 1].label;
                  }

                  return formatMessage({
                    id: 'client.closingMonthPlaceholder',
                  });
                }}
                editing
                labelPlacement="top"
                label={formatMessage({ id: 'client.closingMonth' })}
                listItems={closingMonthOptions}
                onChange={(e) => setClosingMonth(e.target.value as number)}
              />
            </InputWrapper>
            <InputWrapper width={33}>
              <SimplifiedSelect
                displayEmpty
                required
                value={closingPeriod}
                renderValue={(value: unknown) => {
                  const selectedPeriod = value as string;
                  if (selectedPeriod && selectedPeriod !== '') {
                    return closingPeriodOptions.find(
                      (item) => item.value === selectedPeriod
                    )?.label;
                  }

                  return formatMessage({
                    id: 'client.closingPeriodPlaceholder',
                  });
                }}
                editing
                labelPlacement="top"
                label={formatMessage({ id: 'client.closingPeriod' })}
                listItems={closingPeriodOptions}
                onChange={(e) => setClosingPeriod(e.target.value as string)}
              />
            </InputWrapper>
            <InputWrapper width={33}>
              <SimplifiedSelect
                displayEmpty
                required
                value={vatPeriod}
                renderValue={(value: unknown) => {
                  const selectedVatPeriod = value as string;
                  if (selectedVatPeriod && selectedVatPeriod !== '') {
                    return vatPeriodOptions.find(
                      (item) => item.value === selectedVatPeriod
                    )?.label;
                  }

                  return formatMessage({
                    id: 'client.vatPeriodPlaceholder',
                  });
                }}
                editing
                labelPlacement="top"
                label={formatMessage({ id: 'client.vatPeriod' })}
                listItems={vatPeriodOptions}
                onChange={(e) => setVatPeriod(e.target.value as VatPeriodType)}
              />
            </InputWrapper>
          </RowContainer>
          <RowContainer>
            <InputWrapper width={33}>
              <SimplifiedSelect
                editing
                labelPlacement="top"
                label={formatMessage({ id: 'client.manager' })}
                listItems={labelledOrgMembers}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setClientManager(event.target.value as string)
                }
                defaultValue={currentUserEmail}
              />
            </InputWrapper>
            {companyType === 'shares' && (
              <InputWrapper width={33}>
                <GenericSimplefied
                  label={formatMessage({
                    id: 'connections.addConnection.specifyShares.fields.totalShares',
                  })}
                  labelPlacement="top"
                  editing
                  required
                >
                  <SharesField
                    InputProps={{
                      variant: 'outlined',
                      size: 'small',
                      fullWidth: true,
                      placeholder: formatMessage({
                        id: 'connections.addConnection.specifyShares.fields.totalShares',
                      }),
                      required: true,
                    }}
                    displayDecimals={0}
                    editingDecimals={0}
                    value={stockTotalShare}
                    onValueChange={(value) => {
                      setStockTotalShare(value || 0);
                    }}
                    className={!stockTotalShare ? 'has-error' : ''}
                  />
                  {!stockTotalShare && (
                    <FormHelperText margin={undefined} error>
                      {formatMessage({ id: 'form.fieldRequired' })}
                    </FormHelperText>
                  )}
                </GenericSimplefied>
              </InputWrapper>
            )}
            <InputWrapper width={33}>
              <SimplifiedDatePicker
                required
                editing
                label={formatMessage({
                  id: `connections.addConnection.specifyShares.fields.${
                    companyType === 'shares'
                      ? 'appliesFrom'
                      : 'companyFoundationDate'
                  }`,
                })}
                labelPlacement="top"
                value={stockTotalAppliedFrom}
                onChange={(dateValue) => {
                  setStockTotalAppliedFrom(dateValue || '');
                }}
                error={!stockTotalAppliedFrom}
                helperText={
                  !stockTotalAppliedFrom
                    ? formatMessage({ id: 'form.fieldRequired' })
                    : ''
                }
              />
            </InputWrapper>
          </RowContainer>
          <RowContainer>
            <InputWrapper width={33} paddingRight={16}>
              <SimplifiedDatePicker
                editing
                label={
                  <StartDateLabel>
                    <Row>
                      <Typography variant="subtitle1" color="textSecondary">
                        {formatMessage({ id: 'client.startDate' })}
                      </Typography>
                      <Required>*</Required>
                    </Row>
                    <Tooltip
                      title={formatMessage({ id: 'client.startDate.tooltip' })}
                    >
                      <Info color="secondary" />
                    </Tooltip>
                  </StartDateLabel>
                }
                labelPlacement="top"
                value={startDate}
                onChange={(dateValue) => {
                  setStartDate(dateValue || '');
                }}
                error={!startDate}
                helperText={
                  !startDate ? formatMessage({ id: 'form.fieldRequired' }) : ''
                }
              />
            </InputWrapper>
          </RowContainer>
        </ClientSectionWrapper>
        <ClientSectionWrapper
          label={formatMessage({
            id: 'client.additional.information',
          })}
        >
          <RowContainer>
            <InputWrapper width={66}>
              <SimplifiedTextfield
                editing
                labelPlacement="top"
                value={companyAddress}
                label={formatMessage({ id: 'client.address' })}
                onChange={(e) => setCompanyAddress(e.target.value)}
              />
            </InputWrapper>
          </RowContainer>
          <RowContainer>
            <InputWrapper width={22}>
              <SimplifiedTextfield
                editing
                labelPlacement="top"
                value={companyNumber}
                label={formatMessage({ id: 'client.number' })}
                onChange={(e) => setCompanyNumber(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper width={22}>
              <SimplifiedTextfield
                editing
                labelPlacement="top"
                value={companyContact}
                label={formatMessage({ id: 'client.contact' })}
                onChange={(e) => setCompanyContact(e.target.value)}
              />
            </InputWrapper>
          </RowContainer>
          <RowContainer>
            <InputWrapper width={66}>
              <SimplifiedTextfield
                labelPlacement="top"
                editing
                value={companyEmail}
                label={formatMessage({ id: 'client.email' })}
                onChange={(e) => setCompanyEmail(e.target.value)}
              />
            </InputWrapper>
          </RowContainer>
        </ClientSectionWrapper>
        <ActionButtons>
          <CTAButton
            data-cy="save-btn"
            loading={addingNewCustomer}
            onClick={handleSubmit}
            id="onboarding-create-customer"
          >
            {formatMessage({ id: 'client.create' })}
          </CTAButton>
        </ActionButtons>
      </Container>
    </Page>
  );
};

export default CreateClient;
