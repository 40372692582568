import { AgoyTable, AgoyTableRow } from '../../table';
import { isUpdateRowChange, TableChange, UpdateRowChange } from '../../changes';

import { addItem, replaceById, replaceByIdAndType } from '../helpers';
import { Errors } from '../types';
import updateTable from './updateTable';

export type TableUpdater = (
  table: AgoyTable,
  change: TableChange
) => [AgoyTable, TableChange] | false | Errors;

const updateRow = (
  rowIds: string[],
  updater: (
    row: AgoyTableRow,
    change: UpdateRowChange
  ) => [AgoyTableRow, UpdateRowChange]
): TableUpdater => {
  const subRowUpdater = <T extends AgoyTableRow | AgoyTable>(
    row: T,
    change: T extends AgoyTableRow ? UpdateRowChange : TableChange,
    rowIds: string[]
  ): [T, T extends AgoyTableRow ? UpdateRowChange : TableChange] => {
    const rowId = rowIds[0];
    const subRow = row.rows?.find((row) => row.id === rowId);
    const existingRowChange: UpdateRowChange | undefined = change.rows
      ?.filter(isUpdateRowChange)
      .find((c) => c.id === rowId);

    if (subRow) {
      let newRow: AgoyTableRow;
      let newRowChange: UpdateRowChange;
      if (rowIds.length === 1) {
        [newRow, newRowChange] = updater(
          subRow,
          existingRowChange || { type: 'update', id: subRow.id }
        );
      } else {
        if (
          existingRowChange !== undefined &&
          existingRowChange.type !== 'update' &&
          existingRowChange.type !== 'add'
        ) {
          throw new Error("Only updating changes of type 'update' supported");
        }
        [newRow, newRowChange] = subRowUpdater(
          subRow,
          existingRowChange || { type: 'update', id: subRow.id },
          rowIds.slice(1)
        );
      }
      if (newRow !== subRow) {
        return [
          {
            ...row,
            rows: replaceById(row.rows, newRow),
          },
          {
            ...change,
            rows: existingRowChange
              ? replaceByIdAndType(change.rows, newRowChange)
              : addItem(change.rows, newRowChange),
          },
        ];
      }
    }

    return [row, change];
  };

  return (table, changes) =>
    updateTable(table, changes, (table, change) => {
      if (change.type !== 'update') {
        throw new Error("Only updating changes of type 'update' supported");
      }

      return subRowUpdater(table, change, rowIds);
    });
};

export default updateRow;
