import React from 'react';
import { Control, FieldValues, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { gray } from '@agoy/theme/src/colors-fortnox';

import SimplifiedDatePicker from '_shared/components/Inputs/SimplifiedDatePicker';
import FieldWrapper, { FieldWrapperProps } from './FieldWrapper';

interface ClientCardDatePickerProps extends FieldWrapperProps {
  name: string;
  toolTip?: string;
  value?: string;
  placeholder?: string;
  isEditing?: boolean;
  disabled?: boolean;
  control?: Control<FieldValues>;
  views?: Array<'date' | 'month' | 'year'>;
  format?: string;
}

const StyledSimplifiedDatePicker = styled(SimplifiedDatePicker)`
  padding: 0 ${(props) => (!props.editing ? props.theme.spacing(2) : 0)}px;
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${gray.HIGH};
  }
`;

const ClientCardDatePicker = ({
  name,
  field,
  toolTip,
  labelField,
  error,
  isEditing,
  placeholder,
  disabled,
  control,
  source,
  timestamp,
  role,
  user,
  views = ['year', 'month', 'date'],
  format,
}: ClientCardDatePickerProps) => {
  return (
    <FieldWrapper
      source={source}
      timestamp={timestamp}
      role={role}
      user={user}
      field={field}
      toolTip={toolTip}
      labelField={labelField}
      error={error}
      isEditing={isEditing}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...rest } }) => {
          return (
            <StyledSimplifiedDatePicker
              disabled={disabled}
              placeholder={placeholder}
              editing={isEditing}
              inputRef={ref}
              views={views}
              format={format}
              fullWidth
              {...rest}
            />
          );
        }}
      />
    </FieldWrapper>
  );
};

export default ClientCardDatePicker;
