import React, { useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { last } from 'lodash';

import { isInputCorrect } from 'utils';
import { asResultClass, useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { Period } from '@agoy/api-sdk-core';
import { ActualBalances, AccountBalance, Specifications } from '../../types';
import { HiddenGroupRow as HiddenGroupRowType } from '../../RowContext/types';
import { formatValue } from '../../utils';
import { getAccountRows } from '../utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  justify-content: center;
`;

const CheckboxLabel = styled(FormControlLabel)`
  margin-left: 0;
`;

const DifferenceContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type CheckboxRowProps = {
  clientId: string;
  row: HiddenGroupRowType;
  periodBalances: AccountBalance;
  actualBalances: ActualBalances;
  specifications: Specifications;
  updateCheckedHistory: (
    lastPeriod: Period,
    hasAccountsQualityChecked: boolean
  ) => void;
};

const ActualBalanceAndQualityCheckboxes = ({
  clientId,
  row,
  periodBalances,
  actualBalances,
  specifications,
  updateCheckedHistory,
}: CheckboxRowProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { period, balance } = row;

  const lastPeriod = last(period.periods);

  const periodStatus =
    useSelector(
      (state) => state.accountingView.clients[clientId].periodStatus
    ) || undefined;

  const currentPeriodStatus =
    periodStatus && lastPeriod && periodStatus?.[lastPeriod.id]?.status;

  const isLocked = currentPeriodStatus === 'LOCKED';

  const accountRows = useMemo(() => getAccountRows(row.rows), [row.rows]);

  const totalDifference = useMemo(() => {
    if (!balance.ub) {
      return 0;
    }

    const totalBalanceSum = Object.keys(actualBalances).reduce(
      (total, key) => total + (actualBalances[key]?.final?.balance || 0),
      0
    );

    return balance.ub - totalBalanceSum;
  }, [actualBalances, balance.ub]);

  // Check if all accounts in the group have their actual balances confirmed
  const hasActualBalancesConfirmed = useMemo(() => {
    return accountRows.every((account) =>
      isInputCorrect(
        `${actualBalances[account.accountNumber]?.final?.balance}`,
        periodBalances[account.accountNumber]?.ub || 0
      )
    );
  }, [accountRows, actualBalances, periodBalances]);

  // Check if all accounts in the group have been quality checked
  const hasAccountsQualityChecked = useMemo(() => {
    return accountRows.every(
      (account) => actualBalances[account.accountNumber]?.final?.checked
    );
  }, [accountRows, actualBalances]);

  const isEnabledGroupCheckbox = accountRows.every((accountRow) => {
    const { accountNumber } = accountRow;
    const actualBalance = actualBalances[accountNumber]?.final?.balance;
    const ub = periodBalances[accountNumber]?.ub;

    return specifications[accountNumber]?.length
      ? isInputCorrect(`${actualBalance}`, ub)
      : true;
  });

  // Update the actual balance for the accounts in the group for the period
  // Sets the balance to null if the checkbox is already checked
  // To unconfirm the actual balance of all of the group's accounts
  const onPutActualBalance = useCallback(async () => {
    if (!lastPeriod) {
      return;
    }

    const accountsToUpdate = accountRows
      .map((account) => Number(account.accountNumber))
      .filter((account) => !specifications[account]?.length);

    try {
      await sdk.putActualBalance({
        clientid: clientId,
        periodId: lastPeriod.id,
        account: accountsToUpdate,
        preliminary: lastPeriod.preliminary ?? false,
        requestBody: {
          balance: hasActualBalancesConfirmed ? null : 'current',
        },
      });
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [
    lastPeriod,
    accountRows,
    specifications,
    sdk,
    clientId,
    hasActualBalancesConfirmed,
    dispatch,
  ]);

  // Sets the quality checked value for all of the group's accounts
  // Or unchecks the value if all of the accounts are already checked
  const onChangeQualityCheckedValue = useCallback(async () => {
    if (!lastPeriod) {
      return;
    }

    const accountsToUpdate = accountRows.map((account) =>
      Number(account.accountNumber)
    );

    try {
      await asResultClass(
        sdk.putActualBalance({
          clientid: clientId,
          periodId: lastPeriod.id,
          account: accountsToUpdate,
          preliminary: lastPeriod.preliminary ?? false,
          requestBody: {
            checked: !hasAccountsQualityChecked,
          },
        })
      );
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
      // eslint-disable-next-line no-console
      console.error(error);
    }

    // Log the updated checked history for the group
    updateCheckedHistory(lastPeriod, !hasAccountsQualityChecked);
  }, [
    lastPeriod,
    accountRows,
    updateCheckedHistory,
    hasAccountsQualityChecked,
    sdk,
    clientId,
    dispatch,
  ]);

  const onSaldoCheckboxClick = () => {
    onPutActualBalance();
  };

  const onQualityCheck = () => {
    onChangeQualityCheckedValue();
  };

  return (
    <Container>
      <CheckboxLabel
        label={formatMessage({
          id: 'reconciliation.hidden.group.checkAccounts',
        })}
        control={
          <Checkbox
            color="primary"
            checkedIcon={<CheckBoxOutlined />}
            checked={hasActualBalancesConfirmed}
            onClick={onSaldoCheckboxClick}
            disabled={isLocked || !isEnabledGroupCheckbox}
          />
        }
        labelPlacement="end"
      />

      <CheckboxLabel
        label={formatMessage({ id: 'hidden.event.checked' })}
        control={
          <Checkbox
            color="primary"
            checkedIcon={<CheckBoxOutlined />}
            checked={hasAccountsQualityChecked}
            onClick={onQualityCheck}
          />
        }
        labelPlacement="end"
        disabled={!hasActualBalancesConfirmed || isLocked}
      />
      <DifferenceContainer>
        <Typography>
          {formatMessage({ id: 'reconciliation.hidden.group.difference' })}
        </Typography>
        <Typography>{formatValue(totalDifference)}</Typography>
      </DifferenceContainer>
    </Container>
  );
};

export default ActualBalanceAndQualityCheckboxes;
