import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { VoucherStatusType } from 'types/Voucher';
import { beige } from '@agoy/theme/src/colors';
import { green, yellow } from '@agoy/theme/src/colors-fortnox';

type VoucherStatusProps = {
  status: VoucherStatusType;
};

const getVoucherStatusColor = (status: VoucherStatusType) => {
  if (status === VoucherStatusType.SUGGESTION) return `${beige.LOW}`;
  if (status === VoucherStatusType.PRELIMINARY) return `${yellow.MEDIUM}`;
  if (status === VoucherStatusType.FINAL) return `${green.MEDIUM}`;
};

const StatusWrapper = styled.div<{
  status: VoucherStatusType;
}>`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  justify-content: center;
  background: ${({ status }) => getVoucherStatusColor(status)};
  font-weight: 400;
  font-size: 14px;
  border-radius: 28px;
`;

const VoucherStatus = ({ status }: VoucherStatusProps) => {
  const { formatMessage } = useIntl();
  return (
    <StatusWrapper status={status}>
      {formatMessage({
        id: `reconciliation.speedDial.voucher.status.${status}`,
      })}
    </StatusWrapper>
  );
};

export default VoucherStatus;
