import React, { forwardRef } from 'react';
import { Control, FieldValues, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { gray } from '@agoy/theme/src/colors-fortnox';
import { SimplifiedSelect } from '_shared/components/Inputs';
import { FieldWrapperProps } from './FieldWrapper';
import { validation } from '../utils';

interface ClientCardInformationSelectProps extends FieldWrapperProps {
  listItems: Readonly<
    Array<{
      value: string | number;
      label: string;
    }>
  >;
  name: string;
  placeholder?: string;
  isEditing?: boolean;
  disabled?: boolean;
  control?: Control<FieldValues>;
}

const InnerTitle = styled.div`
  padding: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(2)}px;
  font-family: 'Exo 2';
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
`;

const StyledSimplifiedSelect = styled(SimplifiedSelect)`
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${gray.HIGH};
  }
`;

const ClientCardDirectorInformationSelect = forwardRef(
  (
    {
      name,
      field,
      listItems,
      control,
      isEditing,
      disabled,
    }: ClientCardInformationSelectProps,
    inputRef
  ) => {
    const { formatMessage } = useIntl();

    return (
      <Controller
        name={name}
        control={control}
        rules={validation[field]}
        render={({ field: { ref, value, ...rest } }) => {
          return isEditing ? (
            <StyledSimplifiedSelect
              listItems={listItems}
              disabled={disabled}
              editing={isEditing}
              inputRef={ref}
              fullWidth
              value={value ?? ''}
              {...rest}
            />
          ) : (
            <InnerTitle>
              {formatMessage({ id: `clientInformation.${value}` })}
            </InnerTitle>
          );
        }}
      />
    );
  }
);

export default ClientCardDirectorInformationSelect;
