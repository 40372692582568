import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
  ReconciliationBalanceKeyFigure,
} from '@agoy/api-sdk-core';
import { isSameOrAfter, isSameOrBefore, parse } from '@agoy/dates';
import { AccountRowWithMoving } from '@agoy/reconciliation';

import {
  RowWithoutClassName,
  Row,
  MovedRows,
  MovedAccountsBalance,
} from '../../RowContext/types';
import { ReconciliationBalanceAccountRowWithMoving } from '../../types';

export type InitialRow =
  | ReconciliationBalanceAccountRow
  | ReconciliationBalanceAccountRowWithMoving
  | ReconciliationBalanceAccountGroup
  | ReconciliationBalanceKeyFigure;

const toAccountRow = (
  row: ReconciliationBalanceAccountRow
): RowWithoutClassName => ({
  type: 'account',
  accountNumber: `${row.number}`,
  id: `${row.number}`,
});

const toKeyFigureRow = (
  row: ReconciliationBalanceKeyFigure
): RowWithoutClassName => ({
  type: 'keyFigure',
  id: row.id,
});

const mapRow = (
  row: InitialRow,
  groupsWithHiddenRow
): RowWithoutClassName[] => {
  if (row.type === 'account') {
    return [toAccountRow(row)];
  }
  if (row.type === 'group') {
    return toGroupRows(row, groupsWithHiddenRow);
  }

  return [toKeyFigureRow(row)];
};

const toGroupRows = (
  row: ReconciliationBalanceAccountGroup,
  groupsWithHiddenRow: string[]
): Row[] => {
  const groupRows = row.rows
    .flatMap((item) => mapRow(item, groupsWithHiddenRow))
    .map((subRow) => ({ ...subRow, className: '' }));
  return [
    {
      type: 'group',
      id: row.id,
      className: '',
      rows: [
        {
          type: 'groupHeader',
          id: row.id,
          className: '',
        },
        ...groupRows,
        {
          type: 'groupSum',
          id: row.id,
          withHiddenRow: groupsWithHiddenRow.includes(row.id),
          groupRows,
          className: '',
        },
      ],
    },
  ];
};

export const extractRows = (
  rows: InitialRow[],
  groupsWithHiddenRow: string[]
): RowWithoutClassName[] =>
  rows.flatMap((row) => {
    switch (row.type) {
      case 'account':
        return toAccountRow(row);
      case 'group':
        return toGroupRows(row, groupsWithHiddenRow);
      case 'key':
        return [
          {
            type: 'keyFigure',
            id: row.id,
          },
        ];
      default:
        return [];
    }
  });

export const createEmptyAccount = (
  number: number,
  movedToGroup?: string
): ReconciliationBalanceAccountRowWithMoving => ({
  type: 'account',
  number,
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  ib: 0,
  state: 'not_started',
  ub: 0,
  change: 0,
  isEmpty: !!movedToGroup,
  movedToGroup,
});

export const createEmptyGroup = (
  id: string
): ReconciliationBalanceAccountGroup => ({
  id,
  type: 'group',
  state: 'not_started',
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  rows: [],
  sum: {
    ib: 0,
    ub: 0,
    id: `${id}_sum`,
    type: 'key',
    change: 0,
  },
});

export const getClosestPeriod = (period: string, periods: string[]) => {
  return periods.reduce((prevValue, item) => {
    if (isSameOrBefore(parse(item), parse(period))) {
      if (!prevValue) {
        return item;
      }

      return isSameOrBefore(parse(item), parse(prevValue)) ? prevValue : item;
    }
    return prevValue;
  }, '');
};

export const injectMovedRows = (
  rows: InitialRow[],
  movedAccountData: MovedAccountsBalance,
  parentGroupId = 'noGroup'
): InitialRow[] => {
  if (!Object.keys(movedAccountData).length) {
    return rows;
  }
  const res = rows.reduce((prev, row) => {
    if (row.type === 'group') {
      const newAccounts: ReconciliationBalanceAccountRowWithMoving[] = [];

      const internal = injectMovedRows(row.rows, movedAccountData, row.id);

      Object.values(movedAccountData[row.id] || {}).forEach((accountRow) => {
        if (
          accountRow &&
          !internal.some(
            (item) =>
              item.type === 'account' && item.number === accountRow.number
          )
        ) {
          newAccounts.push(accountRow);
        }
      });

      return [
        ...prev,
        {
          ...row,
          rows: [...newAccounts, ...internal],
        },
      ];
    }

    if (row.type === 'account') {
      const movedAccount = movedAccountData[parentGroupId]?.[row.number];

      if (movedAccount) {
        return [...prev, movedAccount];
      }

      if (movedAccount === null) {
        return [...prev];
      }
    }

    return [...prev, row];
  }, [] as InitialRow[]);

  return res;
};

const getBalanceValues = (
  subRows: InitialRow[],
  parentGroupId = 'noGroup'
): Record<string, ReconciliationBalanceAccountRowWithMoving> => {
  return subRows.reduce((prev, row) => {
    if (row.type === 'group') {
      return { ...prev, ...getBalanceValues(row.rows, row.id) };
    }
    if (row.type === 'account' && parentGroupId) {
      return { ...prev, [`${parentGroupId}.${row.number}`]: row };
    }
    return prev;
  }, {});
};

const getAccountBalance = (
  balance: Record<string, ReconciliationBalanceAccountRowWithMoving>,
  accountNumber: string,
  movedToGroup?: string
) => {
  const accountKey = Object.keys(balance).find(
    (key) => key.includes(accountNumber) && !balance[key].movedToGroup
  );

  if (accountKey) {
    return { ...balance[accountKey], movedToGroup };
  }

  return createEmptyAccount(+accountNumber, movedToGroup);
};

export const getMovedAccountsBalance = (
  rows: InitialRow[],
  movedRows: MovedRows,
  periodStart: string,
  financialYearStart: string
): MovedAccountsBalance => {
  const balance = getBalanceValues(rows);

  const result = {};

  Object.keys(movedRows).forEach((accountNumber) => {
    const periods = Object.keys(movedRows[accountNumber]).sort((a, b) =>
      isSameOrAfter(parse(a), parse(b)) ? -1 : 1
    );

    const isFullRow = periods.length === 1 && financialYearStart === periods[0];
    const closestPeriod = getClosestPeriod(periodStart, periods);

    if (!isFullRow) {
      periods.forEach((period) => {
        const { fromGroupId, toGroupId } = movedRows[accountNumber][period];

        // add empty balance for account moved in the next periods
        result[toGroupId] = {
          ...result[toGroupId],
          [accountNumber]: createEmptyAccount(+accountNumber, fromGroupId),
        };

        result[fromGroupId] = {
          ...result[fromGroupId],
          [accountNumber]: getAccountBalance(balance, accountNumber),
        };
      });
    }

    if (closestPeriod) {
      const { fromGroupId, toGroupId } =
        movedRows[accountNumber][closestPeriod];
      result[toGroupId] = {
        ...result[toGroupId],
        [accountNumber]: getAccountBalance(balance, accountNumber, toGroupId),
      };

      result[fromGroupId] = {
        ...result[fromGroupId],
        [accountNumber]: isFullRow
          ? null
          : createEmptyAccount(+accountNumber, toGroupId),
      };
    }
  });

  return result;
};

export const getMovedAccounts = (
  rows: InitialRow[],
  movedRows: MovedRows,
  periodStart: string
): { [accountNumber: string]: AccountRowWithMoving } => {
  const balance = getBalanceValues(rows);

  const result = {};

  Object.keys(movedRows).forEach((accountNumber) => {
    const periods = Object.keys(movedRows[accountNumber]).sort((a, b) =>
      isSameOrAfter(parse(a), parse(b)) ? -1 : 1
    );

    const closestPeriod = getClosestPeriod(periodStart, periods);

    if (closestPeriod) {
      const { toGroupId } = movedRows[accountNumber][closestPeriod];

      result[accountNumber] = getAccountBalance(
        balance,
        accountNumber,
        toGroupId
      );
    }
  });

  return result;
};

export const checkBalanceValue = (value: number) => {
  return Number.isNaN(value) ? null : value;
};
