import React from 'react';
import { ClientInfoContextProvider } from './CompanyData';

export const withCreditSafe = (Component) => (props) => {
  return (
    <ClientInfoContextProvider>
      <Component {...props} />
    </ClientInfoContextProvider>
  );
};
