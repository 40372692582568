import React, { useCallback, useMemo, useState } from 'react';
import { isSameOrAfter, parse } from '@agoy/dates';
import {
  MovedAccountsBalance,
  MovedRows,
  UpdatedAccountBalance,
  UpdatedPeriodsBalance,
  UpdatedYearEndBalance,
} from './types';

type RowContextType = {
  movedAccounts: MovedRows;
  updatedBalance: UpdatedPeriodsBalance;
  updatedYearEndBalance: UpdatedYearEndBalance;
  moveRow: (
    accountNumber: string,
    periodStart: string,
    fromGroupId: string,
    toGroupId: string
  ) => void;
  setUpdatedBalance: (
    periodStart: string,
    accountsChanges: MovedAccountsBalance
  ) => void;
  restoreChanges: () => void;
};

const MoveAccountsContext = React.createContext<RowContextType>({
  movedAccounts: {},
  updatedBalance: {},
  updatedYearEndBalance: {},
  moveRow: () => {
    throw new Error('No context');
  },
  setUpdatedBalance: () => {
    throw new Error('No context');
  },
  restoreChanges: () => {
    throw new Error('No context');
  },
});

type MoveAccountsContextProviderProps = {
  children: React.ReactNode;
};

export const MoveAccountsContextProvider = ({
  children,
}: MoveAccountsContextProviderProps) => {
  const [movedAccounts, setMovedAccounts] = useState<MovedRows>({});
  const [updatedBalance, setUpdatedBalance] = useState<UpdatedPeriodsBalance>(
    {}
  );

  const updatedYearEndBalance: UpdatedYearEndBalance = useMemo(() => {
    const periods = Object.keys(updatedBalance);

    if (!periods.length) {
      return {};
    }

    periods.sort((a, b) => (isSameOrAfter(parse(a), parse(b)) ? 1 : -1));

    return periods.reduce((periodsResult, period) => {
      const accountByGroups = updatedBalance[period];

      const periodResult: Record<string, UpdatedAccountBalance> = Object.keys(
        accountByGroups
      ).reduce((prev, groupKey) => {
        const changedAccounts = accountByGroups[groupKey];

        const accounts: Record<string, UpdatedAccountBalance> = Object.keys(
          changedAccounts
        ).reduce((accountsResult, accountNumber) => {
          const account = changedAccounts[accountNumber];

          if (account && !account.movedToGroup) {
            return {
              ...accountsResult,
              [`${groupKey}.${accountNumber}`]: {
                change: account.change,
                ib: account.ib,
                ub: account.ub,
              },
            };
          }
          return accountsResult;
        }, {});

        return { ...prev, ...accounts };
      }, {});

      const result = { ...periodsResult };

      Object.keys(periodResult).forEach((key) => {
        result[key] = {
          change: (periodsResult[key]?.change || 0) + periodResult[key].change,
          ib: periodsResult[key]?.ib || periodResult[key].ib,
          ub: periodResult[key].ub,
        };
      });

      return { ...periodsResult, ...result };
    }, {});
  }, [updatedBalance]);

  const moveRow = useCallback(
    (
      accountNumber: string,
      period: string,
      fromGroupId: string,
      toGroupId: string
    ) => {
      const accounts = { ...movedAccounts };
      const account = accounts[accountNumber] || {};

      // we moved account for this period already
      if (account[period]) {
        // if we move it back need to delete changes for this period
        if (account[period].fromGroupId === toGroupId) {
          delete accounts[accountNumber][period];
        } else {
          // if we move it to new place
          accounts[accountNumber][period].toGroupId = toGroupId;
        }
      } else {
        // check if we move account to this group again,
        // in this case remove previous period changes
        const existingGroupPeriodKey = Object.keys(account).find(
          (periodKey) => account[periodKey].toGroupId === toGroupId
        );

        if (existingGroupPeriodKey) {
          delete accounts[accountNumber][existingGroupPeriodKey];
        }

        accounts[accountNumber] = {
          ...account,
          [period]: { fromGroupId, toGroupId },
        };
      }

      setMovedAccounts(accounts);
    },
    [movedAccounts]
  );

  const handleSetUpdatedBalance = useCallback(
    (periodStart: string, accountsChanges: MovedAccountsBalance) => {
      setUpdatedBalance((currentValue) => ({
        ...currentValue,
        [periodStart]: accountsChanges,
      }));
    },
    []
  );

  const rowContext = useMemo((): RowContextType => {
    return {
      movedAccounts,
      updatedBalance,
      updatedYearEndBalance,
      moveRow,
      setUpdatedBalance: handleSetUpdatedBalance,
      restoreChanges: () => setMovedAccounts({}),
    };
  }, [
    movedAccounts,
    updatedBalance,
    updatedYearEndBalance,
    moveRow,
    handleSetUpdatedBalance,
  ]);

  return (
    <MoveAccountsContext.Provider value={rowContext}>
      {children}
    </MoveAccountsContext.Provider>
  );
};

export default MoveAccountsContext;
