import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
} from '../../types';
import { ref, value, sum, id, multiply } from '@agoy/document';
import {
  assetsId as utilAssetsId,
  incomeId as utilIncomeId,
  toInitial,
  equityAndLiabilitiesId as utilEquityAndLiabilitiesId,
  mapFormId,
  toStructure,
} from '../forms-util';

const assetsId = (id: string) => utilAssetsId(id, true);
const incomeId = (id: string) => utilIncomeId(id, true);
const equityAndLiabilitiesId = (id: string) =>
  utilEquityAndLiabilitiesId(id, true);

const configForenklatArsbokslut = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const ForenklatArsbokslut = mapFormId(form, 'ForenklatArsbokslut');
  const initial = {
    ForenklatArsbokslut: {
      partType: 'form' as const,
      id: ForenklatArsbokslut,
      fields: {
        name: value(customer.name),
        persOrOrgNumber: value(customer.orgNumber),
        fromDate: value(financialYearStart),
        toDate: value(financialYearEnd),
        businessNature: value(''),
        B_1: ref(assetsId('fixedAndCurrentAssets.intangibleAssets.1')),
        B_2: ref(assetsId('fixedAndCurrentAssets.intangibleAssets.2')),
        B_3: ref(assetsId('fixedAndCurrentAssets.intangibleAssets.3')),
        B_4: ref(assetsId('fixedAndCurrentAssets.intangibleAssets.4')),
        B_5: ref(assetsId('fixedAndCurrentAssets.intangibleAssets.5')),
        B_6: ref(assetsId('fixedAndCurrentAssets.inventory.1')),
        B_7: ref(assetsId('fixedAndCurrentAssets.inventory.2')),
        B_8: ref(assetsId('fixedAndCurrentAssets.inventory.3')),
        B_9: ref(assetsId('fixedAndCurrentAssets.inventory.4')),
        summa_tillgangar: ref(
          sum(
            id('ForenklatArsbokslut.fields.B_1'),
            id('ForenklatArsbokslut.fields.B_2'),
            id('ForenklatArsbokslut.fields.B_3'),
            id('ForenklatArsbokslut.fields.B_4'),
            id('ForenklatArsbokslut.fields.B_5'),
            id('ForenklatArsbokslut.fields.B_6'),
            id('ForenklatArsbokslut.fields.B_7'),
            id('ForenklatArsbokslut.fields.B_8'),
            id('ForenklatArsbokslut.fields.B_9')
          )
        ),
        B_10: ref(equityAndLiabilitiesId('equity.restrictedEquity.1')),
        B_13: ref(equityAndLiabilitiesId('liabilities.liabilities.1')),
        B_14: ref(equityAndLiabilitiesId('liabilities.liabilities.2')),
        B_15: ref(equityAndLiabilitiesId('liabilities.liabilities.3')),
        B_16: ref(equityAndLiabilitiesId('liabilities.liabilities.4')),
        summa_skulder: ref(
          sum(
            id('ForenklatArsbokslut.fields.B_10'),
            id('ForenklatArsbokslut.fields.B_13'),
            id('ForenklatArsbokslut.fields.B_14'),
            id('ForenklatArsbokslut.fields.B_15'),
            id('ForenklatArsbokslut.fields.B_16')
          )
        ),
        R_1: ref(incomeId('incomes.1')),
        R_2: ref(incomeId('incomes.2')),
        R_3: ref(incomeId('incomes.3')),
        R_4: ref(incomeId('incomes.4')),
        R_5: ref(multiply(-1, incomeId('expenses.1'))),
        R_6: ref(multiply(-1, incomeId('expenses.2'))),
        R_7: ref(multiply(-1, incomeId('expenses.3'))),
        R_8: ref(multiply(-1, incomeId('expenses.4'))),
        R_9: ref(multiply(-1, incomeId('taxes.1'))),
        R_10: ref(multiply(-1, incomeId('taxes.2'))),
        R_11: ref(incomeId('result.1_HideInPreviewAndPrint')),
        U_1: value(undefined),
        U_2: value(undefined),
        U_3: value(undefined),
        U_4: value(undefined),
      },
      sru: {},
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configForenklatArsbokslut;
