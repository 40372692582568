import React, { useState, useCallback, useMemo } from 'react';
import { ApiReturnType, asResultClass, useApiSdk } from 'api-sdk';
import {
  CSDetailedCompany,
  ClientInformation as ClientInformationType,
  ClientInformationField,
  ClientInformationUpdate,
} from '@agoy/api-sdk-core';

type ClientInformation = {
  [clientId: string]:
    | {
        general: ClientInformationType;
        [financialYearId: string]: ClientInformationType;
      }
    | undefined;
};

interface ClientInfoContextType {
  companyData: { [clientId: string]: CSDetailedCompany };
  clientInformation: ClientInformation;
  getCompanyData: (
    clientId: string
  ) => Promise<ApiReturnType<'getCsDetailedInformation'>>;
  getClientInformation: (
    clientId: string,
    financialYearId?: number
  ) => Promise<ApiReturnType<'getClientInformation'>>;
  patchClientInformation: (
    clientId: string,
    clientInformation: ClientInformationUpdate,
    source: ClientInformationField['source'],
    personNr?: string,
    financialYearId?: number
  ) => Promise<ApiReturnType<'patchClientInformation'>>;
}

export const ClientInfoContext = React.createContext<ClientInfoContextType>({
  companyData: {},
  clientInformation: {},
  getCompanyData: () => Promise.resolve({}),
  getClientInformation: () => Promise.resolve({}),
  patchClientInformation: () => Promise.resolve({}),
});

type ClientInfoContextProviderProps = {
  defaultClientInformation?: ClientInformation;
  children: React.ReactNode;
};

export const ClientInfoContextProvider = ({
  defaultClientInformation,
  children,
}: ClientInfoContextProviderProps): JSX.Element => {
  const [companyData, setCompanyData] = useState({});
  const [clientInformation, setClientInformation] = useState<ClientInformation>(
    defaultClientInformation || {}
  );

  const sdk = useApiSdk();

  const fetchCompanyData = useCallback(
    async (clientId: string) => {
      const data = await asResultClass(
        sdk.getCsDetailedInformation({ clientid: clientId })
      );

      Object.keys(data.val).forEach((key) => {
        if (data.val[key] === undefined) {
          delete data.val[key];
        }
      });

      if (data.err) throw new Error('fetching failed');

      setCompanyData((currentData) => ({
        ...currentData,
        ...{
          [clientId]: data.val,
        },
      }));
      return data.val;
    },
    [sdk]
  );

  // Get CS data
  const getCompanyData = useCallback(
    async (clientId: string): Promise<CSDetailedCompany> => {
      if (!companyData[clientId]) {
        return fetchCompanyData(clientId);
      }

      return companyData[clientId];
    },
    [companyData, fetchCompanyData]
  );

  const fetchClientInformation = useCallback(
    async (
      clientId: string,
      financialYearId?: number
    ): Promise<ApiReturnType<'getClientInformation'>> => {
      const registerData = await asResultClass(
        sdk.getClientInformation({ clientid: clientId, financialYearId })
      );

      if (registerData.err) throw new Error('fetching failed');

      if (financialYearId) {
        setClientInformation((currentData) => ({
          ...currentData,
          ...{
            [clientId]: {
              ...currentData[clientId],
              general: registerData.val,
              [financialYearId]: registerData.val[financialYearId],
            },
          },
        }));
      }

      setClientInformation((currentData) => ({
        ...currentData,
        ...{
          [clientId]: {
            ...currentData[clientId],
            general: registerData.val,
          },
        },
      }));

      return registerData.val;
    },
    [sdk]
  );

  // need to fix, create new function on every use of fetchClientInformation,
  // so useCallback is useless now
  const getClientInformation = useCallback(
    async (
      clientId: string,
      financialYearId?: number
    ): Promise<ApiReturnType<'getClientInformation'>> => {
      const clientInfoData = clientInformation[clientId];

      if (
        financialYearId &&
        (!clientInfoData || !clientInfoData[financialYearId])
      ) {
        return fetchClientInformation(clientId, financialYearId);
      }

      if (!clientInfoData || !clientInfoData.general) {
        return fetchClientInformation(clientId, financialYearId);
      }

      if (financialYearId) {
        return clientInfoData[financialYearId];
      }
      return clientInfoData.general;
    },
    [clientInformation, fetchClientInformation]
  );

  const patchClientInformation = useCallback(
    async (
      clientId: string,
      data: ClientInformationUpdate,
      source: 'cs' | 'user' | 'fortnox'
    ) => {
      const result = await asResultClass(
        sdk.patchClientInformation({
          clientid: clientId,
          requestBody: data,
          source,
        })
      );

      await fetchClientInformation(clientId);

      return result;

      // if (data.type === 'full') {
      //   setClientInformation((currentValue) => {
      //     // if (financialYearId) {
      //     //   return {
      //     //     ...currentValue,
      //     //     ...{
      //     //       [clientId]: {
      //     //         ...centralRegister[clientId],
      //     //         [financialYearId]: {
      //     //           ...centralRegister[clientId][financialYearId],
      //     //           ...data,
      //     //         },
      //     //       },
      //     //     },
      //     //   };
      //     // }
      //     return {
      //       ...currentValue,
      //       ...{
      //         [clientId]: {
      //           ...centralRegister[clientId],
      //           general: {
      //             ...centralRegister[clientId].general,
      //             ...data.data,
      //           },
      //         },
      //       },
      //     };
      //   });
      // }
    },
    [fetchClientInformation, sdk]
  );

  const value = useMemo(
    () => ({
      companyData,
      clientInformation,
      getCompanyData,
      getClientInformation,
      patchClientInformation,
    }),
    [
      clientInformation,
      companyData,
      getClientInformation,
      getCompanyData,
      patchClientInformation,
    ]
  );

  return (
    <ClientInfoContext.Provider value={value}>
      {children}
    </ClientInfoContext.Provider>
  );
};

export default ClientInfoContextProvider;
