import { ReferenceAccountInformation, TimePeriod } from '@agoy/document';
import { AnnualReportCustomerType } from '../common';
import { AnnualReportType, DocumentConfiguration } from '../document';
import {
  balanceSheetConfig,
  balanceSheetReferences,
} from './balance-sheet/v1/balanceSheet';
import {
  cashFlowStatementConfig,
  cashFlowStatementReferences,
} from './cash-flow-statement/v1/cashFlowStatement';
import {
  incomeStatementConfig,
  incomeStatementReferences,
} from './income-statement/v1/incomeStatement';
import {
  managementReportConfig,
  managementReportReferences,
} from './management-report/managementReport';
import { notesConfig } from './notes/v1/notes';
import { getSharesCompanyNotesGeneratedV2K2 } from './notes/v2/k2/constants';
import { notesConfigV2K2Config } from './notes/v2/k2/notes';
import { getSharesCompanyNotesGeneratedV2K3 } from './notes/v2/k3/constants';
import { notesConfigV2K3Config } from './notes/v2/k3/notes';

import { cashFlowStatementK2v2Config } from './cash-flow-statement/v2/k2/cashflowstatementk2v2';
import { cashFlowStatementK3v2Config } from './cash-flow-statement/v2/k3/cashflowstatementk3v2';

import { balanceStatementK2v2Config } from './balance-sheet/v2/k2/balancestatementk2v2';
import { balanceStatementK3v2Config } from './balance-sheet/v2/k3/balancestatementk3v2';

import { manualSubmissionConfig } from '../common/types/manualSubmission';
import { getIncomeStatement } from '../common/utils/util';
import { annualGeneralMeetingConfig } from './annual-general-meeting/annualGeneralMeeting';
import { confirmationCertificateConfig } from './confirmation-certificate/confirmationCertificate';
import { digitalSubmissionConfig } from './digital-submission/digitalSubmission';
import {
  SharesCompanyAnnualReport,
  SharesCompanyAnnualReportK2V2,
  SharesCompanyAnnualReportK3V2,
  getSharesOtherNotes,
  sharesBalanceSheetNotes,
  sharesIncomeStatementNotes,
} from './document';
import { settingsConfig } from './settings/settings';
import { signaturesConfig } from './signatures/signatures';
import { ClientInformationTables } from './types';

/**
 * Creates a new configuration of a annual report
 * @param customer
 * @param period  The time period for the financial year's start to
 *                the end of the current month
 * @param previousPeriod  The time period for the previous year's start
 *                        to the end of the current month minus one year
 * @param documentConfiguration Settings that affect the content of the report.
 */
export const getSharesCompanyNotes = (
  reportType: AnnualReportType,
  documentTypeVersion = '1'
) => {
  if (documentTypeVersion === '2') {
    if (reportType === 'k2') {
      return getSharesCompanyNotesGeneratedV2K2;
    }
    return getSharesCompanyNotesGeneratedV2K3;
  }

  return {
    incomeStatementNotes: sharesIncomeStatementNotes,
    balanceSheetNotes: sharesBalanceSheetNotes,
    otherNotes: getSharesOtherNotes(reportType, documentTypeVersion),
  };
};

export const sharesCompanyConfigV2 = (
  customer: AnnualReportCustomerType,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  accounts: Record<string, ReferenceAccountInformation>,
  documentConfiguration: DocumentConfiguration,
  isSinglePeriod: boolean,
  clientInformationTables?: ClientInformationTables
): SharesCompanyAnnualReportK2V2 | SharesCompanyAnnualReportK3V2 => {
  // Check if the current financial year is the customer's first financial year
  const hasNoPreviousYear = period._value === customer.financialYears?.[0];

  const isFirstFinancialYear =
    documentConfiguration.financialYears?.length === 1;

  const reportTransitionK3toK2 = documentConfiguration.reportTransitionK3toK2;

  const currentYear = documentConfiguration.financialYears[0];
  const financialYear = TimePeriod.fromISODates(
    currentYear.start,
    currentYear.end
  ).value;

  const { incomeStatement } = documentConfiguration;

  const isK3 = documentConfiguration.reportType === 'k3';

  return {
    documentType: 'shares',
    version: '2',
    settings: settingsConfig(
      customer,
      financialYear,
      period,
      previousPeriod,
      isFirstFinancialYear,
      documentConfiguration.isDigitalSubmission || false,
      hasNoPreviousYear,
      isSinglePeriod,
      reportTransitionK3toK2
    ),
    managementReport: managementReportConfig(
      documentConfiguration.reportType,
      documentConfiguration.reportTransitionK3toK2,
      documentConfiguration.isDigitalSubmission,
      documentConfiguration.financialYears || [],
      '2'
    ),
    incomeStatement: getIncomeStatement(
      incomeStatement,
      isK3,
      period,
      previousPeriod,
      accounts,
      documentConfiguration.version,
      documentConfiguration.reportType,
      'ub',
      documentConfiguration.isDigitalSubmission
    ),
    balanceSheet: isK3
      ? balanceStatementK3v2Config(
          accounts,
          period,
          previousPeriod,
          documentConfiguration.reportType,
          documentConfiguration.isDigitalSubmission,
          documentConfiguration.version,
          'ub'
        )
      : balanceStatementK2v2Config(
          accounts,
          period,
          previousPeriod,
          documentConfiguration.reportType,
          documentConfiguration.isDigitalSubmission,
          documentConfiguration.version,
          'ub'
        ),
    cashFlowStatement: isK3
      ? cashFlowStatementK3v2Config(
          accounts,
          period,
          previousPeriod,
          documentConfiguration.reportType,
          documentConfiguration.isDigitalSubmission,
          documentConfiguration.version,
          'change'
        )
      : cashFlowStatementK2v2Config(
          accounts,
          period,
          previousPeriod,
          documentConfiguration.reportType,
          documentConfiguration.isDigitalSubmission,
          documentConfiguration.version,
          'change'
        ),
    notes: isK3
      ? notesConfigV2K3Config(
          accounts,
          customer.financialYears ?? [],
          financialYear,
          period.end,
          documentConfiguration.isDigitalSubmission ?? false
        )
      : notesConfigV2K2Config(
          accounts,
          customer.financialYears ?? [],
          financialYear,
          period.end,
          documentConfiguration.isDigitalSubmission ?? false
        ),
  };
};

export const sharesCompanyConfig = (
  customer: AnnualReportCustomerType,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  accounts: Record<string, ReferenceAccountInformation>,
  documentConfiguration: DocumentConfiguration,
  isSinglePeriod: boolean,
  clientInformationTables?: ClientInformationTables
): SharesCompanyAnnualReport => {
  // Check if the current financial year is the customer's first financial year
  const hasNoPreviousYear = period._value === customer.financialYears?.[0];

  const isFirstFinancialYear =
    documentConfiguration.financialYears?.length === 1;

  const reportTransitionK3toK2 = documentConfiguration.reportTransitionK3toK2;

  const currentYear = documentConfiguration.financialYears[0];
  const financialYear = TimePeriod.fromISODates(
    currentYear.start,
    currentYear.end
  ).value;

  return {
    documentType: 'shares',
    version: '1',
    settings: settingsConfig(
      customer,
      financialYear,
      period,
      previousPeriod,
      isFirstFinancialYear,
      documentConfiguration.isDigitalSubmission || false,
      hasNoPreviousYear,
      isSinglePeriod,
      reportTransitionK3toK2
    ),
    confirmationCertificate: confirmationCertificateConfig(false),
    managementReport: managementReportConfig(
      documentConfiguration.reportType,
      documentConfiguration.reportTransitionK3toK2,
      documentConfiguration.isDigitalSubmission,
      documentConfiguration.financialYears || [],
      '1'
    ),
    incomeStatement: incomeStatementConfig(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.reportType,
      documentConfiguration.isDigitalSubmission,
      // always 1 here in sharesCompanyConfig
      documentConfiguration.version
    ),
    balanceSheet: balanceSheetConfig(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.reportType,
      'previousYear',
      documentConfiguration.isDigitalSubmission,
      // always 1 here in sharesCompanyConfig
      documentConfiguration.version
    ),
    cashFlowStatement: cashFlowStatementConfig(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.financialYears,
      'previousYear',
      !!documentConfiguration.isDigitalSubmission
    ),
    notes: notesConfig(
      accounts,
      customer.financialYears || [],
      financialYear,
      period.end,
      documentConfiguration.reportType,
      documentConfiguration.isDigitalSubmission || false,
      '1'
    ),
    signatures: signaturesConfig('1', documentConfiguration.reportType),
    annualGeneralMeeting: annualGeneralMeetingConfig(
      false,
      clientInformationTables
    ),
    digitalSubmission: digitalSubmissionConfig(),
    manualSubmission: manualSubmissionConfig(),
  };
};

/**
 * Creates all references that the annual report refers to
 */
export const references = (documentConfiguration: DocumentConfiguration) => {
  const sharedReferences = {};

  const partReferences = [
    managementReportReferences(),
    sharedReferences,
    balanceSheetReferences(),
    incomeStatementReferences(),
    cashFlowStatementReferences,
  ];

  // Verify that there is no name conflict
  let references = {};
  partReferences.forEach((refs) => {
    const keysSoFar = Object.keys(references);
    const conflict = Object.keys(refs).find((ref) => keysSoFar.includes(ref));
    if (conflict) {
      throw new Error(`Conflicting keys in references (${conflict})`);
    }
    references = { ...references, ...refs };
  });

  return references;
};
