export const modalIcon = '#48a6ff';

export const orange = '#F8AF61';
export const white = '#FFFFFF';

export const primaryMain = '#000';
export const primaryLight = '#024453';
export const secondaryMain = '#00CE7C';

export const info = '#335E62';
export const error = '#EA5160';
export const warning = '#F8AF61';
export const success = '#00CE7C';

export const textPrimary = '#000';
export const textSecondary = '#000000a6';

// Specific variations of colors used in account overview cells
export const cellIncomingBalance = '#E1ECF1';
export const cellTotalChange = '#EDF3F6';
export const cellOutgoingBalance = '#F4F8F9';
export const cellOk = '#DCF9E7';
export const cellWarning = '#F9CACF';
export const cellStarted = '#F9EBDC';
export const cellChecked = '#087046';

// Specific variations of colors used in overview
export const accountStatusWrong = '#f4a4ac';
export const currentMonthBackground = orange;
export const closingMonthBackground = primaryMain;

// Transaction analaysis colors
export const gridBorderColor = '#e7e6e6';
export const dimensionBackgroundColor = '#e6e6e6';

// Popup with setting actual saldo
export const popupHeader = '#ccd5d8';

// Table colors
export const headerBackgroundColor = '#f5f5f5';

// Statuses for financial statement
export const NOT_STARTED = { main: '#bdbdbd', darker: '#bdbdbd' };
export const NOT_STARTED_POPUP = { main: '#fff', darker: '#fff' };
export const STARTED = { main: warning, darker: '#de9d58' };
export const AW_COMPL = { main: 'purple', darker: '#570257' };
export const AW_REVIEW = { main: 'dodgerblue', darker: '#1b78d3' };
export const DONE = { main: success, darker: '#02b06a' };
export const LOCKED = { main: success, darker: '#02b06a' };

// Credit Safe icon and buttons color
export const creditSafeColor = '#2e75b8';
export const creditSafeInput = '#d2e7fd';
export const contactInfoCard = '#F5FCF4';
export const organisationCard = '#FEF7EF';
export const boardCard = '#f4fdff';

// Specification info colors
export const grey = '#ededf2';
export const darkGrey = '#e1e4eb';

// Hidden row comments/saldo history
export const infoText = '#08046a';

// Statuses for checklist
export const checklist_in_progress = { main: warning, darker: '#de9d58' };
export const checklist_yes = { main: success, darker: '#02b06a' };
export const checklist_no = { main: error, darker: '#570257' };
export const checklist_not_started = { main: '#bdbdbd', darker: '#bdbdbd' };
export const checklist_not_applicable = { main: '#E1ECF1', darker: '#E1ECF1' };

// Sidebar (main navigation) colors
export const sidebarDividerBackground = '#006880';

// New reconciliation view period header colors
export const periodHeaderYear = '#445277';
export const periodHeaderQuarter = '#6C5675';
export const periodHeaderMonth = '#755656';
export const periodHeaderYearEnd = '#11303B';

export const darkBlue = '#002517';

export const beige = {
  HIGH: '#DEBF9E',
  MEDIUM: '#ECDAC6',
  LOW: '#F8F1E8',
};
