import { useContext } from 'react';
import {
  format,
  eachQuarterOfInterval,
  parseISO,
  endOfQuarter,
  Interval,
  eachMonthOfInterval,
  endOfMonth,
  areIntervalsOverlapping,
} from 'date-fns';
import { last } from 'lodash';
import theme from 'theme';

import { RootState } from 'redux/reducers';
import { TimePeriod } from '@agoy/document';
import { reformatDate, ccyFormat } from '@agoy/common';
import { ClientCompany, ReconciliationState } from '@agoy/api-sdk-core';
import { ClientFinancialYears } from '_clients/types/types';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { DraggableItemTypes } from 'contants';
import { ReconciliationPeriod, PeriodType } from './types';
import DndContext from './RowContext/DndContext';
import MoveAccountsContext from './RowContext/MoveAccountsContext';
import { getHiddenRowGroups } from './RowContext/utils';

type FinancialYears = ClientCompany['financialYears'];

export const getType = (closingPeriod: string | null): PeriodType => {
  if (closingPeriod === 'year') {
    return 'financialYear';
  }

  return closingPeriod as 'quarter' | 'month';
};

const getIntervals = (
  type: Exclude<ReconciliationPeriod['type'], 'dead'>,
  clientId: string,
  financialYears: FinancialYears,
  current: ClientFinancialYears[number],
  eachOfInterval: (interval: Interval) => Date[],
  endOf: (date: Date) => Date
): ReconciliationPeriod[] => {
  if (financialYears.length > 0) {
    const lastFinancialYear = financialYears[financialYears.length - 1];
    const allPeriods = financialYears.flatMap(
      (year) => year.periods?.map((p) => ({ ...p, financialYear: year })) ?? []
    );
    const currentInterval = {
      start: parseISO(current.start),
      end: parseISO(current.end),
    };

    const minStartDate = financialYears[0].start;
    const maxEndDate = lastFinancialYear.end;

    return eachOfInterval({
      start: parseISO(financialYears[0].start),
      end: parseISO(lastFinancialYear.end),
    })
      .map((start) => ({ start, end: endOf(start) }))
      .filter((interval) =>
        areIntervalsOverlapping(interval, currentInterval, { inclusive: true })
      )
      .map(({ start: startDate, end: endDate }) => {
        const start = format(startDate, 'yyyy-MM-dd');
        const end = format(endDate, 'yyyy-MM-dd');
        return {
          type,
          clientId,
          start: start < minStartDate ? minStartDate : start,
          end: end > maxEndDate ? maxEndDate : end,
          periods: allPeriods.filter(
            (p) => p.start >= start && p.end <= end && p.type === 'month'
          ),
          financialYears: financialYears.filter(
            (y) => y.end >= start && y.start <= end
          ),
        };
      });
  }
  return [];
};

/**
 * Returns the quarter period that intersect with the
 * current financial year
 */
export const getQuarters = (
  clientId: string,
  financialYears: FinancialYears,
  current: ClientFinancialYears[number]
) =>
  getIntervals(
    'quarter',
    clientId,
    financialYears,
    current,
    eachQuarterOfInterval,
    endOfQuarter
  );

/**
 * Returns the month periods that intersect with the
 * current financial year
 */
export const getMonths = (
  clientId: string,
  financialYears: FinancialYears,
  current: ClientFinancialYears[number]
) =>
  getIntervals(
    'month',
    clientId,
    financialYears,
    current,
    eachMonthOfInterval,
    endOfMonth
  );

/**
 * Returns the corresponding time period for a period type.
 */
export const periodTypeToRequestPeriod = (
  periodType: ReconciliationPeriod['type']
): 'month' | 'quarter' | 'financialYear' => {
  switch (periodType) {
    case 'month':
    case 'dead':
      return 'month';
    case 'quarter':
      return 'quarter';
    case 'financialYear':
    case 'yearEnd':
      return 'financialYear';

    default:
      return 'month';
  }
};

/**
 * Redux selector to pick the user input for a period and account
 *
 * @param period
 * @param accountNumber
 * @returns
 */
export const selectUserInputByPeriodAndAccount =
  (period: ReconciliationPeriod, accountNumber?: string) =>
  (state: RootState) => {
    const lastFinancialYear = last(period.financialYears);
    if (!lastFinancialYear) {
      return undefined;
    }
    const financialYear = TimePeriod.fromISODates(
      lastFinancialYear.start,
      lastFinancialYear.end
    ).value;
    const lastPeriod = last(period.periods);
    if (!lastPeriod) {
      return undefined;
    }

    return state.accountingView.clients[period.clientId]?.years[financialYear]
      ?.userInput?.[`account${accountNumber}`]?.[
      reformatDate(lastPeriod.start, 'yyyy-MM-dd', 'yyyy-MM')
    ];
  };

export const formatDate = (dateString: string): string => {
  return `${dateString.substring(0, 4)}-${dateString.substring(
    4,
    6
  )}-${dateString.substring(6, 8)}`;
};

export const isPercentageRow = (id: string) =>
  id === 'solidity' ||
  id === 'grossProfitMargin' ||
  id === 'refiPercent' ||
  id === 'refiPercentAgain';

export const formatValue = (value: number, isPercentage = false) =>
  isPercentage ? `${ccyFormat(100 * value, 1)}%` : ccyFormat(value);

export const getAccountStateColor = (state: ReconciliationState) => {
  switch (state) {
    case 'not_started':
      return theme.palette.grey[100];
    case 'started':
      return theme.palette.accountingView.cell.started;
    case 'error':
      return theme.palette.accountingView.cell.warning;
    case 'checked':
    case 'done':
      return theme.palette.accountingView.cell.ok;
    default:
      return theme.palette.grey[200];
  }
};

export const getAccountColor = (
  visited: boolean,
  correct: boolean | null
): string => {
  if (correct === false) {
    return theme.palette.accountingView.cell.warning;
  }

  if (visited) {
    if (correct === null) {
      return theme.palette.accountingView.cell.started;
    }
  }

  if (correct) {
    return theme.palette.accountingView.cell.ok;
  }

  return theme.palette.grey[100];
};

export const DocumentReferenceTypes = {
  account: 'A',
  group: 'G',
};

type AccountCollectedProps = {
  isOver: boolean;
  canDrop: boolean;
  isDragging?: boolean;
  item?: {
    accountNumber: string;
    groupId: string;
    periodStart: string;
  };
};

type AccountDragObject = {
  accountNumber: string;
  groupId: string;
  periodStart: string;
  isHeader?: boolean;
};

const collect = (monitor: DropTargetMonitor): AccountCollectedProps => {
  return {
    isOver: !!monitor.isOver(),
    canDrop: !!monitor.canDrop(),
    item: monitor.getItem(),
  };
};

export const useAccountDrop = (targetGroupId: string) => {
  const { moveRow } = useContext(MoveAccountsContext);
  const { setDragOverGroup } = useContext(DndContext);

  return useDrop<AccountDragObject, void, AccountCollectedProps>(
    {
      accept: DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW,
      canDrop: (item) => {
        return item.groupId !== targetGroupId;
      },
      drop: (item) => {
        moveRow(
          item.accountNumber,
          item.periodStart,
          item.groupId,
          targetGroupId
        );
      },
      hover: (item) => {
        setDragOverGroup(
          item.groupId !== targetGroupId
            ? {
                groupId: targetGroupId,
                periodStart: item.periodStart,
                isHeader: item.isHeader,
              }
            : null
        );
      },
      collect,
    },
    [targetGroupId, moveRow]
  );
};

export const useAccountDrag = (
  accountNumber: string,
  groupId: string,
  canDrag: boolean,
  periodStart: string,
  isHeader?: boolean
) => {
  const { setDragOverGroup } = useContext(DndContext);

  return useDrag<AccountDragObject, void, AccountCollectedProps>(
    {
      type: DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW,
      item: {
        accountNumber,
        groupId,
        periodStart,
        isHeader,
      },
      canDrag: () => {
        return canDrag;
      },
      collect: (monitor: DragSourceMonitor) => ({
        canDrop: false,
        isOver: false,
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        setDragOverGroup(null);
      },
    },
    [accountNumber, groupId, canDrag, periodStart]
  );
};

export const getOriginalGroup = (
  accountNumber: string,
  companyType: string
) => {
  return getHiddenRowGroups(companyType).find((group) => {
    const [start, end] = group.split('_').map((item) => parseInt(item, 10));
    const number = parseInt(accountNumber, 10);
    return number >= start && end >= number;
  });
};
