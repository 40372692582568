import React from 'react';
import styled from '@emotion/styled';
import { Typography, IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { formatDate } from '@agoy/common';

import { ReconciliationPeriod } from '../../types';
import { WIDTHS } from './PeriodColumn';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled(Row)`
  height: 45px;
  padding-top: 2px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: white;
`;

const ExpandedContainer = styled(Row)<{ size: string }>`
  align-items: center;
  width: calc(100% - ${({ size }) => WIDTHS[size] - 32}px);
  justify-content: center;
  padding-bottom: 2px;
`;

const HorizontalLine = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.text.primary};
  flex: 1;
  margin-right: -3px;
`;

const ExpandIconButton = styled(IconButton)`
  margin-left: 8px;
`;

const StyledAddCircle = styled(AddCircle)`
  font-size: 29px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const PeriodLabel = styled(Typography)`
  padding-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  border-radius: 12px;

  > div {
    width: 13px;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.text.primary};
  }
`;

type ExpandRowProps = {
  size: string;
  period: ReconciliationPeriod;
  expanded: boolean;
  onToggleExpand: (period: ReconciliationPeriod) => void;
};

const MinusIcon = () => (
  <Icon>
    <div />
  </Icon>
);

const ExpandRow = ({
  size,
  period,
  expanded,
  onToggleExpand,
}: ExpandRowProps) => {
  const handleExpandColumn = () => {
    onToggleExpand(period);
  };

  return (
    <Container>
      {period.type !== 'month' &&
        period.type !== 'yearEnd' &&
        (expanded ? (
          <ExpandedContainer size={size}>
            <IconButton size="small" onClick={handleExpandColumn}>
              <MinusIcon />
            </IconButton>
            <PeriodLabel>
              {formatDate(new Date(period.start), 'QQQ')} /{' '}
              {formatDate(new Date(period.start), 'MMMM yyyy')} -{' '}
              {formatDate(new Date(period.end), 'MMMM yyyy')}
            </PeriodLabel>

            <HorizontalLine />

            <IconButton size="small" onClick={handleExpandColumn}>
              <MinusIcon />
            </IconButton>
          </ExpandedContainer>
        ) : (
          <ExpandIconButton size="small" onClick={handleExpandColumn}>
            <StyledAddCircle color="primary" />
          </ExpandIconButton>
        ))}
    </Container>
  );
};

export default ExpandRow;
