import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useIntl } from 'react-intl';

import DeleteRowButtonBase from '_tax/components/Taxes/DeleteRowButton';
import ImageDropArea from '_shared/components/UploadDocument/ImageDropArea';
import { PreviewModal as PreviewDocumentModal } from '_shared/components/UploadDocument';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { green } from '@agoy/theme/src/colors-fortnox';
import Button from '../Buttons/Button';

const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;

const TopHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dde1e0;
  padding-bottom: 15px;
  padding-top: 15px;
  h3 {
    font-weight: 700;
    font-size: 16px;
    color: ${green.DARK};
    margin: 0;
  }
`;

const AddButtonIcon = styled(AddCircleOutlineIcon)`
  color: ${green.HIGH};
  padding: 0;
`;

const DocumentListContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dde1e0;
  cursor: pointer;
  padding-bottom: 15px;
  padding-top: 15px;
`;

const DocumentPreview = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
`;

const DocumentPreviewIcon = styled(ReceiptIcon)`
  color: ${green.HIGH};
  padding: 0;
`;

const DocumentInput = styled.input`
  display: none;
`;

const LoadingWrapper = styled.div`
  margin: 70px auto;
`;

const DeleteRowButton = styled(DeleteRowButtonBase)`
  border: none;
  margin-left: 0;
`;

export type Document = {
  id: number;
  name: string;
  url: string;
};

interface DocumentsProps {
  isEdit: boolean;
  documents: Document[];
  handleDeleteDocument: (id: number) => void;
  handleAddDocument: (file: File) => Promise<void>;
}

const DocumentsList = ({
  isEdit,
  documents,
  handleAddDocument,
  handleDeleteDocument,
}: DocumentsProps) => {
  const { formatMessage } = useIntl();

  const inputField = useRef<HTMLInputElement>(null);
  const [fileInput, setFileInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [previewDocument, setPreviewDocument] = useState<Document | null>(null);

  const handleOpenPreview = (id: number) => {
    const document = documents.find((item) => item.id === id);

    if (document) {
      setPreviewDocument(document);
    }
  };

  const handleClosePreview = () => {
    setPreviewDocument(null);
  };

  const handleSelectDocument = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (!files) {
      return;
    }

    setLoading(true);

    const uploaded = Object.keys(files).map(async (key) => {
      const file = files[key];
      if (file) {
        await handleAddDocument(file);
        setFileInput('');
      }
    });

    await Promise.all(uploaded);

    setLoading(false);
  };

  const handledDocumentUpload = () => {
    inputField.current?.click?.();
  };

  return (
    <Container>
      {previewDocument && (
        <PreviewDocumentModal
          documentSrc={previewDocument.url}
          documentId={`${previewDocument.id}`}
          documentName={previewDocument.name}
          onClose={handleClosePreview}
          refreshDownloadUrl={async () => {}}
          isShowDownload={false}
          isOpen
        />
      )}
      <ImageDropArea onDrop={handleAddDocument}>
        {loading ? (
          <LoadingWrapper>
            <LoadingPlaceholder />
          </LoadingWrapper>
        ) : (
          <>
            <TopHeaderContent>
              <Typography variant="h3">
                {formatMessage({
                  id: 'base',
                })}
              </Typography>
              {isEdit && (
                <>
                  <Button
                    label={formatMessage({
                      id: 'voucher.add.document.title',
                    })}
                    startIcon={<AddButtonIcon />}
                    onClick={handledDocumentUpload}
                    variant="text"
                    size="medium"
                  />
                  <DocumentInput
                    className="fileInput"
                    multiple
                    ref={inputField}
                    type="file"
                    accept="jpg, jpeg, png, gif, tiff, pdf"
                    onChange={handleSelectDocument}
                    value={fileInput}
                  />
                </>
              )}
            </TopHeaderContent>
            {documents.map((item) => (
              <DocumentListContent key={item.name}>
                <DocumentPreview onClick={() => handleOpenPreview(item.id)}>
                  <DocumentPreviewIcon />
                  {item.name}
                </DocumentPreview>
                {isEdit && (
                  <DeleteRowButton
                    onClick={async () => handleDeleteDocument(item.id)}
                  />
                )}
              </DocumentListContent>
            ))}
          </>
        )}
      </ImageDropArea>
    </Container>
  );
};

export default DocumentsList;
