import React, { useCallback, useContext, useMemo } from 'react';
import { isEqual } from 'lodash';

import { AgoyTable } from '@agoy/document';
import { ClientInformation } from '@agoy/api-sdk-core';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import ClientInformationContext from '_credit-safe/Context/ClientInformationContext';
import AnnualReportViewServiceContext from '_annual-report/service/AnnualReportViewServiceContext';
import { ClientInformationSource, SourceInfo } from './types';
import Source from './Source';
import { Container, SourceContainer } from '.';

type TableSourceInformationProps = React.PropsWithChildren<{
  table: AgoyTable;
  tableId: string;
  editing: boolean;

  /**
   * Maps the information in ClientInformation and the table
   * into a format used by TableSourceInformation. The `value`
   * and `clientInformationValue` are compared with a deep equal
   * to determine if there is a difference.
   */
  mapSourceInfo: (
    clientInformation: ClientInformation,
    table: AgoyTable
  ) => SourceInfo<Record<string, string | boolean>[]> | null;

  /**
   * Function called to render a difference between the table and clientinformation
   */
  renderDifference: (
    sourceInfo: SourceInfo<Record<string, string | boolean>[]>
  ) => React.ReactNode;

  /**
   * Method responsible for resetting the content of the table to
   * remove the difference.
   *
   * The source field in the table will be updated by the TableSourceInformation before
   * calling this callback.
   */
  onReset: (sourceInfo: SourceInfo<Record<string, string | boolean>[]>) => void;
}>;

const TableSourceInformation = ({
  children,
  tableId,
  table,
  editing,
  mapSourceInfo,
  onReset,
  renderDifference,
}: TableSourceInformationProps): React.ReactElement => {
  const viewService = useContext(AnnualReportViewServiceContext);
  const clientInformation = useContext(ClientInformationContext);

  const source = useMemo((): SourceInfo<
    Record<string, string | boolean>[]
  > | null => {
    if (!clientInformation) {
      return null;
    }

    return mapSourceInfo(clientInformation, table);
  }, [table, mapSourceInfo, clientInformation]);

  const onTableReset = useCallback(
    (
      id: string,
      clientInformationFieldId,
      clientInformationSource: ClientInformationSource,
      clientInformationValue: Record<string, string | boolean>[]
    ) => {
      if (clientInformationValue !== null) {
        viewService.updateTableSource(tableId, {
          type: clientInformationSource.source,
          updatedAt: new Date(clientInformationSource.timestamp).getTime(),
          userId: clientInformationSource.userId,
        });
      }
      if (source) {
        onReset(source);
      }
    },
    [viewService, onReset, source, tableId]
  );

  return (
    <Container>
      {children}
      <SourceContainer>
        {source ? (
          <Source<Record<string, string | boolean>[]>
            sourceInfo={source}
            key={source.id}
            onReset={onTableReset}
            editing={editing}
            isChanged={(sourceInfo) => {
              const formattedSourceInformation =
                tableId === 'signatures.section.people'
                  ? sourceInfo.clientInformationValue.map((item) => {
                      return {
                        accountantRole: item.accountantRole,
                        givenName: item.givenName,
                        role: item.role,
                        surName: item.surName,
                      };
                    })
                  : sourceInfo.clientInformationValue;
              return !isEqual(formattedSourceInformation, sourceInfo.value);
            }}
            renderDifference={renderDifference}
          />
        ) : null}
      </SourceContainer>
    </Container>
  );
};

export default TableSourceInformation;
