import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import {
  Route,
  Link,
  useRouteMatch,
  useHistory,
  Switch,
} from 'react-router-dom';
import { Typography, Paper as MuiPaper } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import styled from '@emotion/styled';

import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { NavForwardButton } from '_shared/components/Buttons';
import ClientHeaderTitle from '_shared/components/ClientView/ClientHeaderTitle';
import config from '_shared/services/config';
import { IconMenuProvider } from '_shared/components/IconMenu';
import CardTabPanel from '_organization/components/organisms/CardTabPanel';
import { CardTabs } from '_organization/components/organisms/CardTabs';
import IntegrationsComponent from '_clients/components/Integrations';
import { getClient, saveClientLogo } from '_clients/redux/customers/actions';
import { useLicenseStatus } from '_payment/licenses/useLicense';
import ClientProfilePage from 'assets/client_profile_page.jpeg';
import { ClientsRouteParams } from 'routes/types';
import ConnectionsToCompanyView from '_client-connections/components/ConnectionsToCompanyView';
import CreateConnectionToCompanyView from '_client-connections/components/CreateConnectionToCompanyView';
import ConnectionDetailsView from '_client-connections/components/ConnectionDetailsView';
import { UserClientImage } from 'utils/UserClientImage';
import {
  IMAGE_SIZE_LIMIT,
  IMAGE_MIN_WIDTH_LIMIT,
  IMAGE_MAX_WIDTH_LIMIT,
  IMAGE_MAX_HEIGHT_LIMIT,
} from 'contants';
import getImageResolution from 'utils/getImageResolution';
import { addChannelMessage, removeChannelMessage } from 'redux/actions';
import { useMessagesContext } from '_messages/contexts/Messages';
import useHeaderHeight from '_shared/hooks/useHeaderHeight';

import Button from '_shared/components/Buttons/Button';
import DocumentPage from './Documents';
import ActivityLogPage from './ActivityLog';
import CompanySharesView from './CompanySharesView';
import ClientInformationPage from './Information/ClientInformationPage';
import CompanyOwnersView from './CompanyOwnersView';
import { isFortnoxCompanyTenantSelector } from '../../_shared/redux/selectors';

const Paper = styled(MuiPaper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: none;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 2rem 2rem 0.5rem;
`;

const TextWrapper = styled.div`
  display: block;
`;

const LogoNameWrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const Layout = styled.div<{ headerHeight: number }>`
  background-color: ${(props) => props.theme.palette.secondary.contrastText};
  display: flex;
  height: calc(
    100vh - ${({ headerHeight }) => headerHeight}px
  ); // deduct header height
  width: 100vw;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const LogoClient = styled(UserClientImage)<{ editing: boolean }>`
  margin: 0;
  height: 40px;
  width: 40px;

  .logo {
    opacity: ${(props) => (props.editing ? '0.3' : '1')};
    font-size: 40px;
  }

  :hover {
    cursor: pointer;
    .logo {
      opacity: 30%;
    }
    .hoverCap {
      font-size: 8px;
      visibility: ${(props) => (props.editing ? 'none' : 'visible')};
      opacity: ${(props) => (props.editing ? '0' : '1')};
    }
  }

  .hoverCap {
    visibility: none;
    opacity: 0;
  }
`;

const StyledIllustration = styled.img`
  width: 10%;
`;

const StyledTabPanel = styled(CardTabPanel)`
  display: flex;
  ${(props) => (props.value === props.index ? 'flex: 1;' : '')}
  background-color: ${(props) => props.theme.palette.grey[100]};
`;

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(10)}px
    ${(props) => props.theme.spacing(5)}px;
`;

const StyledCardTabs = styled(CardTabs)`
  .MuiPaper-root {
    border: none;
  }
`;

const CancelLogoWrapper = styled.div`
  display: flex;
  margin-left: 0.5rem;
  gap: 0.8rem;
`;

const localRoutesTabIndex = {
  documents: 1,
  integrations: 2,
  'activity-log': 3,
  connections: 4,
  create: 4,
  owners: 5,
  shares: 6,
  'client-information': 7,
};

const MESSAGE_ID = 'uploadClientImageError';

const ClientView = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    path,
    params: { clientId, connectionId },
  } = useRouteMatch<ClientsRouteParams>();
  const { messageChannel } = useMessagesContext();
  const history = useHistory();
  const { license } = useLicenseStatus();
  const headerHeight = useHeaderHeight();

  const fetchingClient = useSelector((state) => state.ui.fetchingClients);
  const client = useSelector((state) =>
    clientId ? state.customers[clientId] : undefined
  );

  const isFortnoxCompanyTenant = useSelector(isFortnoxCompanyTenantSelector);

  const tabName = path.substring(path.lastIndexOf('/') + 1);
  const tabValue: number = connectionId ? 4 : localRoutesTabIndex[tabName] || 0;
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!client) {
      dispatch(getClient(clientId));
    }
  }, [client, clientId, dispatch]);

  if (fetchingClient) {
    return <LoadingPlaceholder />;
  }

  const uploadLogo = async (file: File) => {
    if (!client) return;
    // fetch the logo from S3 and transform into base64

    dispatch(saveClientLogo(client.id, file));
  };

  const showErrorMessage = (message) => {
    dispatch(addChannelMessage('error', messageChannel, MESSAGE_ID, message));
  };

  const handleLogoSelect = async (file: File) => {
    dispatch(removeChannelMessage(messageChannel, MESSAGE_ID));

    if (file) {
      if (file.size > IMAGE_SIZE_LIMIT) {
        showErrorMessage('error.imageSize');
        return;
      }

      try {
        const fileAsDataURL = URL.createObjectURL(file);
        const { width, height } = await getImageResolution(fileAsDataURL);

        if (width < IMAGE_MIN_WIDTH_LIMIT) {
          showErrorMessage('error.imageMinWidth');
          return;
        }

        if (width > IMAGE_MAX_WIDTH_LIMIT || height > IMAGE_MAX_HEIGHT_LIMIT) {
          showErrorMessage('error.imageMaxWidth');
          return;
        }

        await uploadLogo(file);
        setEditing(false);
      } catch (e) {
        showErrorMessage('error.imageonly');
      }
    }
  };

  if (!client) {
    return (
      <ErrorWrapper>
        <Typography variant="h5">
          {formatMessage({ id: 'info.page.notFound' })}
        </Typography>
      </ErrorWrapper>
    );
  }

  return (
    <Layout headerHeight={headerHeight}>
      <IconMenuProvider />
      <Wrapper>
        <MainWrapper>
          <TextWrapper>
            <LogoNameWrapper>
              <LogoClient
                imageUrl={client.logo ?? undefined}
                roundedSquare
                hoverCaption
                className="logo"
                editing={editing}
                handleEdit={() => setEditing(true)}
                handleChange={handleLogoSelect}
              />
              <CancelLogoWrapper>
                {editing && (
                  <Button
                    label={formatMessage({ id: 'cancel' })}
                    variant="text"
                    onClick={() => setEditing(false)}
                  />
                )}
                <ClientHeaderTitle
                  clientName={client?.name}
                  clientType="company"
                />
              </CancelLogoWrapper>
            </LogoNameWrapper>

            <Typography variant="subtitle2">
              {config.whiteLabelUI === 'fortnox'
                ? formatMessage({ id: 'info.client.page.fortnox' })
                : formatMessage({ id: 'info.client.page' })}
              {client?.latestFinancialYear && (
                <>
                  <NavForwardButton
                    onClick={() => {
                      history.push(
                        `/clients/${clientId}/reconciliation/${client?.latestFinancialYear}`
                      );
                    }}
                    title={formatMessage({ id: 'details' })}
                  />
                  {license === 'big' && (
                    <NavForwardButton
                      onClick={() =>
                        history.push(
                          `/clients/${clientId}/${client?.latestFinancialYear}/tax`
                        )
                      }
                      title={formatMessage({ id: 'tax' })}
                    />
                  )}
                </>
              )}
            </Typography>
          </TextWrapper>
          <StyledIllustration
            src={ClientProfilePage}
            alt="Client Profile Page"
          />
        </MainWrapper>

        <Paper variant="elevation" elevation={0}>
          <StyledCardTabs
            value={tabValue}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              label="Klientinformation"
              component={Link}
              to={`/clients/${clientId}/`}
            />

            <Tab
              label={formatMessage({ id: 'documents' })}
              component={Link}
              to={`/clients/${clientId}/documents`}
            />

            <Tab
              label={formatMessage({ id: 'customerinfo.integrations' })}
              component={Link}
              to={`/clients/${clientId}/integrations`}
            />

            <Tab
              label={formatMessage({ id: 'activitylog.title' })}
              component={Link}
              to={`/clients/${clientId}/activity-log`}
            />
            {!isFortnoxCompanyTenant && (
              <Tab
                label={formatMessage({ id: 'connections.title' })}
                component={Link}
                to={`/clients/${clientId}/connections`}
              />
            )}
            {!isFortnoxCompanyTenant && (
              <Tab
                label={formatMessage({ id: 'companyOwners.title' })}
                component={Link}
                to={`/clients/${clientId}/owners`}
              />
            )}
            {!isFortnoxCompanyTenant && (
              <Tab
                label={formatMessage({ id: 'companyShares.title' })}
                component={Link}
                to={`/clients/${clientId}/shares`}
              />
            )}
          </StyledCardTabs>

          <StyledTabPanel name="info" value={tabValue} index={0}>
            <Route path={`/clients/${clientId}/`} exact>
              <ClientInformationPage clientId={clientId} />
            </Route>
          </StyledTabPanel>

          <StyledTabPanel name="documents" value={tabValue} index={1}>
            <Route path={`/clients/${clientId}/documents`} exact>
              <DocumentPage clientId={clientId} open />
            </Route>
          </StyledTabPanel>

          <StyledTabPanel name="integrations" value={tabValue} index={2}>
            <Route path={`/clients/${clientId}/integrations`} exact>
              <IntegrationsComponent clientId={clientId || ''} />
            </Route>
          </StyledTabPanel>

          <StyledTabPanel name="activity-log" value={tabValue} index={3}>
            <Route path={`/clients/${clientId}/activity-log`} exact>
              <ActivityLogPage clientId={clientId || ''} />
            </Route>
          </StyledTabPanel>

          <StyledTabPanel name="connections" value={tabValue} index={4}>
            <Switch>
              <Route path={`/clients/${clientId}/connections`} exact>
                <ConnectionsToCompanyView client={client} path="clients" />
              </Route>
              <Route path={`/clients/${clientId}/connections/create`} exact>
                <CreateConnectionToCompanyView client={client} path="clients" />
              </Route>
              <Route
                path={`/clients/${clientId}/connections/${connectionId}`}
                exact
              >
                <ConnectionDetailsView
                  path="clients"
                  client={client}
                  connectionId={Number.parseInt(connectionId, 10)}
                />
              </Route>
            </Switch>
          </StyledTabPanel>

          <StyledTabPanel name="owners" value={tabValue} index={5}>
            <Route path={`/clients/${clientId}/owners`} exact>
              <CompanyOwnersView client={client} />
            </Route>
          </StyledTabPanel>

          <StyledTabPanel name="shares" value={tabValue} index={6}>
            <Route path={`/clients/${clientId}/shares`} exact>
              <CompanySharesView client={client} />
            </Route>
          </StyledTabPanel>
        </Paper>
      </Wrapper>
    </Layout>
  );
};

export default ClientView;
