import {
  ClientInformation,
  ClientInformationAuditFirm,
  ClientInformationDirector,
  ClientInformationField,
  ClientInformationLight,
  ClientInformationUpdate,
} from '@agoy/api-sdk-core';
import { RegisterOptions } from 'react-hook-form';
import { isValidOrgNumber, isDefined } from '@agoy/common';
import { format, parseISO } from 'date-fns';
import { sv } from 'date-fns/locale';
import { isEqual } from 'lodash';
import { CIUpdateField, defaultClientInformation } from './types';

export type ValidationTypes = {
  [key: string]: RegisterOptions;
};
const personalNumberValidation = {
  validate: (number: string) =>
    isValidOrgNumber(number) || number.endsWith('0000'),
  minLength: 10,
  pattern: /^(16|19|20|)?(\d{6})[-–+]?(\d{4})$/g,
  required: true,
};
export const validation: ValidationTypes = {
  orgNumber: {
    validate: (number) => isValidOrgNumber(number),
    minLength: 10,
    pattern: /^(16|19|20|)?(\d{6})[-–]?(\d{4})$/g,
    required: true,
  },
  personalNumber: personalNumberValidation,
  personNr: personalNumberValidation,
  email: { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ },
  name: { required: true },
  lastName: { required: true },
  firstName: { required: true },
  companyName: { required: true },
  closingPeriod: { required: true },
  closingMonth: { required: true },
  vatPeriod: { required: true },
  role: { required: true },
  phoneNumber: {
    pattern: /^[\d\s+\-()]*$/,
    required: false,
  },
};

// Hack to fix some issue with date-fns
export const getDate = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString).toLocaleString('sv-SE', {
      timeZone: 'CET',
    });

    return new Date(parseISO(date));
  }

  return new Date();
};

/**
 * Returns the source of the field to be then displayed with calcSource.
 * @param object the CR or director with the properties {value, source, timestamp, userId}
 * @param key the key of the field that was updated (e.g. name, address, orgNumber, etc.)
 * @returns the source from the given field
 */
export const returnSource = (
  object: ClientInformation | undefined,
  key: string
): ClientInformationField['source'] | undefined => {
  return object?.[key]?.source;
};

/**
 * Returns the source (user or credit-safe) of the latest update.
 * @param source 'user' or 'cs'
 * @returns a string to display if the input was manually changed or fetched from CS
 */
export const calcSource = (source: 'user' | 'cs' | 'fortnox'): string => {
  switch (source) {
    case 'user':
      return 'Manuellt ifylld';
    case 'cs':
      return 'Hämtat från CS';
    case 'fortnox':
      return 'Hämtat från Fortnox';
    default:
      return 'Från okänd källa';
  }
};

/**
 * Returns the timestamp of the field to be then displayed with calcTimestamp.
 * @param object the CR or director with the properties {value, source, timestamp, userId}
 * @param key the key of the field that was updated (e.g. name, address, orgNumber, etc.)
 * @param index (optional) the position of the director or any array of items
 * @returns the timestamp created in the DB for the given field's last update
 */
export const returnTimestamp = (
  object: ClientInformation | undefined,
  key: string
): string | number | undefined => {
  return object?.[key]?.timestamp;
};

/**
 * Returns a timestamp of the latest update.
 * @param timestamp a string or a number
 * @returns a timestamp formatted with date-fns 'dd-MM-yyyy — HH:mm'
 */
export const calcTimestamp = (timestamp): string => {
  return format(getDate(timestamp), 'yyyy-MM-dd — HH:mm', {
    locale: sv,
  });
};

/**
 * Returns a timestamp of the latest update.
 * @param timestamp a string or a number
 * @returns a timestamp formatted with date-fns 'd MMMM yyyy, HH:mm'
 */
export const formatTimestamp = (timestamp): string | number => {
  return format(getDate(timestamp), 'd MMMM yyyy, HH:mm', {
    locale: sv,
  });
};

/**
 * Returns the user's fullname for the field's user property using the userId.
 * @param object the CR or director with the properties {value, source, timestamp, userId}
 * @param key the key of the field that was updated (e.g. name, address, orgNumber, etc.)
 * @param index (optional) the position of the director or any array of items
 * @returns the user's fullname based on the given field's userId
 */
export const returnUser = (
  object: ClientInformation | undefined,
  orgMembers: Member.MemberType[],
  key: string
): string | undefined => {
  const userId = object?.[key]?.userId;
  if (!userId) {
    return undefined;
  }

  const memberMatching = orgMembers.find((m) => m.userId === userId);

  return memberMatching
    ? `${memberMatching.givenName} ${memberMatching.familyName}`
    : undefined;
};

export const createPerson = (personRole: string): ClientInformationDirector => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    personNr: '',
    role: personRole,
    appointedDate: format(new Date(), 'yyyy-MM-dd'),
  };
};

export const createAuditFirm = (
  personRole: string
): ClientInformationAuditFirm => {
  return {
    role: personRole,
    appointedDate: format(new Date(), 'yyyy-MM-dd'),
    companyName: '',
    orgNumber: '',
  };
};

const createUpdateForRoles =
  (fieldName: 'directors' | 'auditors' | 'otherFunctions') =>
  (
    data: ClientInformationLight,
    current: ClientInformation
  ): CIUpdateField<'directors', ClientInformationDirector[]> | undefined => {
    if (
      data[fieldName] !== undefined &&
      !isEqual(current[fieldName]?.value, data[fieldName])
    ) {
      return {
        key: fieldName,
        type: 'directors',
        newValue: data[fieldName] || [],
      };
    }
    return undefined;
  };

const createUpdateForDirectors = createUpdateForRoles('directors');
const createUpdateForAuditors = createUpdateForRoles('auditors');
const createUpdateForOtherFunctions = createUpdateForRoles('otherFunctions');

const createUpdateForDirector =
  (key: 'ceo') =>
  (
    value: ClientInformationLight,
    current: ClientInformation
  ): CIUpdateField<'directors', ClientInformationDirector[]> | undefined => {
    if (!isEqual(current[key]?.value ?? [], value[key] ?? [])) {
      return {
        key,
        type: 'directors',
        newValue: value[key] ?? [],
      };
    }
    return undefined;
  };

const createUpdateForAuditFirm =
  (key: 'auditFirm') =>
  (
    value: ClientInformationLight,
    current: ClientInformation
  ): CIUpdateField<'auditFirm', ClientInformationAuditFirm[]> | undefined => {
    if (!isEqual(current[key]?.value ?? [], value[key] ?? [])) {
      return {
        key,
        type: 'auditFirm',
        newValue: value[key] ?? [],
      };
    }
    return undefined;
  };

const createUpdateForClosingMonth = (
  data: ClientInformationLight,
  current: ClientInformation
): CIUpdateField<'number', number | null> | undefined => {
  const value = data.closingMonth;
  if (value !== undefined && value !== current.closingMonth?.value) {
    return {
      key: 'closingMonth',
      type: 'number',
      newValue: value,
    };
  }
  return undefined;
};

/**
 * Names of all field that are represented by string, including enum values.
 */
type StringFields = Exclude<
  {
    [K in keyof ClientInformationLight]: Required<ClientInformationLight>[K] extends
      | string
      | null
      ? K
      : never;
  }[keyof ClientInformationLight],
  undefined
>;

const createUpdateForString =
  (field: StringFields, emptyToNull = false) =>
  (
    data: ClientInformationLight,
    current: ClientInformation
  ): CIUpdateField | undefined => {
    const value = data[field];
    if (value === undefined) {
      return undefined;
    }
    if (value === null || typeof value === 'string') {
      const newValue: string | null = emptyToNull && !value ? null : value;
      if (newValue !== current[field]?.value) {
        return {
          key: field,
          type: 'string',
          newValue,
        };
      }
    }

    return undefined;
  };

/**
 * Names of all field that are represented by booleans
 */
type BooleanFields = Exclude<
  {
    [K in keyof ClientInformationLight]: Required<ClientInformationLight>[K] extends
      | boolean
      | null
      ? K
      : never;
  }[keyof ClientInformationLight],
  undefined
>;

const createUpdateForBoolean =
  (field: BooleanFields) =>
  (
    data: ClientInformationLight,
    current: ClientInformation
  ): CIUpdateField<'boolean', boolean | null> | undefined => {
    const value = data[field];
    if (value === undefined) {
      return undefined;
    }
    if (value === null || typeof value === 'boolean') {
      if (value !== current[field]?.value) {
        return {
          key: field,
          type: 'boolean',
          newValue: value,
        };
      }
    }

    return undefined;
  };

/**
 * Mapping for how we create updates for each field.
 *
 * The typing of this mapping force us to add a specific mapping
 * for each field that exists. No fallbacks.
 */
export const createUpdateMapping: {
  [K in keyof ClientInformation & keyof ClientInformationLight]: (
    value: ClientInformationLight,
    current: ClientInformation
  ) =>
    | CIUpdateField<'string', string | null>
    | CIUpdateField<'number', number | null>
    | CIUpdateField<'boolean', boolean | null>
    | CIUpdateField<'directors', ClientInformationDirector[]>
    | CIUpdateField<'auditFirm', ClientInformationAuditFirm[]>
    | undefined;
} = {
  directors: createUpdateForDirectors,
  auditors: createUpdateForAuditors,
  otherFunctions: createUpdateForOtherFunctions,
  auditFirm: createUpdateForAuditFirm('auditFirm'),
  ceo: createUpdateForDirector('ceo'),
  closingMonth: createUpdateForClosingMonth,

  name: createUpdateForString('name'),
  address: createUpdateForString('address'),
  zipCode: createUpdateForString('zipCode'),
  city: createUpdateForString('city'),
  orgNumber: createUpdateForString('orgNumber'),
  type: createUpdateForString('type'),
  email: createUpdateForString('email'),
  activityText: createUpdateForString('activityText'),

  sni: createUpdateForString('sni'),
  contactPerson: createUpdateForString('contactPerson'),
  phoneNumber: createUpdateForString('phoneNumber'),
  clientManager: createUpdateForString('clientManager', true),
  managerRole2: createUpdateForString('managerRole2'),
  managerRole3: createUpdateForString('managerRole3'),
  closingPeriod: createUpdateForString('closingPeriod'),
  vatPeriod: createUpdateForString('vatPeriod'),
  vatRegistration: createUpdateForBoolean('vatRegistration'),
  vatRegistrationDate: createUpdateForString('vatRegistrationDate'),
  startDateAgoy: createUpdateForString('startDateAgoy', true),
  numberOfEmployees: createUpdateForString('numberOfEmployees'),
  incorporationDate: createUpdateForString('incorporationDate'),
  registeredEmployer: createUpdateForBoolean('registeredEmployer'),
};

const isValidFieldName = (
  fieldName: string
): fieldName is keyof ClientInformation & keyof ClientInformationLight => {
  return createUpdateMapping[fieldName] !== undefined;
};

/** This function returns the fields where the value has been updated and format them with a better
 * structure for the BE (key, newValue and optional personalNr for directors).
 * The useForm library returns the entire state by default, which updates the
 * timestamp for all the fields, hence the need for this function that only sends to our endpoint
 * the fields where the value has been changed.
 * @param data to be updated in a [key: string, value: string] format;
 * @param currentClientInformation the current ClientInformation from the context;
 * @returns formatted data in a key, newValue format;
 */
export const calcTouchedFields = (
  data: ClientInformationLight,
  currentClientInformation: ClientInformation
): ClientInformationUpdate => {
  /**
   *  Need to be updated according (wrong calculations of directors and ceo because of personalNumber transformations);
   */
  const changedValues: (
    | CIUpdateField<'string', string | null>
    | CIUpdateField<'number', number | null>
    | CIUpdateField<'boolean', boolean | null>
    | CIUpdateField<'directors', ClientInformationDirector[]>
    | CIUpdateField<'auditFirm', ClientInformationAuditFirm[]>
  )[] = Object.keys(data)
    .filter(isValidFieldName)
    .map((field) => {
      if (
        currentClientInformation[field] === undefined &&
        isEqual(defaultClientInformation[field], data[field])
      ) {
        // No current value, and the value is same the default. No change.
        return undefined;
      }
      return createUpdateMapping[field]?.(data, currentClientInformation);
    })
    .filter(isDefined);
  return {
    type: 'partial',
    updates: changedValues,
  };
};

export const translateCompanyType = (
  type: string | undefined,
  formatMessage: (string) => string
): string | undefined => {
  if (!type) return undefined;
  if (type && type.includes('_')) {
    return formatMessage({ id: `company.${type.split('_').join('.')}` });
  }
  return formatMessage({ id: `company.${type}` });
};

const contentFields = {
  generalInfo: [
    'name',
    'orgNumber',
    'sni',
    'type',
    'activityText',
    'numberOfEmployees',
    'vatRegistration',
    'vatRegistrationDate',
    'incorporationDate',
    'registeredEmployer',
  ],
  boardInfo: ['ceo', 'directors', 'auditFirm', 'auditors', 'otherFunctions'],
  contactInfo: [
    'contactPerson',
    'phoneNumber',
    'email',
    'address',
    'zipCode',
    'city',
  ],
  organisation: [
    'clientManager',
    'managerRole2',
    'managerRole3',
    'closingPeriod',
    'vatPeriod',
    'closingMonth',
    'startDateAgoy',
  ],
};

export const getContent = (
  dataType: keyof typeof contentFields,
  clientInformation: ClientInformation | undefined
) =>
  clientInformation
    ? contentFields[dataType].reduce(
        (data, field) => ({ ...data, [field]: clientInformation[field] }),
        {}
      )
    : {};

/**
 * Normalizes input by trimming leading and trailing spaces and replacing multiple spaces with a single space.
 * @param input The string to be normalized.
 * @returns The normalized string.
 */
export const normalizeString = (str: string): string => {
  return str.trim().replace(/\s+/g, ' ');
};
