import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Paper, Typography } from '@material-ui/core';
import { Comment as CommentIcon } from '@material-ui/icons';
import { Comment } from '_shared/types';
import { SimplifiedTextfield } from '../Inputs';
import CommentsList from './CommentsList';
import { CommentsSubsection } from './CommentsSubsectionList';
import Button from '../Buttons/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  width: 100%;
`;

const InputContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  gap: ${({ theme }) => theme.spacing(1.5)}px;
  z-index: 1;
`;

const StyledIcon = styled.div`
  width: fit-content;
  color: ${({ theme }) => theme.palette.grey['500']};
`;

const CommentTextfield = styled(SimplifiedTextfield)`
  flex: 1;

  .MuiFormControl-root {
    display: flex;
    flex: 1;
  }
`;

/**
 * Renders Comment input with Save button
 *
 * @param onSave Save comment callback
 * @param loading Comment section performs a network request
 * @param disabled disabled comment input, default = false (optional)
 * @returns A comment input
 */
const CommentInput = ({
  onSave,
  loading,
  multiline,
  disabled = false,
}: {
  onSave: (comment: string) => void;
  loading: boolean;
  multiline?: boolean;
  disabled?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [comment, setComment] = useState('');

  return (
    <InputContainer>
      <StyledIcon>
        <CommentIcon color="inherit" fontSize="medium" />
      </StyledIcon>
      <CommentTextfield
        multiline={multiline}
        maxRows={multiline ? 4 : undefined}
        editing
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        disabled={disabled}
      />
      <Button
        label={formatMessage({ id: 'save' })}
        onClick={() => {
          onSave(comment);
          setComment('');
        }}
        disabled={!comment}
        loading={loading}
      />
    </InputContainer>
  );
};

interface Props {
  comments: Comment[];
  addComment: (comment: string) => void;
  loading: boolean;
  savingNewComment: boolean;
  title?: string;
  preview?: CommentsSubsection;
  subsections?: CommentsSubsection[];
  multiline?: boolean;
  disabled?: boolean;
  /**
   * For future pagination:
   *
   * defaultText?: string;
   * pageSize?: number;
   * displaySize?: number;
   */

  displayName?: boolean;
  displayCreatedAt?: boolean;
  showPreview?: boolean;
}

/**
 * Renders Comment section with input and list of comments.
 * This component is representational.
 * For fetching and handling comments use {@link web/src/_shared/hooks/useComments.ts | useComments} hook
 *
 * @param comments Comments list
 * @param addComment Add comemnt callback
 * @param loading Comment section performs a network request
 * @param savingNewComment Comment section performs saving of a new comment added through inout field
 * @param title Section title
 * @param preview comments to display as preview, includes title and comments (optional)
 * @param showPreview flag to toggle preview visibility, default = false (optional)
 * @param subsections subsections of comments, includes  title, comments and additional info (optional)
 * @param disabled disabled comment input, default = false (optional)
 * @param displayName display or hide author name, default = true (optional)
 * @param displayCreatedAt display or hide date of creation, default = true (optional)
 * @param multiline make comment inout multiline, , default = false (optional)
 * @returns A comment section with input and comment list
 */
const CommentsSection = ({
  comments,
  addComment,
  loading,
  savingNewComment,
  title,
  preview,
  subsections,
  displayName = true,
  displayCreatedAt = true,
  showPreview = false,
  multiline = false,
  disabled = false,
}: Props) => (
  <Container>
    {title && (
      <Typography variant="h4" color="textSecondary">
        {title}
      </Typography>
    )}
    <CommentInput
      onSave={addComment}
      loading={savingNewComment}
      multiline={multiline}
      disabled={disabled}
    />
    <CommentsList
      displayName={displayName}
      displayCreatedAt={displayCreatedAt}
      comments={comments}
      loading={loading}
      preview={preview}
      showPreview={showPreview}
      subsections={subsections}
    />
  </Container>
);

export default CommentsSection;
