import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography as MuiTypography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { normalizePersonalNumber } from '@agoy/common';
import { GenericModal } from '_shared/components/Modal';
import { ClientInformation, CSDetailedCompany } from '@agoy/api-sdk-core';
import LoadingLogo from '_shared/components/LoadingLogo';
import { getClient } from 'redux/actions';

import Button from '_shared/components/Buttons/Button';
import { ClientInfoContext } from '../Context/CompanyData';
import CSModalRow from './CSModalRow';
import DirectorsRow from './DirectorsRow';

const Container = styled.div`
  height: 100%;
  width: 1000px;
  max-height: 600px;
  position: relative;
  display: block;
  padding: ${({ theme }) => theme.spacing(1.5, 3, 3, 3)};
`;

const ContainerGridRow = styled.div`
  display: flex;
  margin: 1rem 0;
  width: 930px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  height: fit-content;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  margin-right: ${({ theme }) => theme.spacing(7)}px;
`;

const GridElement = styled.div`
  display: flex;
  width: 30rem;

  :nth-of-type(1) {
    width: 26rem;
    margin-left: 2rem;
  }

  :nth-of-type(-n + 3) {
    margin-top: 1.5rem;
  }
`;

const UpdatedDataTextContainer = styled.div`
  display: block;
`;

const Typography = styled(MuiTypography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: bold;
`;

const SubTitle = styled(MuiTypography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-style: italic;
  text-align: center;
`;

const TooltipTitle = styled(MuiTypography)`
  padding: ${({ theme }) => theme.spacing(1, 0, 0, 1)};
`;

const TooltipText = styled(MuiTypography)`
  margin-bottom: 0.5rem;
  padding-right: 0.5rem;
`;

const WhiteInfoIcon = styled(InfoOutlinedIcon)`
  color: white;
`;

const LoadingLogoWrapper = styled.div`
  margin-top: 2rem;
`;

const FadingElement = styled.div`
  position: absolute;
  height: ${(props) => props.theme.spacing(5)}px;
  width: 100%;
  background: linear-gradient(0deg, white, transparent);
  left: 0;
  right: 0;
  bottom: 50px;
`;

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  height: 65px;
  width: 100%;
  background: white;
  border-bottom-left-radius: ${(props) => props.theme.shape.borderRadius}px;
  border-bottom-right-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

const Spacer = styled.div`
  margin: 1.5rem 0;
`;

/**
 * Modal for updating a document state
 */

type ModalRowProps = {
  companyData: CSDetailedCompany;
  cardModalFields: ClientInformation;
};

export const ModalRow = ({ companyData, cardModalFields }: ModalRowProps) => (
  <>
    {'directors' in cardModalFields ? (
      <DirectorsRow
        cardModalFields={cardModalFields}
        companyData={companyData}
      />
    ) : (
      <CSModalRow cardModalFields={cardModalFields} companyData={companyData} />
    )}
  </>
);

const ModalText = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <TooltipTitle>
        {formatMessage({ id: 'clientInformation.modal.description.1' })}
      </TooltipTitle>
      <ul>
        <li>
          <TooltipText>
            {formatMessage({ id: 'clientInformation.modal.description.2' })}
          </TooltipText>
        </li>
        <li>
          <TooltipText>
            {formatMessage({ id: 'clientInformation.modal.description.3' })}
          </TooltipText>
        </li>
        <li>
          <TooltipText>
            {formatMessage({ id: 'clientInformation.modal.description.4' })}
          </TooltipText>
        </li>
      </ul>
    </>
  );
};

/**
 * Modal for updating a document state
 */

type CreditSafeModalProps = {
  open: boolean;
  clientId: string;
  cardModalFields: ClientInformation;
  closeModal: () => void;
};

export const CreditSafeModal = ({
  open,
  clientId,
  cardModalFields,
  closeModal,
}: CreditSafeModalProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const {
    companyData,
    getCompanyData,
    getClientInformation,
    patchClientInformation,
  } = useContext(ClientInfoContext);
  const [formattedData, setFormattedDate] = useState({});
  const [loading, setLoading] = useState(false);

  const onClickHandler = useCallback(async () => {
    await getCompanyData(clientId);
    setLoading(false);
  }, [clientId, getCompanyData]);

  useEffect(() => {
    setLoading(true);
    onClickHandler();
  }, [onClickHandler]);

  useEffect(() => {
    const clientData = companyData[clientId];
    const filteredClientData = {};

    if (!clientData) {
      return;
    }

    // NOTE: separate clientData for each client card;
    Object.keys(clientData).forEach((key) => {
      if (
        key in cardModalFields &&
        cardModalFields[key]?.source !== 'fortnox'
      ) {
        filteredClientData[key] = clientData[key];
      }
    });

    // NOTE: we should rewrite it after creating all client information cards
    if ('directors' in clientData) {
      const { directors } = clientData;

      if (directors) {
        Object.keys(directors).forEach((item) => {
          if (directors[item] && directors[item].personNr?.length !== 12) {
            directors[item] = {
              ...directors[item],
              personNr: normalizePersonalNumber(directors[item].personNr),
            };
          }
        });
      }
    }

    setFormattedDate(
      Object.keys(filteredClientData).length ? filteredClientData : clientData
    );
  }, [clientId, companyData, cardModalFields]);

  const onSaveData = async () => {
    try {
      await patchClientInformation(
        clientId,
        { type: 'full', data: formattedData },
        'cs'
      );
      dispatch(getClient(clientId));
    } catch (e) {
      console.error(e);
    }

    closeModal();
  };

  return (
    <GenericModal
      open={open}
      handleClose={closeModal}
      title={formatMessage({
        id: 'clientInformation.modal.title',
      }).toUpperCase()}
      icon={<WhiteInfoIcon />}
      tooltipText={<ModalText />}
    >
      <Container>
        <ContainerGridRow>
          <GridElement />
          <GridElement>
            <Typography>
              {formatMessage({
                id: 'clientInformation.current.data',
              }).toUpperCase()}
            </Typography>
          </GridElement>
          <GridElement>
            <Typography>
              {formatMessage({
                id: 'clientInformation.creditSafe.data',
              }).toUpperCase()}
            </Typography>
          </GridElement>
          <GridElement>
            <UpdatedDataTextContainer>
              <SubTitle variant="subtitle1">
                {formatMessage({
                  id: 'clientInformation.preview.data',
                })}
              </SubTitle>
              <Typography>
                {formatMessage({
                  id: 'clientInformation.updated.data',
                }).toUpperCase()}
              </Typography>
            </UpdatedDataTextContainer>
          </GridElement>
        </ContainerGridRow>

        {loading ? (
          <LoadingLogoWrapper>
            <LoadingLogo size="medium" />
          </LoadingLogoWrapper>
        ) : (
          <ModalRow
            cardModalFields={cardModalFields}
            companyData={formattedData}
          />
        )}

        <Spacer />
        <Actions>
          {!loading && <FadingElement />}
          <ButtonContainer>
            <Button
              label={formatMessage({
                id: 'clientInformation.modal.update',
              })}
              onClick={onSaveData}
            />
          </ButtonContainer>
        </Actions>
      </Container>
    </GenericModal>
  );
};

export default CreditSafeModal;
