import { ThemeOptions } from '@material-ui/core/styles';
import { appHeaderHeight } from './measurements';
import {
  primaryMain,
  primaryLight,
  secondaryMain,
  info,
  error,
  warning,
  success,
  textPrimary,
  textSecondary,
  cellIncomingBalance,
  cellTotalChange,
  cellOutgoingBalance,
  cellOk,
  cellWarning,
  accountStatusWrong,
  currentMonthBackground,
  closingMonthBackground,
  white,
  gridBorderColor,
  dimensionBackgroundColor,
  popupHeader,
  headerBackgroundColor,
  darkGrey,
  grey,
  infoText,
  cellStarted,
  cellChecked,
  creditSafeColor,
  creditSafeInput,
  boardCard,
  contactInfoCard,
  organisationCard,
  DONE,
  NOT_STARTED,
  STARTED,
  AW_COMPL,
  AW_REVIEW,
  periodHeaderMonth,
  periodHeaderQuarter,
  periodHeaderYear,
  periodHeaderYearEnd,
  darkBlue,
  beige,
} from './colors';

// NOTE: theme.spacing(1) is 8px or 0.5rem

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Cabin', 'Roboto'].join(','),
    subtitle1: {
      fontSize: '0.8750rem',
    },
    h1: {
      fontSize: '2rem',
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
    },
    h2: {
      fontSize: '1.75rem',
      textAlign: 'left',
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
    },
    h3: {
      fontSize: '1.5rem',
      textAlign: 'left',
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
    },
    h4: {
      fontSize: '1.125rem',
      textAlign: 'left',
      marginTop: '1.25rem',
      marginBottom: '1.25rem',
    },
    h5: {
      fontSize: '1rem',
      textAlign: 'left',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    h6: {
      fontSize: '1rem',
      textAlign: 'left',
    },
  },
  zIndex: {
    appHeader: 100,
    appBar: 1202,
    drawer: 1000,
    leftMenu: 40,
    taxLegend: 100,
    speedDial: 2000,
    accountingView: {
      header: 5,
      rowHeader: 1,
      periodRow: 3,
      summaryRow: 12,
      summaryRowStickyCell: 10,
      speedDial: 20,
    },
  },
  palette: {
    type: 'light',
    background: {
      default: '#fafafa',
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
    },
    secondary: {
      main: secondaryMain,
      contrastText: white,
    },
    info: {
      main: info,
    },
    error: {
      main: error,
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    beta: {
      main: '#F8AF61',
    },
    creditSafe: {
      main: creditSafeColor,
      inputPreview: creditSafeInput,
    },
    clientCard: {
      contactInfoCard: contactInfoCard,
      organisationCard: organisationCard,
      boardCard: boardCard,
    },
    accountingView: {
      cell: {
        incomingBalance: cellIncomingBalance,
        totalChange: cellTotalChange,
        outgoingBalance: cellOutgoingBalance,
        ok: cellOk,
        warning: cellWarning,
        started: cellStarted,
        checked: cellChecked,
      },
      popup: {
        header: popupHeader,
      },
      headers: {
        main: primaryMain,
        mainLighter: primaryLight,
      },
      hiddenRow: {
        documentUpload: {
          background: cellOk,
        },
        infoText,
      },
      periodHeader: {
        financialYear: periodHeaderYear,
        quarter: periodHeaderQuarter,
        month: periodHeaderMonth,
        yearEnd: periodHeaderYearEnd,
        dead: periodHeaderYearEnd,
      },
    },
    overviewView: {
      accountStatusWrong,
      closingMonthBackground,
      currentMonthBackground,
    },
    taxDeclarationView: {
      background: '#F6F6F6',
      formType: {
        INK1: '#5180EA',
        INK2: '#F8AF61',
        INK3: '#52D5AC',
        INK4: '#5180EA',
        K10: '#8196C6',
        N3A: '#EA51EA',
        NE: '#671CCE',
      },
      formStatus: {
        DONE: DONE.main,
        NOT_STARTED: NOT_STARTED.main,
        STARTED: STARTED.main,
        AW_REVIEW: AW_REVIEW.main,
        AW_COMPL: AW_COMPL.main,
      },
    },
    transactionsanalysView: {
      bordercolor: gridBorderColor,
      backgroundcolor: dimensionBackgroundColor,
    },
    organisationView: {
      background: '#f6f6f6',
      infoText: '#eaefee',
    },
    financialReportView: {
      accentBackground: 'rgb(242, 242, 242)',
    },
    specificationsView: {
      grey,
      darkGrey,
    },
    darkBlue,
    beige,
  },
  // we use elevation 0 - 5, rest is default MUI
  shadows: [
    'none',
    '0 1px 2px rgba(0,0,0,0.02), 0 2px 4px rgba(0,0,0,0.02), 0 4px 8px rgba(0,0,0,0.02)',
    '0 1px 2px rgba(0,0,0,0.04), 0 2px 4px rgba(0,0,0,0.04), 0 4px 8px rgba(0,0,0,0.04)',
    '0 2px 3px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.06), 0 6px 12px rgba(0,0,0,0.06)',
    '0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,0,0,0.08), 0 8px 16px rgba(0,0,0,0.08)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  overrides: {
    MuiAppBar: {
      root: {
        height: appHeaderHeight,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: headerBackgroundColor,
      },
    },
    MuiPaper: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.08)',
      },
    },
    MuiCircularProgress: {
      root: {},
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
      },
    },
    MuiDialog: {
      paper: {
        '@media print': {
          overflowY: 'visible',
          maxWidth: 'unset !important',
          width: '100%',
        },
      },
    },
    MuiDialogContent: {
      root: {
        '@media print': {
          overflowY: 'visible',
        },
      },
    },
    MuiFab: {
      root: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiSwitch: {
      thumb: {
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    },
    MuiSelect: {
      outlined: {
        backgroundColor: white,
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: white,
      },
      inputMarginDense: {
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
      },
    },
  },
};

const theme = themeOptions;

export default theme;
