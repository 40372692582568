import React from 'react';
import styled from '@emotion/styled';
import Typography from '../../UI/Typography/Typography';
import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { isValid } from 'date-fns';
import { parse } from '@agoy/dates';

import {
  AnnualReportVersion,
  requireDatePerSignatureDate,
  Settings,
  Signatures,
} from '@agoy/annual-report-document';
import { booleanValue, stringValue } from '@agoy/document';
import SignaturesAuditReportDate from './Parts/AuditReportDate';
import AccountantTitlesPreview from './Parts/People/AccountantTitlesPreview';
import BoardMembersPreview from './Parts/People/BoardMembersPreview';
import SignaturesStatement from './Parts/Statement';
import { IXElement } from '../../ARKeyToIX';
import { isAuditor } from '../../utils/isAuditor';

const Content = styled.div`
  page-break-before: always;
`;

interface SignaturesPageProps {
  signatures: Signatures | undefined;
  isDigitalSubmission: boolean | null;
  currentFinancialYear: string;
  settings: Settings;
  version: AnnualReportVersion;
  showDefaultPeopleDate?: boolean;
}
const SignaturesPage = ({
  signatures,
  isDigitalSubmission,
  currentFinancialYear,
  settings,
  version,
  showDefaultPeopleDate = false,
}: SignaturesPageProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { typeSigningAnnualReport } = settings.section;

  const isDigitalSigningWithFortnox = booleanValue(
    typeSigningAnnualReport?.isDigitalSigningWithFortnox
  );

  const isNewDateStructure =
    new Date(currentFinancialYear.substring(12, 23)) >
    requireDatePerSignatureDate;

  if (signatures == null) {
    return null;
  }

  const { section } = signatures;
  const displayAuditReportAndStatement =
    section.people.rows.length > 0
      ? section.people.rows.some((row) => isAuditor(row, version))
      : false;

  const parsedDate = parse(stringValue(section.date));
  const auditCompanyName = stringValue(section.auditFirm.companyName);

  return (
    <Content>
      <Typography variant="h1">{stringValue(section.title) || ''}</Typography>
      {/* Location */}
      {stringValue(section.location) && !isDigitalSigningWithFortnox && (
        <Box mt={1}>
          <Typography>
            {formatMessage({
              id: 'annualReport.signatures.section.location',
            })}
          </Typography>
          <Typography>
            <IXElement arPart={section.location}>
              {stringValue(section.location)}
            </IXElement>
          </Typography>
        </Box>
      )}

      {/* Date */}
      {!isNewDateStructure && section.date && (
        <Box mt={1}>
          {isValid(parsedDate) ? (
            <>
              <Typography>
                {formatMessage({
                  id: 'annualReport.signatures.section.date',
                })}
              </Typography>
              <Typography>
                <IXElement arPart={section.date}>
                  {stringValue(section.date)}
                </IXElement>
              </Typography>
            </>
          ) : (
            <Typography>
              <IXElement arPart={section.date}>
                {stringValue(section.date)}
              </IXElement>
            </Typography>
          )}
        </Box>
      )}

      {/* Board member signatures */}
      <BoardMembersPreview
        isNewDateStructure={isNewDateStructure}
        rows={section.people.rows}
        isDigitalSubmission={!!isDigitalSubmission}
        isDigitalSigningWithFortnox={isDigitalSigningWithFortnox}
        isDateSelected={showDefaultPeopleDate || !!stringValue(section.date)}
        version={version}
      />

      {/* AuditReportDate and Statement should only be displayed based on table content  */}
      {displayAuditReportAndStatement && (
        <Box mt={1} display="block">
          {section.auditReportDate && (
            <SignaturesAuditReportDate
              people={signatures.section.people}
              value={section.auditReportDate || ''}
              statement={section.statement}
              statementPronoun={section.statementPronoun}
              version={version}
            />
          )}

          {section.statement && (
            <SignaturesStatement
              value={section.statement}
              statementPronoun={section.statementPronoun}
              version={version}
            />
          )}

          {!!auditCompanyName && (
            <Typography>
              <IXElement arPart={section.auditFirm.companyName}>
                {auditCompanyName}
              </IXElement>
            </Typography>
          )}
        </Box>
      )}

      <AccountantTitlesPreview
        rows={section.people.rows}
        isDigitalSubmission={!!isDigitalSubmission}
        isDigitalSigningWithFortnox={isDigitalSigningWithFortnox}
        version={version}
        isDateSelected={showDefaultPeopleDate || !!stringValue(section.date)}
      />
    </Content>
  );
};

export default SignaturesPage;
