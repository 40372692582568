import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Typography from '../../UI/Typography/Typography';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  AnnualGeneralMeeting,
  AnnualReport,
  AnnualReportVersion,
  ConfirmationCertificate,
  DocumentConfiguration,
  Signatures,
} from '@agoy/annual-report-document';
import { ResolvedReference, stringValue } from '@agoy/document';
import Header from './Header';
import extractPartsToPrint from '../../extractPartsToPrint';
import * as IX from '../../xml/IX';
import ConfirmationCertificateDigitalSubmission from '../FSI';
import { BOARD_LABELS } from '../../types/enums';
import ConfirmationCertificateManualSubmission from '../FSI/ConfirmationCertificateManualSubmission';

const StyledPage = styled.div`
  text-align: center;
`;

const Title = styled(Typography)`
  margin-top: 130px;
  margin-bottom: 40px;
  font-size: 3rem;
  text-transform: uppercase;
`;

const For = styled(Typography)`
  font-size: 1.5rem;
  margin-bottom: 40px;
`;

const LogoImage = styled.img`
  margin-bottom: 40px;
  max-height: 250px;
  max-width: 80%;
`;

const CompanyName = styled(Typography)`
  font-size: 2rem;
`;

const Orgnr = styled(Typography)`
  margin: 8px 0px;
`;

const FinancialYear = styled.div`
  margin: 24px 0px 16px 0px;
`;

const FinYearTitle = styled(Typography)`
  margin-bottom: 8px;
`;

const Text = styled(Typography)`
  margin-bottom: 32px;
  font-size: inherit;
`;

const Index = styled(Table)`
  text-align: left;
  width: 60%;
  margin: 0 auto;

  .MuiTableCell-root {
    border-bottom: none;
    line-height: 0.875rem;
  }

  tr {
    background: none;
  }

  tr td :first-of-type {
    width: 90%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

interface IndexPageProps {
  pageNumbers: Record<string, number>;
  annualReport: AnnualReport;
  documentConfig: DocumentConfiguration;
  confirmationCertificate: ConfirmationCertificate | null;
  signatures: Signatures | undefined;
  documentTypeVersion: AnnualReportVersion;
  injectFSI?: boolean;
  isXBRLRender?: boolean;
  isManualSubmission: boolean;
  annualGeneralMeeting?: AnnualGeneralMeeting;
}

const IndexPage = ({
  pageNumbers,
  annualReport,
  documentConfig,
  confirmationCertificate,
  signatures,
  documentTypeVersion,
  injectFSI = false,
  isXBRLRender = false,
  annualGeneralMeeting,
  isManualSubmission,
}: IndexPageProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const parts = extractPartsToPrint(annualReport, documentConfig.reportType);

  const { settings } = annualReport;

  const isCashFlowToggled = settings.section.cashFlowStatementActive?.value;

  const shouldIncludeCashflow = (
    part: string,
    documentTypeVersion: string,
    isCashFlowToggled: ResolvedReference
  ) => {
    if ('cashFlowStatement' in annualReport && part === 'cashFlowStatement') {
      if (documentTypeVersion === '1') {
        return annualReport.cashFlowStatement.section.active;
      }
      if (documentTypeVersion === '2') {
        return isCashFlowToggled;
      }
    }
    return true;
  };

  const title =
    stringValue(settings.section.title) ||
    formatMessage({
      id: 'annualReport.settings.annualReportTitle',
    });

  const ceo = signatures
    ? signatures.section.people.rows?.find((row) =>
        stringValue(row.cells?.role)?.includes(BOARD_LABELS.CEO)
      )
    : undefined;

  const defaultSecondaryText = ceo
    ? formatMessage(
        {
          id: `annualReport.settings.secondaryText`,
        },
        {
          period: stringValue(settings.section.period),
        }
      )
    : formatMessage(
        {
          id: `annualReport.settings.secondaryText.withoutCeo`,
        },
        {
          period: stringValue(settings.section.period),
        }
      );
  const secondaryText = stringValue(settings.section.secondaryText);

  const logo = stringValue(annualReport.settings.section.logoLargeUrl);

  const getSavedBoardText = (): string => {
    if (
      'managementReport' in annualReport &&
      'activities' in annualReport?.managementReport
    ) {
      return (
        stringValue(annualReport?.managementReport?.activities?.boardText) || ''
      );
    }

    return '';
  };

  const boardTextMsg = (boardText: string) => {
    if (boardText?.includes('verkställande')) {
      return 'boardAndCeo';
    }
    if (boardText?.includes('Likvidatorn')) {
      return 'liquidator';
    } else {
      return 'justBoard';
    }
  };

  const boardText = getSavedBoardText() && boardTextMsg(getSavedBoardText());
  const isIndividual = annualReport.documentType === 'individual';

  const getTranslationKey = (part: string) => {
    const base = isIndividual
      ? 'individualCompanyAnnualReport.menu'
      : 'annualReport.menu';
    return `${base}.${part}`;
  };
  return (
    <StyledPage>
      <Header
        settings={annualReport.settings}
        documentConfig={documentConfig}
        boardText={boardText}
        isIndividual={isIndividual}
        isXBRLRender={isXBRLRender}
      />
      <Title variant="h1">{title}</Title>
      <For>för</For>
      {/* Remove image if we inject the FSI on the first page since it might force index to span two pages */}
      {logo && !injectFSI && <LogoImage src={logo} alt="Företagsbild" />}

      <CompanyName>
        <IX.NonNumeric name="se-cd-base:ForetagetsNamn" contextRef="period0">
          {stringValue(settings.section.clientName)}
        </IX.NonNumeric>
      </CompanyName>
      <Orgnr>
        <span>{'Org.nr. '}</span>
        <IX.NonNumeric
          name="se-cd-base:Organisationsnummer"
          contextRef="period0"
        >
          {stringValue(settings.section.organisationNumber)}
        </IX.NonNumeric>
      </Orgnr>
      <FinancialYear>
        <FinYearTitle>{formatMessage({ id: 'theFinancialyear' })}</FinYearTitle>
        <IX.NonNumeric
          name="se-cd-base:RakenskapsarForstaDag"
          contextRef="period0"
        >
          {stringValue(settings.section.periodStart)}
        </IX.NonNumeric>{' '}
        —{' '}
        <IX.NonNumeric
          name="se-cd-base:RakenskapsarSistaDag"
          contextRef="period0"
        >
          {stringValue(settings.section.periodEnd)}
        </IX.NonNumeric>
      </FinancialYear>

      {!isIndividual && !documentConfig.isDigitalSubmission && (
        <Text>{secondaryText || defaultSecondaryText}</Text>
      )}
      {isIndividual && secondaryText && <Text>{secondaryText}</Text>}
      {isIndividual &&
        settings.section.currencyText &&
        settings.section.currencyText.active && (
          <Text>{stringValue(settings.section.currencyText)}</Text>
        )}

      <Index>
        <TableHead>
          <TableRow>
            <TableCell>Innehåll</TableCell>
            <TableCell align="right">Sida</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parts
            .filter((part) => {
              return shouldIncludeCashflow(
                part,
                documentTypeVersion,
                isCashFlowToggled
              );
            })
            .map((part) => {
              return (
                <TableRow key={`index-page-part-${part}`}>
                  <TableCell>
                    {formatMessage({ id: getTranslationKey(part) })}
                  </TableCell>
                  {pageNumbers[part] && pageNumbers[part] > 0 ? (
                    <TableCell align="right">
                      <a href={`#${part}`}>{pageNumbers[part]}</a>
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Index>
      {confirmationCertificate && isManualSubmission && injectFSI && (
        <ConfirmationCertificateManualSubmission
          confirmationCertificate={confirmationCertificate}
          settings={settings}
        />
      )}
      {injectFSI &&
        confirmationCertificate &&
        !isManualSubmission &&
        annualGeneralMeeting && (
          <>
            <Box mt={2} paddingLeft="4rem" paddingRight="4rem" textAlign="left">
              <Typography>
                Om inte annat särskilt anges, redovisas alla belopp i hela
                kronor.
              </Typography>
            </Box>
            <ConfirmationCertificateDigitalSubmission
              confirmationCertificate={confirmationCertificate}
              annualGeneralMeeting={annualGeneralMeeting}
              settings={settings}
            />
          </>
        )}
    </StyledPage>
  );
};

export default IndexPage;
