import React from 'react';
import styled from '@emotion/styled';
import { Tooltip, Typography as MuiTypography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useIntl } from 'react-intl';
import {
  ClientInformation,
  CSDetailedCompany,
  ClientInformationDirector,
} from '@agoy/api-sdk-core';
import { normalizeOrganisationNumber } from '@agoy/common';

type Warning = 'notMatching' | 'noValue' | undefined;

const StyledWarningIcon = styled(WarningIcon)<{ warning: Warning }>`
  color: ${(props) =>
    props.warning === 'notMatching'
      ? `${props.theme.palette.warning.main}`
      : `${props.theme.palette.error.main}`};
  background: ${({ theme }) => theme.palette.grey[200]};
`;

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 13rem;
  padding: ${(props) => props.theme.spacing(1)}px;
  border-radius: ${(props) => props.theme.spacing(0.5)}px;
  row-gap: ${(props) => props.theme.spacing(0.5)}px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: bold;
`;

const PersonNumber = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 11px;
  font-weight: bold;
`;

const Role = styled.div`
  color: ${(props) => props.theme.palette.grey[700]};
  font-weight: bold;
`;

const MissingRole = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.palette.grey['700']};
`;

const ContainerGridRow = styled.div`
  display: flex;
  position: relative;
  margin: 1rem 0;
  width: 930px;
  overflow-y: scroll;
`;

const GridElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  min-height: 32px;
  row-gap: ${(props) => props.theme.spacing(1)}px;

  :nth-of-type(1) {
    width: 23rem;
    margin-left: 2rem;
  }

  // Extra padding to allow for the warning icon
  :nth-of-type(2) {
    > div {
      padding-right: ${(props) => props.theme.spacing(1, 3, 1, 1)};
    }
  }

  :nth-of-type(3) {
    > div {
      padding: ${(props) => props.theme.spacing(1)}px;
    }
  }

  :nth-of-type(2),
  :nth-of-type(3) {
    > div {
      background: ${({ theme }) => theme.palette.grey[200]};
    }
  }

  :nth-of-type(4) {
    > div {
      background: ${({ theme }) => theme.palette.creditSafe.inputPreview};
      padding: ${(props) => props.theme.spacing(1)}px;
    }
  }
`;

const Typography = styled(MuiTypography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: bold;
`;

const WarningMark = ({ warning }: { warning?: string }) => {
  if (warning === 'notMatching') {
    return (
      <Tooltip title="'Aktuell Grundinfo' stämmer inte med 'Data enligt CreditSafe'">
        <StyledWarningIcon warning="notMatching" />
      </Tooltip>
    );
  }
  if (warning === 'noValue') {
    return (
      <Tooltip title="'Aktuell Grundinfo' saknas">
        <StyledWarningIcon warning="noValue" />
      </Tooltip>
    );
  }

  return null;
};

type CSModalRowProps = {
  companyData: CSDetailedCompany;
  cardModalFields: ClientInformation;
};

const compare = (
  currentValue: ClientInformationDirector,
  valueFromCS: ClientInformationDirector
) => {
  let result = true;
  Object.keys(currentValue).forEach((key) => {
    if (result) {
      if (key === 'personNr') {
        result =
          normalizeOrganisationNumber(currentValue[key]) ===
          normalizeOrganisationNumber(valueFromCS[key]);
      } else {
        result = currentValue[key] === valueFromCS[key];
      }
    }
  });
  return result;
};

const DirectorsRow = ({ cardModalFields, companyData }: CSModalRowProps) => {
  const { formatMessage } = useIntl();

  // If value in Agoy does not match, yellow icon (notMatching); if no value in Agoy, red icon (noValue);
  const compareDirectors = (
    currentValue: ClientInformationDirector[],
    valueFromCS: ClientInformationDirector[]
  ): Warning => {
    if (!currentValue && valueFromCS?.[0]) {
      return 'noValue';
    }

    if (!currentValue && !valueFromCS?.[0]) {
      return undefined;
    }

    let result = true;

    currentValue.forEach((value) => {
      const csValue = valueFromCS.find(
        (item) => item.firstName === value.firstName
      );

      if (!csValue) {
        result = false;
        return;
      }

      if (result) {
        result = compare(value, csValue);
      }
    });

    return result ? undefined : 'notMatching';
  };

  // Maps through the companyTypes in English and returns the translation
  const renderItem = (
    item: ClientInformationDirector | undefined,
    warning?: string
  ) => {
    if (!item) {
      return (
        <StyledInput key={item}>
          <InputRow>
            <MissingRole>{formatMessage({ id: 'missing' })}</MissingRole>
            <WarningMark warning={warning} />
          </InputRow>
        </StyledInput>
      );
    }

    return (
      <StyledInput key={`${item.personNr}${item.role}`}>
        <InputRow>
          <Name>{item.firstName}</Name>
          <PersonNumber>{item.personNr}</PersonNumber>
        </InputRow>
        <InputRow>
          <Role>{formatMessage({ id: `clientInformation.${item.role}` })}</Role>
          {warning && <WarningMark warning={warning} />}
        </InputRow>
      </StyledInput>
    );
  };

  return (
    <>
      {Object.keys(cardModalFields).map((field) => {
        const currentValue = cardModalFields[field]?.value;
        const valueFromCS = companyData[field];
        const isEmptyCS = valueFromCS ? valueFromCS.length === 0 : true;

        return (
          <React.Fragment key={field}>
            {valueFromCS && (
              <ContainerGridRow>
                <GridElement>
                  <Typography>
                    {formatMessage({ id: `clientInformation.${field}` })}
                  </Typography>
                </GridElement>
                <GridElement>
                  {currentValue?.[0]
                    ? currentValue.map((item) =>
                        renderItem(
                          item,
                          compareDirectors(currentValue, valueFromCS)
                        )
                      )
                    : renderItem(
                        undefined,
                        compareDirectors(currentValue, valueFromCS)
                      )}
                </GridElement>
                <GridElement>
                  {(isEmptyCS ? [undefined] : valueFromCS).map((item) =>
                    renderItem(item)
                  )}
                </GridElement>
                <GridElement>
                  {(isEmptyCS ? [undefined] : valueFromCS).map((item) =>
                    renderItem(item)
                  )}
                </GridElement>
              </ContainerGridRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DirectorsRow;
