import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  FieldValues,
  FieldErrors,
  UseFormSetValue,
  UseFormRegister,
  Control,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  ClientInformation,
  ClientInformationDirector,
} from '@agoy/api-sdk-core';
import config from '_shared/services/config';
import {
  getClosingMonthOptions,
  getClosingPeriodOptions,
  getVatPeriodOptions,
  getCompanyTypeOptions,
  getVatRegistrationOptions,
} from '_clients/components/options';
import { useSelector } from 'redux/reducers';
import {
  returnSource,
  returnTimestamp,
  returnUser,
  validation,
} from '../utils';

import { ORG_NUMBER, COMPANY_ADDRESS, COMPANY_TYPE } from '../../Constants';
import ClientCardInformationBlock from './ClientCardInformationBlock';
import ClientCardInformationSelect from './ClientCardInformationSelect';
import ClientCardDatePicker from './ClientCardDatePicker';
import ClientCardRolesSection from './ClientCardRolesSection';
import { isFortnoxCompanyTenantSelector } from '../../../../_shared/redux/selectors';

const fortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

const disabledFieldsFortnoxUI = [COMPANY_ADDRESS, ORG_NUMBER, COMPANY_TYPE];

// Fields that are pre-filled from Fortnox but can be overridden by the user for
// company tenants.
const overridableFieldsFortnoxCompanyTenantUI = [COMPANY_TYPE];

const InformationBlockWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

type ClientValue =
  | ClientInformationDirector[]
  | boolean
  | number
  | string
  | undefined
  | null;

interface ContentProps {
  value?: ClientValue;
  field: string;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  directorsType?: string;
  orgMembers: Member.MemberType[];
  content: ClientInformation;
  edit: boolean;
  addPerson: (role: string, field: string) => void;
  deletePerson: (field: string, value: ClientValue, index?: number) => void;
}

const Content = (props: ContentProps) => {
  const {
    field,
    value = '',
    register,
    control,
    errors,
    orgMembers,
    content,
    edit,
    setValue,
  } = props;
  const intl = useIntl();
  const isFortnoxCompanyTenant = useSelector(isFortnoxCompanyTenantSelector);

  let disabled = false;
  if (fortnoxWhiteLabel) {
    const isUserOverridable =
      isFortnoxCompanyTenant &&
      overridableFieldsFortnoxCompanyTenantUI.includes(field);
    if (!isUserOverridable) {
      disabled =
        disabledFieldsFortnoxUI.includes(field) ||
        content[field]?.source === 'fortnox';
    }
  }

  const menuItems = useMemo(
    () => ({
      closingPeriod: getClosingPeriodOptions(intl).map((item) => ({
        ...item,
        value: `${item.value}`,
      })),
      closingMonth: getClosingMonthOptions(intl),
      vatPeriod: getVatPeriodOptions(intl),
      type: getCompanyTypeOptions(intl),
      clientManager: orgMembers.map((member) => ({
        value: member.userId,
        label: member.email,
      })),
      vatRegistration: getVatRegistrationOptions(intl),
      registeredEmployer: getVatRegistrationOptions(intl),
    }),
    [intl, orgMembers]
  );

  if (
    ['ceo', 'auditFirm', 'directors', 'auditors', 'otherFunctions'].includes(
      field
    )
  ) {
    return <ClientCardRolesSection {...props} />;
  }

  if (
    [
      'closingPeriod',
      'closingMonth',
      'vatPeriod',
      'type',
      'clientManager',
      'vatRegistration',
      'registeredEmployer',
    ].includes(field)
  ) {
    return (
      <InformationBlockWrapper>
        <ClientCardInformationSelect
          name={field}
          listItems={menuItems[field]}
          field={field}
          isEditing={edit}
          labelField={field}
          disabled={disabled}
          error={errors[field]}
          control={control}
          source={returnSource(content, field)}
          timestamp={returnTimestamp(content, field)}
          user={returnUser(content, orgMembers, field)}
          valueType={
            field === 'vatRegistration' || field === 'registeredEmployer'
              ? 'boolean'
              : 'string'
          }
        />
      </InformationBlockWrapper>
    );
  }

  if (
    ['startDateAgoy', 'vatRegistrationDate', 'incorporationDate'].includes(
      field
    )
  ) {
    return (
      <InformationBlockWrapper>
        <ClientCardDatePicker
          toolTip={
            field === 'startDateAgoy'
              ? intl.formatMessage({
                  id: 'clientInformation.startDateAgoy.tooltip',
                })
              : undefined
          }
          name={field}
          field={field}
          isEditing={edit}
          labelField={field}
          disabled={disabled}
          error={errors[field]}
          control={control}
          source={returnSource(content, field)}
          timestamp={returnTimestamp(content, field)}
          user={returnUser(content, orgMembers, field)}
          format={field === 'vatRegistrationDate' ? 'yyyy-MM' : undefined}
          views={
            field === 'vatRegistrationDate' ? ['year', 'month'] : undefined
          }
        />
      </InformationBlockWrapper>
    );
  }

  return (
    <InformationBlockWrapper>
      <ClientCardInformationBlock
        {...register(field, validation[field])}
        field={field}
        isEditing={edit}
        labelField={field}
        source={returnSource(content, field)}
        timestamp={returnTimestamp(content, field)}
        user={returnUser(content, orgMembers, field)}
        disabled={disabled}
        value={!edit && value?.toString() ? value.toString() : undefined}
        error={errors[field]}
        setValue={setValue}
      />
    </InformationBlockWrapper>
  );
};

export default Content;
