import React, { useMemo, useState } from 'react';
import { Tooltip as MuiTooltip, makeStyles } from '@material-ui/core';
import Typography from '../Typography/Typography';

type TooltipProps = {
  title: JSX.Element | string;
  children: JSX.Element;
};

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.fortnoxTooltip.background,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minWidth: 400,
  },
  arrow: {
    color: theme.palette.fortnoxTooltip.background,
  },
  popper: {
    pointerEvents: 'initial',
  },
}));

const Tooltip = ({ title, children }: TooltipProps) => {
  const [open, setOpen] = useState(false);

  const openTooltip = () => {
    setOpen(true);
  };

  const closeTooltip = () => {
    setOpen(false);
  };

  const output = React.cloneElement(children, {
    onMouseEnter: openTooltip,
    onMouseLeave: closeTooltip,
    onFocus: openTooltip,
    onBlur: closeTooltip,
  });

  const titleElement = useMemo(() => {
    if (typeof title === 'string' && title.length) {
      return (
        <Typography color="onDark" margin="none">
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  return (
    <MuiTooltip
      title={titleElement}
      placement="bottom"
      classes={tooltipStyles()}
      arrow
      PopperProps={{
        onMouseEnter: openTooltip,
        onMouseLeave: closeTooltip,
      }}
      open={open}
    >
      {output}
    </MuiTooltip>
  );
};

export default Tooltip;
