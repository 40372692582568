import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@emotion/is-prop-valid';
import { useIntl } from 'react-intl';
import { darkBlue } from '@agoy/theme/src/colors';
import Spacer from '@agoy/annual-report-view/src/UI/helpers/Spacer';
import config from '_shared/services/config';
import { DeleteButton as SharedDeleteButton } from '_shared/components/Buttons';
import When from '_shared/components/When/When';
import PersonalNumber from '_shared/components/PersonalNumber';
import LabelSelector from '_shared/components/Inputs/LabelSelector';
import Alert from '_shared/components/AlertBanners/Alert';

import {
  normalizeString,
  returnSource,
  returnTimestamp,
  returnUser,
  validation,
} from '../utils';

import { defaultRoles } from '../types';
import ClientCardDirectorsSection from './ClientCardDirectorsSection';
import ClientCardDirectorInformationBlock from './ClientCardDirectorInformationBlock';
import ClientCardDirectorInformationSelect from './ClientCardDirectorInformationSelect';
import { ContentProps } from './CardContent.types';

const DirectorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

const DirectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &:not(:last-of-type) {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
    margin-bottom: ${(props) => props.theme.spacing(1)}px;
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  }
`;

const InputRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .generic-input-wrapper {
    width: 100%;
  }
`;

const DeleteButton = styled(SharedDeleteButton)`
  color: ${darkBlue};
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const RowWrapper = styled('div', { shouldForwardProp })<{
  order?: number;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: ${(props) => props.theme.spacing(3)}px;

  ${({ order }) => (order ? `order: ${order}` : '')}
`;

const MissingRole = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.palette.grey['500']};
`;

const fortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

const ClientCardRolesSection = ({
  content,
  field,
  value,
  setValue,
  setError,
  clearErrors,
  directorsType,
  edit,
  orgMembers,
  addPerson,
  register,
  errors,
  control,
  deletePerson,
}: ContentProps) => {
  const { formatMessage } = useIntl();

  const disabled = fortnoxWhiteLabel && content[field]?.source === 'fortnox';

  const fieldValue = content[field]?.value;

  const onBlur = (index, fieldName) => (e) => {
    const newDirectorsObj = content[field]?.value.map(
      (director, directorIndex) =>
        directorIndex === index
          ? { ...director, [fieldName]: normalizeString(e.target.value) }
          : director
    );
    setValue(field, newDirectorsObj || []);
  };

  const auditors = useMemo(
    () => content.auditors?.value ?? [],
    [content.auditors?.value]
  );

  const responsibleAuditor = auditors.findIndex(
    (auditor) => auditor.responsibleAuditor
  );

  const responsibleAuditorMenuItems = useMemo(
    () =>
      auditors
        // we use index as value so it's important to preserve auditors order,
        // and filter out unnecessary roles after
        .map((auditor, auditorIndex) => ({
          value: auditorIndex,
          label: `${auditor.firstName} ${auditor.lastName}`,
        }))
        .filter((auditor) =>
          defaultRoles.responsibleAuditor.includes(auditors[auditor.value].role)
        ),
    [auditors]
  );

  const onChangeChiefAuditor = useCallback(
    (e) => {
      const updatedAuditors = auditors.map((auditor, index) => ({
        ...auditor,
        responsibleAuditor: index === e.target.value,
      }));

      setValue('auditors', updatedAuditors || []);
    },
    [auditors, setValue]
  );

  const onNoChiefAuditorSelected = useCallback(() => {
    if (responsibleAuditor < 0) {
      setError('responsibleAuditor', { type: 'required' });
      return;
    }
    clearErrors('responsibleAuditor');
  }, [clearErrors, responsibleAuditor, setError]);

  const directorsWithType = field === 'directors' && directorsType;
  const roles =
    directorsWithType && defaultRoles[directorsType]
      ? defaultRoles[directorsType]
      : defaultRoles[field];

  const fields =
    field === 'auditFirm'
      ? [['orgNumber']]
      : [['firstName', 'lastName'], ['personNr', 'phoneNumber'], ['email']];

  const responsibleAuditorTitle = formatMessage({
    id: 'clientInformation.chief_auditor',
  });

  return (
    <ClientCardDirectorsSection
      isEditing={edit}
      labelField={directorsWithType ? directorsType : field}
      source={returnSource(content, field)}
      timestamp={returnTimestamp(content, field)}
      user={returnUser(content, orgMembers, field)}
      createPerson={addPerson}
      roles={roles}
      hasPerson={['ceo', 'auditFirm'].includes(field) && !!fieldValue?.length}
    >
      {fieldValue && Object.keys(fieldValue).length > 0 ? (
        <DirectorsWrapper>
          {fieldValue.map((director, index) => (
            <DirectorWrapper key={`${field}.${index}`}>
              <InputRow>
                <When
                  isTrue={field !== 'auditFirm'}
                  fallback={
                    <ClientCardDirectorInformationBlock
                      {...register(`${field}.${index}.companyName`, {
                        ...(field === 'auditFirm'
                          ? validation.companyName
                          : {}),
                        onBlur: onBlur?.(index, 'companyName'),
                      })}
                      name={`${field}.${index}.companyName`}
                      field={`${field}.companyName`}
                      value={!edit ? director.companyName : undefined}
                      isEditing={edit}
                      disabled={disabled}
                      error={errors[field]?.[index]?.companyName}
                      placeholder="Företagsnamn"
                      labelField="Företagsnamn"
                      autoFocus
                    />
                  }
                >
                  <ClientCardDirectorInformationSelect
                    {...register(`${field}.${index}.role`, {
                      onChange: onBlur?.(index, 'role'),
                    })}
                    labelField={field}
                    listItems={roles.map((role) => ({
                      value: role,
                      label: formatMessage({
                        id: `clientInformation.${role}`,
                      }),
                    }))}
                    field={`${field}.role`}
                    isEditing={edit}
                    disabled={disabled || director.responsibleAuditor}
                    error={errors[field]?.[index]?.role}
                    control={control}
                    user={returnUser(content, orgMembers, field)}
                  />
                </When>

                {edit && (
                  <DeleteButton
                    onClick={() => deletePerson(field, value, index)}
                    disabled={director.responsibleAuditor}
                  />
                )}
              </InputRow>

              {fields.map((row) => (
                <RowWrapper key={row.join('-')}>
                  {row.map((fieldName) => (
                    <ClientCardDirectorInformationBlock
                      key={fieldName}
                      {...register(`${field}.${index}.${fieldName}`, {
                        ...(validation[fieldName] || {}),
                        onBlur: onBlur?.(index, fieldName),
                      })}
                      field={`${field}.${fieldName}`}
                      isEditing={edit}
                      disabled={disabled}
                      value={!edit ? director[fieldName] : undefined}
                      error={errors[field]?.[index]?.[fieldName]}
                      placeholder={formatMessage({
                        id: `clientInformation.${fieldName}.placeholder`,
                      })}
                      labelField={formatMessage({
                        id: `clientInformation.${fieldName}.label`,
                      })}
                      renderNonEditingValue={
                        fieldName === 'personNr'
                          ? () => <PersonalNumber value={director.personNr} />
                          : undefined
                      }
                    />
                  ))}
                </RowWrapper>
              ))}
              <When isTrue={field === 'auditFirm'}>
                <InputRow>
                  <ClientCardDirectorInformationBlock
                    name={`${field}.${index}.responsibleAuditor`}
                    field="responsibleAuditor"
                    value={
                      responsibleAuditor >= 0
                        ? `${auditors[responsibleAuditor].firstName} ${auditors[responsibleAuditor].lastName}`
                        : undefined
                    }
                    isEditing={edit}
                    disabled={disabled}
                    error={errors?.responsibleAuditor}
                    labelField={responsibleAuditorTitle}
                    onBlur={() => Promise.resolve()}
                    onChange={() => Promise.resolve()}
                    required
                  >
                    <LabelSelector
                      title={responsibleAuditorTitle}
                      listItems={responsibleAuditorMenuItems}
                      disabled={disabled}
                      editing
                      fullWidth
                      value={responsibleAuditor}
                      onChange={onChangeChiefAuditor}
                      onBlur={onNoChiefAuditorSelected}
                      onClose={onNoChiefAuditorSelected}
                      placeholder={responsibleAuditorTitle}
                    />
                  </ClientCardDirectorInformationBlock>
                </InputRow>
              </When>
            </DirectorWrapper>
          ))}
        </DirectorsWrapper>
      ) : (
        <MissingRole>{formatMessage({ id: 'missing' })}</MissingRole>
      )}
      <When
        isTrue={
          ['auditFirm', 'auditors'].includes(field) &&
          errors?.responsibleAuditor &&
          !responsibleAuditorMenuItems.length
        }
      >
        <Spacer size={field === 'auditors' ? 0 : 2} />
        <RowWrapper order={field === 'auditors' ? -1 : undefined}>
          <Alert type={field === 'auditors' ? 'error' : 'warning'}>
            {formatMessage({
              id: `clientInformation.${field}.addApprovedAuditors`,
            })}
          </Alert>
        </RowWrapper>
      </When>
    </ClientCardDirectorsSection>
  );
};

export default ClientCardRolesSection;
