import React, { useCallback, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { ReconciliationBalanceKeyFigure } from '@agoy/api-sdk-core';
import compareRows from './compareRows';
import injectHiddenRows from './injectHiddenRows';
import mergeRows from './mergeRows';
import { Row, RowWithoutClassName, GroupRow } from './types';
import HiddenRowsContext, { HiddenRowType } from './HiddenRowsContext';
import { ReconciliationPeriod } from '../types';

/**
 * RowContext type containing the definitions for the
 * rows that are rendered in the table.
 *
 * It provides methods for adding and removing rows that
 * column request.
 */
type RowContextType = {
  /**
   * The current rows of the table
   */
  rows: Row[];

  /**
   * addRows, requests rows to be rendered. Provided by columns to
   * render their content. The className of each row is added by the
   * context.
   *
   * @param id Unique identifier of a column
   * @param rows The rows to add to the table without classNames.
   */
  addRows: (id: string, rows: RowWithoutClassName[]) => void;

  /**
   * removeRows, requests a removal if the rows that were added by a
   * column.
   *
   * @param id The unique id used when adding rows.
   */
  removeRows: (id: string) => void;
};

/**
 * The RowContext, with dummy implementation.
 */
const RowContext = React.createContext<RowContextType>({
  rows: [],
  addRows: () => {
    throw new Error('No context');
  },
  removeRows: () => {
    throw new Error('No context');
  },
});

/**
 * Props for the RowContextProvider
 */
type RowContextProviderProps = React.PropsWithChildren<{
  /**
   * A function that translates a row to a className
   *
   * @param row The row to provide a className for.
   */
  classNameProvider: (row: RowWithoutClassName) => string;

  /**
   * The default list of rows for the table.
   *
   * At least one row must be present for the focusing to work.
   */
  defaultRows: RowWithoutClassName[];
}>;

const addClassName = (
  row: RowWithoutClassName,
  provider: (row: RowWithoutClassName) => string
): Row =>
  row.type === 'group'
    ? {
        ...row,
        className: provider(row),
        rows: row.rows.map((subRow) => addClassName(subRow, provider)),
      }
    : { ...row, className: provider(row) };

const getGroupById = (groupId: string, rows: Row[]): GroupRow | undefined => {
  let group: GroupRow | undefined;

  for (let i = 0; i < rows.length - 1; i += 1) {
    const row = rows[i];

    if (row.type === 'group' && row.id === groupId) {
      group = row;
      break;
    }

    if (row.type === 'group' && row.rows) {
      const res = getGroupById(groupId, row.rows);
      if (res) {
        group = res;
        break;
      }
    }
  }

  return group;
};

function ifChanged<T>(prev: T, next: T): T {
  return isEqual(prev, next) ? prev : next;
}

/**
 * Creates a Provider for RowContext with a HiddenRowContext to back it up.
 */
export const RowContextProvider = ({
  children,
  classNameProvider,
  defaultRows,
}: RowContextProviderProps) => {
  // allRows contains all requested rows per unique id.
  const [allRows, setAllRows] = useState<Record<string, Row[]>>(() => ({
    default: defaultRows.map(
      (row): Row => ({ ...row, className: classNameProvider(row) })
    ),
  }));

  const [hiddenRows, setHiddenRows] = useState<Record<string, HiddenRowType>>(
    {}
  );

  const addRows = useCallback(
    (id: string, rows: RowWithoutClassName[]) => {
      setAllRows((all) =>
        ifChanged(all, {
          ...all,
          [id]: rows
            .map((row) => addClassName(row, classNameProvider))
            .sort(compareRows),
        })
      );
    },
    [classNameProvider]
  );

  const removeRows = useCallback((id: string) => {
    setAllRows((all) => {
      const next = { ...all };
      delete next[id];
      return next;
    });
  }, []);

  const addHiddenRow = useCallback(
    (
      accountNumber: string,
      groupId: string,
      period,
      accountBalance,
      onClose
    ) => {
      setHiddenRows((current) => {
        const open = current[accountNumber];
        if (open?.onClose && open.period !== period) {
          // The hidden row for this account is already open for another period,
          // notify the previous owner that it is closed for that period.
          setTimeout(() => open.onClose(), 0);
        }

        // The hidden row for group of this account is already open,
        // notify the group that it is closed for that period.
        Object.keys(current).forEach((key) => {
          const { row } = current[key];
          if ('rows' in row) {
            const isOpen = row.rows.find(
              (accountRow) =>
                'accountNumber' in accountRow &&
                accountRow.accountNumber === accountNumber
            );

            if (isOpen) {
              setTimeout(() => current[key].onClose(), 0);
            }
          }
        });

        const hiddenRow = {
          type: 'hidden' as const,
          id: `account${accountNumber}.hidden`,
          period,
          accountNumber,
          accountBalance,
        };

        return {
          ...current,
          [accountNumber]: {
            row: { ...hiddenRow, className: classNameProvider(hiddenRow) },
            period,
            onClose,
            groupId,
          },
        };
      });
    },
    [classNameProvider]
  );

  const addGroupHiddenRow = useCallback(
    (
      groupId: string,
      period: ReconciliationPeriod,
      balance: ReconciliationBalanceKeyFigure,
      onClose: () => void
    ) => {
      setHiddenRows((current) => {
        const open = current[groupId];
        if (open?.onClose) {
          // The hidden row for this account is already open,
          // notify the previous owner that it is closed for that period.
          setTimeout(() => open.onClose(), 0);
        }

        const group = getGroupById(groupId, mergeRows(allRows));

        group?.rows.forEach((row) => {
          if ('accountNumber' in row) {
            const rowOpen = current[row.accountNumber];

            if (rowOpen?.onClose) {
              // The hidden row for account in this group is already open,
              // notify account that it is closed for that period.
              rowOpen.onClose();
            }
          }
        });

        const hiddenRow = {
          type: 'hiddenGroup' as const,
          id: `account${groupId}.hidden`,
          period,
          balance,
          rows: group?.rows || [],
        };

        return {
          ...current,
          [groupId]: {
            row: { ...hiddenRow, className: classNameProvider(hiddenRow) },
            period,
            onClose,
          },
        };
      });
    },
    [allRows, classNameProvider]
  );

  const removeHiddenRow = useCallback((accountNumber, period) => {
    setHiddenRows((current) => {
      const open = current[accountNumber];
      // If the hidden row is opened for the given period, we
      // close it. Otherwise just ignore the request.
      if (open && open.period === period) {
        open?.onClose();

        const next = {
          ...current,
        };
        delete next[accountNumber];

        return next;
      }
      return current;
    });
  }, []);

  const rowContext = useMemo((): RowContextType => {
    return {
      rows: injectHiddenRows(mergeRows(allRows), hiddenRows),
      addRows,
      removeRows,
    };
  }, [allRows, hiddenRows, addRows, removeRows]);

  const hiddenRowsContext = useMemo(() => {
    return {
      hiddenRows,
      addHiddenRow,
      addGroupHiddenRow,
      removeHiddenRow,
    };
  }, [hiddenRows, addGroupHiddenRow, addHiddenRow, removeHiddenRow]);

  return (
    <HiddenRowsContext.Provider value={hiddenRowsContext}>
      <RowContext.Provider value={rowContext}>{children}</RowContext.Provider>
    </HiddenRowsContext.Provider>
  );
};

export default RowContext;
