import { parseISO } from 'date-fns';
import { getAnnualConfig } from '@agoy/common';
import {
  AccountRowWithMoving,
  ReconciliationOverview,
  SummarizationContext,
} from './types';

const createContext = (
  group: ReconciliationOverview['groups'][number],
  companyType: string,
  movedAccounts?: { [accountNumber: string]: AccountRowWithMoving }
): SummarizationContext => {
  return {
    companyTax: (endDateOfFinancialYear: string) => {
      const year = parseISO(endDateOfFinancialYear).getFullYear();
      return getAnnualConfig(year)?.companyTax ?? Number.NaN;
    },
    startFinancialYear: group.startFinancialYear,
    endFinancialYear: group.endFinancialYear,
    companyType,
    movedAccounts,
  };
};

export default createContext;
