import { ClientInformation } from '@agoy/api-sdk-core';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ClientInfoContext } from './CompanyData';

const ClientInformationContext = createContext<ClientInformation | undefined>(
  undefined
);

type ClientInformationContextProviderProps = React.PropsWithChildren<{
  clientId: string;
}>;

/**
 * Provides access to client information in the views by clientId
 */
export const ClientInformationContextProvider = ({
  clientId,
  children,
}: ClientInformationContextProviderProps) => {
  const creditSafe = useContext(ClientInfoContext);
  const [data, setData] = useState<ClientInformation | undefined>();

  useEffect(() => {
    creditSafe.getClientInformation(clientId).then((value) => {
      setData(value);
    });
  }, [clientId /* creditSafe (not a stable instance) */]);

  return (
    <ClientInformationContext.Provider value={data}>
      {children}
    </ClientInformationContext.Provider>
  );
};

export default ClientInformationContext;
