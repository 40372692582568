export const defaultRoles = {
  ceo: ['ceo', 'external_ceo'],
  directors: ['board_member', 'board_deputy', 'chairman', 'deputy_ceo'],
  auditors: [
    'auditor',
    'deputy_auditor',
    'chartered_accountant',
    'approved_auditor',
    'main_auditor',
    'lay_auditor',
    'deputy_lay_auditor',
  ],
  otherFunctions: [
    'external_signatory',
    'external_deputy_ceo',
    'resident_in_ees',
    'resident_outside_ees',
    'special_recipient',
    'limited_partner',
    'general_partner',
  ],
};

export const defaultRolesForSignatureTable = {
  ceo: ['ceo', 'external_ceo'],
  directors: ['board_member', 'board_deputy', 'chairman', 'deputy_ceo'],
  auditors: [
    'auditor',
    'deputy_auditor',
    'chief_auditor',
    'chartered_accountant',
    'approved_auditor',
    'main_auditor',
    'lay_auditor',
    'deputy_lay_auditor',
    'chartered_accountant',
    'approved_auditor',
  ],
  otherFunctions: [
    'external_signatory',
    'external_deputy_ceo',
    'resident_in_ees',
    'resident_outside_ees',
    'special_recipient',
    'limited_partner',
    'general_partner',
    'liquidator',
    'liquidator_deputy',
  ],
};

export const protocolSignatureRoles = {
  ceo: ['ceo', 'external_ceo'],
  auditFirm: ['chartered_accountant'],
  directors: ['board_member', 'board_deputy', 'deputy_ceo'],
  partner: ['partner', 'limited_partner', 'general_partner'],
  auditors: [
    'auditor',
    'deputy_auditor',
    'chief_auditor',
    'lay_auditor',
    'deputy_lay_auditor',
  ],
  otherFunctions: [
    'external_signatory',
    'external_deputy_ceo',
    'resident_in_ees',
    'resident_outside_ees',
    'chairman_protocol',
    'recordKeeper',
    'adjuster',
    'chairmanAndRecordKeeper',
  ],
};

export const companyTypes = [
  'shares',
  'limited',
  'limited_partnership',
  'individual',
  'economic_association',
  'non_profit_association',
  'foundation',
  'other',
];

export const votingRoles = [
  'chairman',
  'recordKeeper',
  'adjuster',
  'chairmanAndRecordKeeper',
];

export const protocolRoles = [
  'chairman_protocol',
  'recordKeeper',
  'adjuster',
  'chairmanAndRecordKeeper',
];

export const directorsRoles = [
  'actuary',
  'partner',
  'external_signatory',
  'external_ceo',
  'external_deputy_ceo',
  'limited_partner',
  'board_member',
  'chairman',
  'auditor',
  'chief_accountant',
  'lay_auditor',
  'deputy_auditor',
  'deputy_lay_auditor',
  'board_deputy',
  'ceo',
  'deputy_ceo',
  'employee_representative',
  'resident_in_ees',
  'resident_outside_ees',
  'insurrance_representative',
];
