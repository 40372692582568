import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Global } from '@emotion/react';
import { map } from 'rxjs';
import { last, orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

import { useApiSdk, asResultClass } from 'api-sdk';
import { overviewTableWidth } from 'theme/measurements';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import sendActivityEvent from 'Api/Activity/authorizationActivityEventHandler';
import { ActivityLog } from '@agoy/activity-log';
import { useSelector } from 'redux/reducers';
import { PeriodDataContextProvider } from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import { Period } from '@agoy/api-sdk-core';

import { HiddenGroupRow as HiddenGroupRowType } from '../../RowContext/types';
import {
  ActualBalances,
  AccountBalance,
  UserInput,
  Specifications,
  GroupCheckedHistoryItemType,
  GroupActualBalanceHistoryItem,
} from '../../types';

import HiddenRowsContext from '../../RowContext/HiddenRowsContext';
import ActualBalanceAndQualityCheckboxes from './ActualBalanceAndQualityCheckboxes';
import GroupCheckedHistory from './GroupCheckedHistory';
import GroupedAccountsComments from './GroupedAccountsComments';
import GroupDocumentsProvider from './Documents/GroupDocumentsContext';
import GroupActualBalanceHistory from './GroupActualBalanceHistory';
import { periodTypeToRequestPeriod } from '../../utils';
import Documents from './Documents/Documents';
import { getAccounts, getBalances } from '../utils';
import GroupedAccountsTable from './GroupedAccountsTable';

/**
 * A container for the hidden row rendered in the header column.
 * It stretches outside of the column covering the whole row.
 *
 * This is will be moved into a separate component in the next sub tasks...
 *
 * It is positioned 2px up to let the account cell cover the border and create
 * that tab feeling.
 */
const HiddenRowContainer = styled.div`
  position: relative;
  left: 0px;
  width: 100vw;
  overflow-x: visible;
  margin-top: -2px;

  background-color: ${(props) => props.theme.palette.background.paper};
  z-index: ${(props) => props.theme.zIndex.accountingView.periodRow - 1};
`;

const StickyWrapper = styled.div`
  position: sticky;
  left: 0;
  border: 2px solid;
  max-width: calc(${overviewTableWidth} - 64px);
  border-color: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  overflow: hidden;
  min-height: 250px;
  padding: 16px;
`;

const StyledExpandLessIcon = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing(1.25)}px;
  right: ${(props) => props.theme.spacing(2)}px;
  cursor: pointer;
`;

const MidSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 650px 400px 1fr;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  grid-column-gap: 16px;

  .child:first-of-type {
    width: 100%;
  }

  .child:not(:first-of-type) {
    flex: 1;
  }
`;

const LogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FirstSectionContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 3fr) repeat(3, 1fr) 1.6fr 1.2fr 3fr;
`;

const CheckboxesRow = styled.div`
  justify-self: end;
  grid-column: 1 / span 5;
  margin-right: 2rem;
`;

type HiddenGroupRowProps = {
  row: HiddenGroupRowType;
  clientId: string;
  financialYearId: number;
};

/**
 * Creates the global style for the CSS variable controlling the
 * height of the hidden row.
 *
 * @param row The hidden row
 * @param height The current height of the hidden row
 * @returns
 */
const createStyles = (row, height) => ({
  ':root': {
    [`--${row.id.replace('.', '-')}-height`]: `${height - 2}px`,
  },
});

/**
 * The GroupHiddenRow
 *
 * It renders the content of the group hidden row. To communicate the height
 * and changes to its height, an ResizeObserver is observing the content.
 * When an update is made, the height is stored as a global CSS variable
 * with the row.id to identify it.
 */
const HiddenGroupRow = ({
  row,
  clientId,
  financialYearId,
}: HiddenGroupRowProps) => {
  const service = useClientDataLayer(clientId);
  const ref = useRef<HTMLDivElement | null>(null);
  const sdk = useApiSdk();
  const { formatMessage } = useIntl();

  const [styles, setStyles] = useState(createStyles(row, 250));
  const [periodBalances, setPeriodBalances] = useState<AccountBalance>({});
  const [yearBalances, setYearBalances] = useState<AccountBalance>({});
  const [actualBalances, setActualBalances] = useState<ActualBalances>({});
  const [specifications, setSpecifications] = useState<Specifications>({});
  const [userInput, setUserInput] = useState<UserInput>({});
  const [checkedHistory, setCheckedHistory] = useState<
    GroupCheckedHistoryItemType[]
  >([]);
  const [groupActualBalanceHistory, setGroupActualBalanceHistory] =
    useState<GroupActualBalanceHistoryItem>({
      date: '',
      authorName: '',
      balance: 0,
    });

  const { removeHiddenRow } = useContext(HiddenRowsContext);
  const notificationService = useContext(NotificationContext);

  const parentPeriod = row.period.parent;
  const { period } = row;
  const lastPeriod = last(period.periods);
  const group = row.balance.id.slice(0, -4);

  const userName = useSelector(
    (state) => `${state.user.givenName} ${state.user.familyName}`
  );

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const contentRect = entries.find(
          (entry) => entry.target === ref.current
        )?.contentRect;

        if (contentRect) {
          window.requestAnimationFrame(() => {
            setStyles(createStyles(row, contentRect.height));
          });
        }
      }),
    [row]
  );

  const handleCollapseClick = useCallback(() => {
    removeHiddenRow(row.balance.id.replace('.sum', ''), row.period);
  }, [removeHiddenRow, row.balance.id, row.period]);

  // Fetch the actual balances for the period's accounts
  const fetchActualBalances = useCallback(async () => {
    const accountNumbers = getAccounts(row.rows);

    if (clientId && lastPeriod && accountNumbers.length) {
      const result = await asResultClass(
        sdk.getActualBalances({
          clientid: clientId,
          periodId: lastPeriod.id,
          accounts: accountNumbers,
        })
      );

      if (result.ok) {
        setActualBalances(result.val.accounts);
      }
    }
  }, [clientId, lastPeriod, row.rows, sdk]);

  const fetchUserInput = useCallback(async () => {
    const accountNumbers = getAccounts(row.rows);

    if (clientId && lastPeriod && accountNumbers.length) {
      const result = await asResultClass(
        sdk.getPeriodUserInput({
          clientid: clientId,
          periodId: lastPeriod.id,
          accountNumbers,
        })
      );

      if (result.ok) {
        setUserInput(result.val.accounts);
      }
    }
  }, [clientId, lastPeriod, row.rows, sdk]);

  const fetchSpecifications = useCallback(async () => {
    const accountNumbers = getAccounts(row.rows);

    if (clientId && lastPeriod && accountNumbers.length) {
      const result = await asResultClass(
        sdk.getSpecifications({
          clientid: clientId,
          periodId: lastPeriod.id,
          accountNumbers,
        })
      );

      if (result.ok) {
        setSpecifications(result.val.accounts);
      }
    }
  }, [clientId, lastPeriod, row.rows, sdk]);

  /**
   * Fetches and updates the checked history for the period's accounts
   */
  const fetchCheckedHistory = useCallback(async () => {
    const accountNumbers = getAccounts(row.rows);
    const formattedPeriod = format(new Date(period.end), 'yyyyMM');

    if (clientId) {
      const result = await asResultClass(
        sdk.getCheckedChangeHistory({
          clientid: clientId,
          period: formattedPeriod,
          account: [accountNumbers.toString()],
          limit: 10,
        })
      );
      if (result.ok) {
        setCheckedHistory(result.val);
      } else {
        // eslint-disable-next-line no-console
        console.error(result.err);
      }
    }
  }, [clientId, period.end, row.rows, sdk]);

  /**
   * Fetches the actual balance history for the group
   */
  const fetchActualBalanceHistory = useCallback(async () => {
    const accountNumbers = getAccounts(row.rows);
    const periodId = last(period.periods)?.id;

    if (clientId && periodId && accountNumbers.length) {
      const result = await asResultClass(
        sdk.getSaldoChangeHistory({
          clientid: clientId,
          periodId,
          accounts: accountNumbers,
          limit: 1,
        })
      );
      if (result.ok) {
        const data = result.val;
        const sortedItems = orderBy(data, ['createdAt'], ['desc']);
        const groupBalance = Object.keys(actualBalances).reduce((sum, key) => {
          const actualBalance = actualBalances[key]?.final?.balance;

          return actualBalance ? sum + actualBalance : sum;
        }, 0);

        if (sortedItems?.length > 0) {
          const formattedHistory = {
            balance: groupBalance,
            date: sortedItems[0]?.createdAt,
            authorName: sortedItems[0]?.author.fullName,
          };

          setGroupActualBalanceHistory(formattedHistory);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(result.err);
      }
    }
  }, [actualBalances, clientId, period.periods, row.rows, sdk]);

  const updateCheckedHistory = useCallback(
    async (_period: Period, value: boolean) => {
      const accountNumbers = getAccounts(row.rows);

      if (clientId) {
        const formattedPeriod = format(new Date(_period.start), 'MMMM yyyy', {
          locale: sv,
        });
        const periodDate =
          formattedPeriod.charAt(0).toLocaleUpperCase() +
          formattedPeriod.slice(1);

        // We only update the accounts' checked history if the value has changed
        accountNumbers.forEach(async (account) => {
          if (actualBalances[account].final?.checked !== value) {
            await sendActivityEvent(
              ActivityLog.createActivityLogEvent({
                clientId,
                program: 'RECONCILIATION',
                section: 'HIDDEN_ROW',
                resource: 'CHECKED',
                operation: 'UPDATE',
                arguments: [
                  periodDate,
                  userName,
                  value
                    ? ''
                    : formatMessage({
                        id: 'RECONCILIATION.HIDDEN_ROW.CHECKED.UPDATE.negative',
                      }),
                  account.toString(),
                ],
              })
            );
          }
        });
      }
    },
    [row.rows, clientId, actualBalances, userName, formatMessage]
  );

  useEffect(() => {
    const sub = notificationService?.subscribe(
      {
        topic: 'user-input-changed',
        clientId,
      },
      (result) => {
        if (result.ok) {
          fetchUserInput();
          fetchActualBalances();
          fetchSpecifications();
        }
      }
    );

    return () => {
      sub?.unsubscribe();
    };
  }, [
    clientId,
    notificationService,
    fetchUserInput,
    fetchActualBalances,
    fetchSpecifications,
  ]);

  useEffect(() => {
    fetchUserInput();
  }, [fetchUserInput]);

  useEffect(() => {
    fetchActualBalances();
  }, [fetchActualBalances]);

  useEffect(() => {
    fetchSpecifications();
  }, [fetchSpecifications]);

  useEffect(() => {
    fetchCheckedHistory();
  }, [fetchCheckedHistory]);

  useEffect(() => {
    fetchActualBalanceHistory();
  }, [fetchActualBalanceHistory]);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [observer]);

  useEffect(() => {
    const sub = service.reconciliation
      .getAccounts(
        period.start,
        period.end,
        periodTypeToRequestPeriod(period.type),
        period.type === 'yearEnd'
      )
      .pipe(
        map((result) => {
          if (result.ok && result.val) {
            setPeriodBalances(getBalances(result.val));
          }
        })
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [service, period]);

  useEffect(() => {
    const financialYear = period.financialYears[0];

    const sub = service.reconciliation
      .getAccounts(
        financialYear.start,
        financialYear.end,
        'financialYear',
        true
      )
      .pipe(
        map((result) => {
          if (result.ok && result.val) {
            setYearBalances(getBalances(result.val));
          }
        })
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [service, period]);

  // Memoized accounts rendered with getFilteredAccounts
  const accountNumbersList = useMemo(
    () => getAccounts(row.rows, true),
    [row.rows]
  );

  return (
    <PeriodDataContextProvider
      clientId={row.period.clientId}
      period={row.period.periods}
      periodType={row.period.type}
      lastPeriod={parentPeriod?.periods[parentPeriod.periods.length - 1]}
      parentPeriodType={
        parentPeriod?.type === 'quarter' ||
        parentPeriod?.type === 'financialYear'
          ? parentPeriod.type
          : undefined
      }
    >
      <GroupDocumentsProvider
        accounts={accountNumbersList}
        groupedPeriods={row.period.periods}
        clientId={clientId}
        group={group}
      >
        <Global styles={styles} />
        <HiddenRowContainer ref={ref}>
          <StickyWrapper>
            <StyledExpandLessIcon onClick={handleCollapseClick}>
              <ExpandLessIcon />
            </StyledExpandLessIcon>
            <GroupedAccountsTable
              row={row}
              clientId={clientId}
              periodBalances={periodBalances}
              yearBalances={yearBalances}
              actualBalances={actualBalances}
              userInput={userInput}
              specifications={specifications}
              financialYearId={financialYearId}
            />
            {period.type !== 'dead' && (
              <FirstSectionContainer>
                <CheckboxesRow>
                  <ActualBalanceAndQualityCheckboxes
                    row={row}
                    clientId={clientId}
                    periodBalances={periodBalances}
                    actualBalances={actualBalances}
                    specifications={specifications}
                    updateCheckedHistory={updateCheckedHistory}
                  />
                </CheckboxesRow>
              </FirstSectionContainer>
            )}

            <MidSectionContainer>
              {period.type !== 'dead' && (
                <GroupedAccountsComments row={row} clientId={clientId} />
              )}

              <LogsContainer>
                {!!groupActualBalanceHistory && (
                  <GroupActualBalanceHistory
                    history={groupActualBalanceHistory}
                  />
                )}
                {!!checkedHistory.length && (
                  <GroupCheckedHistory history={checkedHistory} />
                )}
              </LogsContainer>

              {lastPeriod && <Documents period={lastPeriod} />}
            </MidSectionContainer>
          </StickyWrapper>
        </HiddenRowContainer>
      </GroupDocumentsProvider>
    </PeriodDataContextProvider>
  );
};

export default HiddenGroupRow;
