import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import range from 'lodash-es/range';
import styled from '@emotion/styled';

import { useSelector, RootState } from 'redux/reducers';
import { initState } from '_shared/redux/actions';
import { AnnualReportDataServicePrintProvider } from '_annual-report/service/AnnualReportDataServiceContext';
import FinancialReportPrint from '_financial-report/components/FinancialReportView/FinancialReportPrint';
import ReconciliationPrint from '_reconciliation/components/ReconciliationView/ReconciliationPrint';
import ReconciliationPrintProvider from '_reconciliation/components/ReconciliationView/ReconciliationPrintProvider';
import { Global } from '@emotion/react';
import TaxesPrintProvider from '_tax/service/TaxesPrintRovider';
import TaxesPrint from '_tax/service/TaxesPrint';
import TaxesFormPrint from '_tax/service/TaxesFormPrint';
import Page from './UI/Print/Page';
import AnnualReportPrint from './AnnualReportPrint';
import ConfirmationCertificatePrint from './ConfirmationCertificatePrint';
import AnnualGeneralMeetingPrint from './AnnualGeneralMeetingPrint';
import PeriodReportPrint from './PeriodReportPrint';
import PrintStateContext from './PrintStateContext';
import TestPdf from './TestPdf';

interface DocumentProps {
  orientation: string;
}

const Document = styled.div<DocumentProps>`
  @page {
    margin: ${(props) =>
      props.orientation === 'landscape'
        ? '1cm 1cm 1cm 1cm'
        : '2.7cm 2.5cm 2cm 2.5cm'};
    size: A4 ${(props) => props.orientation};
  }
  background-color: white;
`;

const getEmptyPages = (search: string): number => {
  const result = /emptyPages=(\d+)/.exec(search);
  if (result && result[1]) {
    return parseInt(result[1], 10);
  }
  return 0;
};

const PrintedDocument = () => {
  const dispatch = useDispatch();
  const { state, setState } = useContext(PrintStateContext);

  const history = useHistory();
  const { location } = history;

  const [pageNumbers, setPageNumbers] = useState<Record<string, number>>({});

  const { loggedIn } = useSelector((state) => state.user);
  const emptyPages: number = getEmptyPages(location.search);

  useEffect(() => {
    if (location.pathname.includes('/print/testPdf')) {
      return;
    }

    if (location.pathname.includes('/print/financialReport')) {
      const lastPartIndex = location.pathname.lastIndexOf('/');
      const lastPart = location.pathname.substring(
        lastPartIndex + 1,
        location.pathname.length
      );

      // @ts-ignore
      window.isPrintReady = !(lastPart === 'documents' || lastPart === '');
    } else {
      // @ts-ignore
      window.isPrintReady = true;
    }
  }, [location]);

  const setDocumentOrientation = useCallback(() => {
    if (location.pathname.includes('/print/financialReport')) {
      return 'landscape';
    }

    return 'portrait';
  }, [location]);

  const handleFinancialReportLoaded = useCallback(() => {
    // @ts-ignore
    window.isPrintReady = true;
  }, []);

  const content = (
    <Document orientation={setDocumentOrientation()}>
      <Global
        styles={{
          html: {
            fontSize: 14,
          },
        }}
      />
      {range(emptyPages).map((p) => (
        <Page key={p}>Empty page</Page>
      ))}
      <Switch>
        <Route path="/print/annualReportWithCertificate">
          <AnnualReportDataServicePrintProvider>
            <AnnualReportPrint pageNumbers={pageNumbers} withCertificate />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/print/annualReport">
          <AnnualReportDataServicePrintProvider>
            <AnnualReportPrint pageNumbers={pageNumbers} />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/print/periodReport">
          <AnnualReportDataServicePrintProvider>
            <PeriodReportPrint pageNumbers={pageNumbers} />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/print/confirmationCertificate">
          <AnnualReportDataServicePrintProvider>
            <ConfirmationCertificatePrint />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/print/annualGeneralMeeting">
          <AnnualReportDataServicePrintProvider>
            <AnnualGeneralMeetingPrint />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/print/financialReport">
          <AnnualReportDataServicePrintProvider>
            <FinancialReportPrint
              pageNumbers={pageNumbers}
              onLoaded={handleFinancialReportLoaded}
            />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/print/accounting">
          <ReconciliationPrintProvider>
            <ReconciliationPrint pageNumbers={pageNumbers} />
          </ReconciliationPrintProvider>
        </Route>
        <Route path="/print/taxes">
          <TaxesPrintProvider>
            <TaxesPrint pageNumbers={pageNumbers} />
          </TaxesPrintProvider>
        </Route>
        <Route path="/print/taxForm">
          <TaxesFormPrint />
        </Route>
        <Route path="/print/testPdf">
          <TestPdf onLoaded={handleFinancialReportLoaded} />
        </Route>
      </Switch>
    </Document>
  );

  const setContent = (state: Partial<RootState>) => {
    if (!loggedIn) {
      // The printing service is not logged in, so only set state then to protect logged in users
      dispatch(initState(state));
      setState(state);
    } else {
      // eslint-disable-next-line no-console
      console.error('setContent while logged in');
    }
  };

  /**
   * Hook for printing, called by puppeteer to
   * be able to navigate with React Router history.
   *
   * @param path
   */
  const navigateTo = (path: string) => {
    // eslint-disable-next-line no-console
    console.log('navigateTo', path);
    history.push(path);
  };
  // These functions are used by puppeteer during the printing process

  // @ts-ignore
  window.setContent = setContent;
  // @ts-ignore
  window.setPageNumbers = setPageNumbers;
  // @ts-ignore
  window.navigateTo = navigateTo;

  return content;
};

export default PrintedDocument;
