import React, { useState } from 'react';
// TODO: BUTTONS view design revision needed
import { Button } from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import { useGenerateSieFile } from 'Api/Client/sie';
import FileSaver from 'file-saver';
import { isUserError } from 'types/Error/types';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { formatCurrentTime } from '@agoy/dates';

const ExportSIEButton = () => {
  const { currentCustomer: clientId, currentFinancialYear } = useSelector(
    (state) => state.customerView
  );
  const client = useSelector((state) =>
    clientId ? state.customers[clientId] : undefined
  );
  const [downloading, setDownloading] = useState(false);
  const dispatch = useDispatch();

  const generateSieFile = useGenerateSieFile();

  const onDownload = async () => {
    if (clientId && currentFinancialYear) {
      try {
        setDownloading(true);
        const result = await generateSieFile(clientId, currentFinancialYear);
        FileSaver.saveAs(
          result,
          `${client?.name}_${currentFinancialYear}_${formatCurrentTime(
            'yyyyMMddhhmmss'
          )}.se`
        );
      } catch (err) {
        dispatch(
          addGlobalErrorMessage(isUserError(err) ? err.messageId : 'error')
        );
      } finally {
        setDownloading(false);
      }
    }
  };

  return (
    <Button onClick={onDownload} disabled={downloading}>
      Exportera SIE
    </Button>
  );
};

export default ExportSIEButton;
