import { ReconciliationBalances } from '@agoy/api-sdk-core';
import { AccountRow, Row } from '../RowContext/types';
import { AccountBalance } from '../types';

// Gets several signatures to avoid having to specify the returned type when using the function
export function getAccounts(rows: Row[], asString: true): string[];
export function getAccounts(rows: Row[], asString?: false): number[];
export function getAccounts(
  rows: Row[],
  asString?: boolean
): number[] | string[] {
  const accountNumbers: number[] = [];

  rows.forEach((account) => {
    if ('accountNumber' in account) {
      accountNumbers.push(Number(account.accountNumber));
    }
  });

  if (asString) {
    const accountAsStrings: string[] = [];
    accountAsStrings.push(
      ...accountNumbers.map((account) => account.toString())
    );
    return accountAsStrings;
  }
  return accountNumbers;
}

export const getBalances = (rawBalances: ReconciliationBalances) => {
  const values: AccountBalance = {};

  const getValues = (subRows: typeof rawBalances.rows) => {
    subRows.forEach((subRow) => {
      if (subRow.type === 'account') {
        values[subRow.number] = subRow;
      } else if (subRow.type === 'key') {
        values[subRow.id] = subRow;
      } else {
        values[subRow.id] = subRow.sum;
        getValues(subRow.rows);
      }
    });
  };

  getValues(rawBalances.rows);

  return values;
};

export const getTotalSaldo = (data) => {
  return data.reduce((total: number, { saldo }) => total + saldo, 0);
};

export const getAccountRows = (rows: Row[]): AccountRow[] =>
  rows.filter((item) => 'accountNumber' in item) as AccountRow[];
