import React from 'react';
import styled from '@emotion/styled';
import { Typography, Switch, FormControlLabel } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { togglePeriodLockedVisibility } from '_reconciliation/redux/accounting-view/actions';
import { useSelector } from 'redux/reducers';

import { PeriodType } from '../../../types';
import HeaderPopupMenu from './HeaderPopupMenu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #01313c;
  height: 130px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRow = styled(Row)`
  justify-content: space-between;
`;

const BottomRow = styled(Row)`
  justify-content: flex-end;
`;

const WhiteText = styled(Typography)`
  color: white;
`;

const Title = styled(WhiteText)`
  font-size: 24px;
  font-weight: 700;
`;

const SubTitle = styled(WhiteText)`
  font-size: 14px;
  font-weight: 700;
`;

const Label = styled(FormControlLabel)`
  .MuiTypography-body1 {
    color: white;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

type HeaderProps = {
  stickyHeader: boolean;
  type: PeriodType;
};

const Header = ({ type }: HeaderProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { periodLockedVisible } = useSelector((state) => state.accountingView);

  const handleChangeSwitch = () => {
    dispatch(togglePeriodLockedVisibility());
  };

  return (
    <Container>
      <TopRow>
        <Title>
          {formatMessage({ id: `reconciliation.header.title.${type}` })}
        </Title>
        <HeaderPopupMenu />
      </TopRow>

      <SubTitle>
        {formatMessage({ id: 'reconciliation.header.subTitle' })}
      </SubTitle>

      <BottomRow>
        <Label
          control={
            <Switch
              size="small"
              checked={periodLockedVisible}
              onChange={handleChangeSwitch}
            />
          }
          label={formatMessage({ id: 'reconciliation.header.label' })}
        />
      </BottomRow>
    </Container>
  );
};

export default Header;
