import React from 'react';
import styled from '@emotion/styled';
import { blue } from '@agoy/theme/src/colors-fortnox';

type VoucherChipType = {
  voucherSeries?: string;
};

const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background-color: ${blue.MEDIUM};
  border-radius: 28px;
`;

const VoucherChip = ({ voucherSeries }: VoucherChipType) => {
  return <ChipWrapper>{voucherSeries}</ChipWrapper>;
};

export default VoucherChip;
