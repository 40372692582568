import { AgoyTable, AgoyTableRow, RowType } from './table';

const hasNumericValue = (cell: any): cell is { value: number } =>
  'value' in cell && ![null, undefined].includes(cell.value);

const isZeroOrRoundedToZero = (value: number): boolean =>
  value === 0 || Math.round(value) === 0;

const shouldShowRow = (
  row: AgoyTableRow,
  filterTypes?: RowType[],
  showAll = true,
  showZeroValues = true
) => {
  const { cells, rows: subRows, type } = row;

  // controlled by outer prop
  if (!!filterTypes?.length && type && filterTypes.includes(type)) {
    return false;
  }

  // we should not show rows that are not active
  if (
    row.active === false ||
    (typeof row.active === 'object' && row.active.value === false)
  )
    return false;

  // in edit mode all rows should be shown
  // in non-edit those that have type alwaysDisplay or alwaysDisplaySum should be shown
  if (
    showAll ||
    // rows wih type "hidden" are hidden visually
    type === 'hidden' ||
    // In income statement, Nettoomsattning and its sum should always be displayed
    type?.includes('alwaysDisplay')
  )
    return true;

  if (cells) {
    // check if this row has notes or non-0 values
    // get all numeric and notes cells
    const numericAndNotesCells = Object.keys(cells)
      .filter((cellId) =>
        ['number', 'ref', 'refs'].includes(cells[cellId]?.type || '')
      )
      .map((cellId) => cells[cellId]);

    // some cells have notes or values that are not zero
    if (
      numericAndNotesCells.some(
        (cell) =>
          (cell.type === 'refs' && cell.values.length > 0) ||
          (hasNumericValue(cell) &&
            (showZeroValues || !isZeroOrRoundedToZero(cell.value)))
      )
    ) {
      return true;
    }
  }

  // checked only for group headers
  // at this point subrows should be filtered, so we're interested in their length
  // account rows should not affect visibility of the row
  if (type === 'header' && subRows?.length) {
    return subRows.filter((subRow) => subRow.type !== 'account').length > 0;
  }

  // not assigned accounts should be visible in non-edit mode when there are non-empty accounts
  if (type === 'accountsNotAssigned' && subRows?.length) {
    return true;
  }

  return false;
};

/**
 * Method accepts Agoy Table and returns Table with rows filtered according to built in logic and additional params
 *
 * @param table Agoy Table with rows
 * @param filterTypes row types that should be excluded from final result (usually to exclude "account" rows)
 * @param showAll show all rows (usually used with edit), does NOT interfere with "filterTypes"
 * @returns
 */
const filterRows = (
  table: AgoyTable,
  filterTypes?: RowType[],
  showAll = true,
  showZeroValues = true
): AgoyTable => {
  const { rows } = table;

  // filter method that recursively goes through all nested levels of rows
  const filter = (tableRows: AgoyTableRow[], level: number): AgoyTableRow[] => {
    // recursively apply filter to children first
    const rowsWithFilteredChildren = tableRows.map((row) =>
      row.rows ? { ...row, rows: filter(row.rows, level + 1) } : row
    );

    // filter rows on current level
    return rowsWithFilteredChildren.filter((row) =>
      shouldShowRow(row, filterTypes, showAll, showZeroValues)
    );
  };

  return {
    ...table,
    rows: filter(rows, 1),
  };
};

export default filterRows;
